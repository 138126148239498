import React from "react";
import styled from "styled-components";

const Text = styled.div`
	margin-top: 1rem;
`;

export default function GalleryText(props) {
	return <Text>{props.children}</Text>;
}
