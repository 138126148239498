import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import device from "./device";

const cards = [
  {
    icon: "/icons/awareness.svg",
    title: "High Awareness",
    description:
      "Signage is one of the best forms of advertising and a great investment – good signage and graphics can last for many years with fantastic exposure to existing and potential customers. The right signage will allow customers to be aware of your business and your brand.",
  },
  {
    icon: "/icons/sign.svg",
    title: "Sign Features",
    description:
      "Signage comes in all sorts of shapes, sizes, materials and functionality – and we offer pretty much anything you can think of. Our production team love nothing more than rising to the challenge of a bespoke signage project so whatever you have in mind there’s a very chance we have the right solution. ",
  },
  {
    icon: "/icons/cost.svg",
    title: "Cost Effective",
    description:
      "We understand that pricing is important and whilst we pride ourselves on the highest quality of materials and suppliers we also know the need for competitive pricing. As well as medium and large businesses, we also work with SMEs and charities and understand how important budget it.",
  },
  {
    icon: "/icons/team.svg",
    title: "In-House Team",
    description:
      "We pride ourselves on our ability to supply and install your next signage or graphics project with our own in-house team of experts from our headquarters and workshop in Battersea, South-West London. Our in-house team means your project is delivered and installed on time, every time. ",
  },
];

const Wrapper = styled.section`
  margin-bottom: 80px;
`;

const Title = styled.h2`
  font-size: 48px;
  line-height: 1;
  margin-bottom: 64px;
  color: var(--ys-teal);
  font-family: var(--font-secondary);
  text-align: center;
  margin-inline: auto;
`;
const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
`;
const Icon = styled.img`
  width: 64px;
  height: 64px;
  margin-bottom: 24px;
`;
const CardTitle = styled.h3`
  margin-bottom: 16px;
  text-transform: none;
  font-size: 32px !important;
  line-height: 44px !important;
`;
const CardDescription = styled.p`
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.01em;
  font-weight: 400;
  color: var(--ys-black);
  text-align: center;
  @media ${device.sm} {
    max-width: 320px;
    margin-inline: auto;
  }
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;

  @media ${device.lg} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${device.sm} {
    text-align: center;
    grid-template-columns: repeat(1, 1fr);
  }
`;

const WhyYouCare = () => {
  return (
    <Wrapper>
      <Container>
        <Title>{`Why should you care about signage for your business?`}</Title>
        <Grid>
          {cards?.map((card, index) => {
            return <Card {...card} key={index} />;
          })}
        </Grid>
      </Container>
    </Wrapper>
  );
};

const Card = ({ title, description, icon }) => {
  return (
    <CardWrapper>
      <Icon width={64} height={64} src={icon} alt={title} />
      <CardTitle>{title}</CardTitle>
      <CardDescription>{description}</CardDescription>
    </CardWrapper>
  );
};

export default WhyYouCare;
