import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import Button from "../common/Button";

const Wrapper = styled.div`
  padding: 70px 0;
  color: var(--ys-white);
  text-align: center;
  background-image: url("/img/locations/cta-bg.svg");
  background-color: var(--ys-teal);
  background-size: cover;
  h2 {
    font-family: var(--font-secondary);
    font-size: 48px;
    margin-bottom: 24px;
    line-height: 1;
  }

  p {
    white-space: pre-line;
    font-size: 16px;
    line-height: 21px;
    color: var(--ys-white);
    font-weight: 500;
  }

  .content {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }

  a.cta-button {
    &:hover {
      span {
        background: var(--ys-yellow-lt);
        color: var(--ys-black);
      }
    }
  }
`;

const CTA = () => {
  return (
    <Wrapper>
      <Container>
        <div className="content">
          <h2>Signage & Graphics</h2>
          <p>
            {`Can’t see the product or service you’re looking for? Try searching by industry below or drop us an email or give a call to discuss your next project.`}
          </p>

          <Button className="cta-button" href="/contact-us">
            Contact Us
          </Button>
        </div>
      </Container>
    </Wrapper>
  );
};

export default CTA;
