import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import HeaderSimple from "../../fragments/services/HeaderSimple";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styled from "styled-components";
import Helmet from "react-helmet";

const Date = styled.h3`
  color: var(--ys-teal);
`;

const DateSmall = styled.p`
  display: block;
  color: (--ys-black);
`;

const Title = styled.h3`
  color: var(--ys-teal);
  font-size: 3rem !important;
  margin-top: -0.25rem;
`;

const Excerpt = styled.p``;

const Content = styled.p`
  margin-top: 2rem;
`;

const Thumb = styled.div`
  height: 9rem;
  background-size: cover !important;
  background: url(${(props) => props.img});
`;

const DateSeperator = styled.div`
  height: 2px;
  background: var(--ys-grey-lt);
  width: 100%;
  margin-bottom: 1.25rem;
`;

const ArticleSeperator = styled.div`
  height: 2px;
  background: var(--ys-grey-lt);
  width: 100%;
  margin: 2rem 0.75rem 1rem 0.75rem;
`;

const YellowTitle = styled.h3`
  color: var(--ys-yellow);
`;

const Sidebar = styled.div`
  border-left: 2px solid var(--ys-grey-lt);
  height: 100%;
  padding: 0 1rem;
`;

const PostImg = styled.div`
  height: 30rem;
  background: url(${(props) => props.img});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const SideLink = styled.p`
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 0.25rem;
`;

class SinglePost extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    window.scroll(0, 0);
  }

  Date(date) {
    let year = date.substring(0, 4);
    let month;
    let day = date.substring(8, 10);

    switch (date.substring(5, 7)) {
      case "01":
        month = "January";
        break;
      case "02":
        month = "February";
        break;
      case "03":
        month = "March";
        break;
      case "04":
        month = "April";
        break;
      case "05":
        month = "May";
        break;
      case "06":
        month = "June";
        break;
      case "07":
        month = "July";
        break;
      case "08":
        month = "August";
        break;
      case "09":
        month = "September";
        break;
      case "10":
        month = "October";
        break;
      case "11":
        month = "November";
        break;
      case "12":
        month = "December";
        break;
      default:
        month = date.substring(5, 7);
    }

    return `${year} ${month} ${day}`;
  }

  render() {
    const ListRecentPosts = this.props.recentPosts.map(
      (post, index) => (
        // post.categories[0] === 2 && ( // 2 is the category ID for News in WP
        <div style={{ marginBottom: 40 }} key={index}>
          <Link to={`/${post.slug}`}>
            <SideLink>{post.title.rendered}</SideLink>
            {/* <DateSmall>{this.Date(post.date)}</DateSmall> */}
          </Link>
        </div>
      )
      // )
    );

    return (
      <>
        <HeaderSimple heading={this.props.category} />
        <Helmet>
          <title>{this.props.title} | Yellow Signs</title>
          <meta
            name="description"
            content={this.props.content.substring(4, 200)}
          />
          <link
            rel="canonical"
            href={`${window.location.origin}/${this.props?.slug}`}
          />
        </Helmet>
        <Container style={{ marginBottom: 100 }}>
          <Row>
            <Col lg={9}>
              <Title>{this.props.title}</Title>
              {/* <DateSmall>{this.Date(this.props.date)}</DateSmall> */}
              <PostImg img={this.props.img} />
              <Content
                dangerouslySetInnerHTML={{ __html: this.props.content }}
              />
            </Col>
            <Col lg={3}>
              <Sidebar>
                <YellowTitle>Recent Posts</YellowTitle>
                {ListRecentPosts}
              </Sidebar>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default SinglePost;
