import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import device from "../fragments/common/device";
import Container from "react-bootstrap/Container";
import styled from "styled-components";
import Slider from "react-slick";
// import Img from "react-optimized-image";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../fragments/HeroSlider.css";

const BannerHeight = "20rem";

const SliderWrapper = styled.div`
  background: var(--ys-teal);
  height: ${BannerHeight};
  position: relative;
  @media ${device.md} {
    display: none;
  }
`;

const SlickWrapper = styled.div`
  width: 60%;
  position: absolute;
  right: 0;
`;

const SliderInfo = styled.div`
  background: var(--ys-teal);
  height: ${BannerHeight};
  position: absolute;
  width: 25rem;
  transform: scale(1) rotate(0deg) translate(0px, 0px) skew(-6deg, 0deg);
  z-index: 3;
  /* padding-left: 1.6rem; */
`;

const TealWrapper = styled.div`
  height: ${BannerHeight};
  background: var(--ys-teal);
  position: absolute;
  width: 33%;
  z-index: 1;
`;

const YellowStripe = styled.div`
  height: 16.75rem;
  background: var(--ys-yellow);
  margin-top: 6.5rem;
  position: absolute;
  width: 35%;
  z-index: 2;
`;

const HeroImage = styled.img`
  // background-image: url(${(props) => props.img});
  /* background-position-y: center; */
  background-position-y: 25%;
  background-repeat: no-repeat;
  background-size: cover;
  height: ${BannerHeight};
  object-fit: cover;
  width: 100%;
  float: right;
`;

const TitleWrapper = styled.h1`
  padding: 1rem 0 0 0;
  display: inline-block;
  float: left;
  /* height: ${BannerHeight}; */
  height: 22rem;
  position: absolute;
  z-index: 2;
  text-transform: uppercase;
  overflow: hidden;
  transform: scale(0.7);
  margin-left: -3rem;
`;

const LineOne = styled.span`
  display: block;
  color: white;
  font-weight: 300;
  overflow: hidden;
`;

const LineTwo = styled.span`
  display: block;
  color: var(--ys-yellow);
  font-family: Alternate, "sans-serif";
  font-size: 14rem;
  margin-top: -3rem;
  @media ${device.sm} {
    font-size: 10rem;
    margin-top: -2rem;
  }
`;

const LineThree = styled.span`
  display: block;
  color: white;
  width: 30rem;
  font-weight: 300;
  margin-top: -2.7rem;
`;

class HeroSlider extends Component {
  render() {
    var settings = {
      autoplay: true,
      arrows: false,
      dots: true,
      infinite: true,
      autoplaySpeed: 7000,
      speed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    return (
      <SliderWrapper id="heroSlider">
        <Container>
          <SliderInfo>
            <TitleWrapper>
              <LineOne>Complete</LineOne>
              <LineTwo>Signage</LineTwo>
              <LineThree>Solution</LineThree>
              <Link to="signage-solution">
                <button
                  className="alternate"
                  style={{
                    transform:
                      "scale(1) rotate(0deg) translate(0px, 0px) skew(0deg, 0deg)",
                    color: "var(--ys-yellow)",
                    transform: "scale(1.5)",
                    marginLeft: "1rem",
                  }}
                >
                  More {">"}
                </button>
              </Link>
            </TitleWrapper>
          </SliderInfo>
        </Container>

        {/* <YellowStripe /> */}
        <TealWrapper />

        <SlickWrapper>
          <Slider {...settings}>
            <HeroImage
              width={500}
              height={250}
              fetchPriority="high"
              loading="eager"
              src="/img/banners/Sliding4.webp"
              alt="Hero slider 1"
            />
            <HeroImage
              width={500}
              height={250}
              // fetchPriority="high"
              loading="lazy"
              src="/img/banners/Sliding.webp"
              alt="Hero slider 2"
            />
            <HeroImage
              width={500}
              height={250}
              // fetchPriority="high"
              loading="lazy"
              src="/img/banners/Sliding2.webp"
              alt="Hero slider 3"
            />
            <HeroImage
              width={500}
              height={250}
              // fetchPriority="high"
              loading="lazy"
              src="/img/banners/Sliding3.webp"
              alt="Hero slider 4"
            />
            <HeroImage
              width={500}
              height={250}
              // fetchPriority="high"
              loading="lazy"
              src="/img/banners/Sliding5.webp"
              alt="Hero slider 5"
            />
            <HeroImage
              width={500}
              height={250}
              // fetchPriority="high"
              loading="lazy"
              src="/img/banners/Sliding6.webp"
              alt="Hero slider 6"
            />
            <HeroImage
              width={500}
              height={250}
              // fetchPriority="high"
              loading="lazy"
              src="/img/banners/Sliding7.webp"
              alt="Hero slider 7"
            />
          </Slider>
        </SlickWrapper>
      </SliderWrapper>
    );
  }
}

export default withRouter(HeroSlider);
