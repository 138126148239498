import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  withRouter,
} from "react-router-dom";
import axios from "axios";
import Header from "./fragments/common/Header";
import Home from "./pages/Home";

import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import ShopsRetailSigns from "./pages/signselection/ShopsRetailSigns";

// // Sign Selection
// import NeonSigns from "./pages/signselection/NeonSigns";
// import VehicleGraphics from "./pages/signselection/VehicleGraphics";
// import AcrylicLetters from "./pages/signselection/AcrylicLetters";
// import PlaquesWayfinding from "./pages/signselection/PlaquesWayfinding";
// import FlatLetters from "./pages/signselection/FlatLetters";
// import FullColourGraphics from "./pages/signselection/FullColourGraphics";
// import PrivacyFrosting from "./pages/signselection/PrivacyFrosting";
// import ProjectingSigns from "./pages/signselection/ProjectingSigns";
// import ThroughLighting from "./pages/signselection/ThroughLighting";
// import CutVinylGraphics from "./pages/signselection/CutVinylGraphics";
// import DigitalWallpaper from "./pages/signselection/DigitalWallpaper";
// import Hoarding from "./pages/signselection/Hoarding";
// import BespokeFabrication from "./pages/signselection/BespokeFabrication";
// import Print from "./pages/signselection/Print";
// import Trade from "./pages/signselection/Trade";

// // Signs by Sector
// import BarRestaurant from "./pages/signsbysector/BarRestaurant";
// import BuildConstruction from "./pages/signsbysector/BuildConstruction";
// import EstateAgencyProperty from "./pages/signsbysector/EstateAgencyProperty";
// import ExhibitionEvent from "./pages/signsbysector/ExhibitionEvent";
// import GymsFitness from "./pages/signsbysector/GymsFitness";
// import OfficeCorporate from "./pages/signsbysector/OfficeCorporate";
// import SchoolEducation from "./pages/signsbysector/SchoolEducation";
// import ShopRetail from "./pages/signsbysector/ShopRetail";

// About Us
import OurStory from "./pages/aboutus/OurStory";
import MeetTheTeam from "./pages/aboutus/MeetTheTeam";
import ContactUs from "./pages/aboutus/ContactUs";

// What We Do
import SignageSolution from "./pages/whatwedo/SignageSolution";
import CaseStudies from "./pages/whatwedo/CaseStudies";
// import Articles from "./pages/whatwedo/Articles";
import News from "./pages/whatwedo/News";
import SinglePost from "./pages/whatwedo/SinglePost";

// Top level pages
import QuoteSiteVisit from "./pages/QuoteSiteVisit";
import SignBuyersGuide from "./pages/SignBuyersGuide";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsConditions from "./pages/TermsConditions";
import Locations from "./pages/Locations";
import Footer from "./fragments/common/Footer";
import LocationSingle from "./pages/LocationSingle";
import ProductSingle from "./pages/ProductSingle";
import { Helmet } from "react-helmet";
import locations from "./data/locations";
import locationsGallery from "./data/locationsGallery";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: {},
      postUrls: [],
    };
  }

  componentDidMount() {
    // Fetching posts immediatelly from WP for speedy delivery accross page
    axios
      .get(`https://blog.yellowsigns.co.uk/wp-json/wp/v2/posts?per_page=30`)
      .then((res) => {
        this.setState({ posts: res.data });
      });
    // axios.get(`https://www.wpexplorer.com/wp-json/wp/v2/posts?per_page=30`).then((res) => {
    // 	this.setState({ posts: res.data });
    // });

    // const scriptGTM = document.createElement("script");
    // const scriptGT = document.createElement("script");
    // const dataLayerScript = document.createElement("script");
    const livechatScript = document.createElement("script");

    // // scriptGTM.async = true;
    // scriptGTM.defer = true;
    // scriptGTM.crossOrigin = "anonymous";

    // // scriptGT.async = true;
    // scriptGT.defer = true;
    // scriptGT.crossOrigin = "anonymous";

    // // dataLayerScript.async = true;
    // dataLayerScript.defer = true;
    // dataLayerScript.crossOrigin = "anonymous";

    // scriptGTM.innerHTML = `
    // (function (w, d, s, l, i) {
    //   w[l] = w[l] || [];
    //   w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
    //   var f = d.getElementsByTagName(s)[0],
    //     j = d.createElement(s),
    //     dl = l != "dataLayer" ? "&l=" + l : "";
    //   j.async = true;
    //   j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
    //   f.parentNode.insertBefore(j, f);
    // })(window, document, "script", "dataLayer", "GTM-TLC7MGD");
    // `;

    // dataLayerScript.innerHTML = `
    // window.dataLayer = window.dataLayer || [];
    //   function gtag() {
    //     dataLayer.push(arguments);
    //   }
    //   gtag("js", new Date());

    //   gtag("config", "G-9CEB09VPMT");
    // `;

    livechatScript.innerHTML = `window.__lc = window.__lc || {};
    window.__lc.license = 13787559;
    (function (n, t, c) {
      function i(n) {
        return e._h ? e._h.apply(null, n) : e._q.push(n);
      }
      var e = {
        _q: [],
        _h: null,
        _v: "2.0",
        on: function () {
          i(["on", c.call(arguments)]);
        },
        once: function () {
          i(["once", c.call(arguments)]);
        },
        off: function () {
          i(["off", c.call(arguments)]);
        },
        get: function () {
          if (!e._h)
            throw new Error(
              "[LiveChatWidget] You can't use getters before load."
            );
          return i(["get", c.call(arguments)]);
        },
        call: function () {
          i(["call", c.call(arguments)]);
        },
        init: function () {
          var n = t.createElement("script");
          (n.async = !0),
            (n.type = "text/javascript"),
            (n.src = "https://cdn.livechatinc.com/tracking.js"),
            t.head.appendChild(n);
        },
      };
      !n.__lc.asyncInit && e.init(),
        (n.LiveChatWidget = n.LiveChatWidget || e);
    })(window, document, [].slice);`;

    // document.body.append(scriptGTM);
    // document.body.append(scriptGT);
    // document.body.append(dataLayerScript);
    setTimeout(() => {
      document.body.append(livechatScript);
    }, 2000);
  }

  render() {
    let postsFetched = Object.keys(this.state.posts).length !== 0;
    // console.log(productPages)
    return (
      <>
        <Helmet>
          {/* <meta name="robots" content="nofollow,noindex" /> */}
        </Helmet>

        <Router>
          <Header />

          <Switch>
            {locationsGallery.map((page) => (
              <Route
                exact
                path={page?.href}
                // element={<ProductSingle page={page} />}
              >
                <ProductSingle page={page} />
              </Route>
            ))}
            {/* About us */}
            <Route exact path="/our-story" component={OurStory} />
            <Route exact path="/meet-the-team" component={MeetTheTeam} />
            <Route exact path="/contact-us" component={ContactUs} />
            {/* What We Do */}
            <Route exact path="/signage-solution" component={SignageSolution} />
            <Route exact path="/case-studies">
              <CaseStudies slug="/case-studies" {...this.state} />
            </Route>
            <Route exact path="/news">
              <News slug="/news" {...this.state} />
            </Route>
            {/* Blog Pages Single */}
            {postsFetched
              ? this.state.posts.map((item, index) => (
                  <Route path={`/${item.slug}`} key={index}>
                    <SinglePost
                      slug={item.slug}
                      title={item.title.rendered}
                      date={item.date}
                      img={item.jetpack_featured_media_url}
                      content={item.content.rendered}
                      category={
                        item.categories[0] === 2 ? "News" : "Case Studies"
                      }
                      recentPosts={this.state.posts}
                    />
                  </Route>
                ))
              : null}
            {/* Location Pages Single */}
            <Route exact path="/locations" component={Locations} />
            {locations.map((item, index) => (
              <Route path={item.href} key={index}>
                <LocationSingle {...item} />
              </Route>
            ))}
            {/* Home & Top Level Pages */}
            <Route
              exact
              path="/sign-buyers-guide"
              component={SignBuyersGuide}
            />
            {/* <Route exact path="/location-single" component={LocationSingle} />{" "} */}
            {/* <Route
              exact
              path="/shop-retails-old"
              component={ShopsRetailSigns}
            /> */}
            <Route exact path="/quote-site-visit" component={QuoteSiteVisit} />
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
            <Route
              exact
              path="/terms-and-conditions"
              component={TermsConditions}
            />
            <Route exact path="/" component={Home} />
            <Route path="*">
              <div style={{ padding: "10rem", textAlign: "center" }}>
                <Loader
                  type="Grid"
                  color="#ffd800"
                  height={80}
                  width={80}
                  timeout={4000}
                />
              </div>
            </Route>
          </Switch>
          <Footer />
        </Router>
      </>
    );
  }
}

export default withRouter(App);
