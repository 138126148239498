import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import device from "../common/device";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faBars } from "@fortawesome/free-solid-svg-icons";
import ImagePreloader from "./ImagePreload";

const MenuTxt = styled.div`
  text-transform: uppercase;
  font-size: 0.7rem;
  font-weight: 600;
  padding: 0.3rem 0.55rem 0.25rem 0.55rem;
  cursor: pointer;
  &:hover {
    background: var(--ys-teal);
    color: white;
  }
  @media (max-width: 820px) {
    font-size: 0.6rem;
  }
`;

const MegaMenuWrap = styled.div`
  position: absolute;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-bottom: 0.5rem;
  z-index: 4;
  background: white;
  width: 39rem;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 600;
  margin-left: 0.9rem;
  transition: 0.3s;
  opacity: 1;
  box-shadow: 0px 4px 4px 4px rgb(0 0 0 / 5%);
`;

const MegaMenuItem = styled.div`
  margin: 0.5rem 0.5rem 0 0.5rem;
  height: 2.5rem;
  cursor: pointer;
  &:hover {
    background: var(--ys-yellow);
  }
  font-size: 0.7rem;
`;

const DesktopMenu = styled.div`
  @media ${device.md} {
    display: none;
  }
`;

const MobileMenuWrapper = styled.div`
  position: absolute;
  z-index: 4;
  top: 3.3rem;
  display: none;
  @media ${device.md} {
    display: grid;
  }
`;

const MobileMenu = styled.div`
  background: var(--ys-yellow);
  height: 100vh;
  width: 100%;
  position: absolute;
  padding-top: 1rem;
  transition: 0.3s;

  position: relative;
  overflow: hidden;
`;

const MobMenuItem = styled.div`
  padding: 0.75rem 1rem;
  text-transform: uppercase;
  font-weight: 600;
`;

const MobMenuItemWrapper = styled.div`
  transition: 0.3s;
`;

const Dropdown = styled.div`
  position: absolute;
  transition: 0.3s;
`;

const DropdownItem = styled.div`
  padding: 0.3rem 1rem 0.3rem 2rem;
  font-weight: 600;
`;

const SignSelection = [
  {
    name: "Shop & Retail Signs",
    link: "/shops-retail-signs",
    img: "/img/menu/ShopRetail.jpg",
    id: "signSelection",
  },
  {
    name: "3D & Built-Up Letters",
    link: "/3d-builtup-letters",
    img: "/img/menu/3DBuiltUpLetters.jpg",
  },
  {
    name: "Wayfinding & Plaques",
    link: "/plaques-wayfinding",
    img: "/img/menu/PlaquesWayfinding.jpg",
  },
  {
    name: "Privacy Frosting",
    link: "/privacy-frosting",
    img: "/img/menu/PrivacyFrosting.jpg",
  },
  {
    name: "Vehicle Graphics",
    link: "/vehicle-graphics",
    img: "/img/menu/VehicleGraphics.jpg",
  },
  {
    name: "Projecting Signs",
    link: "/projecting-signs",
    img: "/img/menu/ProjectingSigns.jpg",
  },
  { name: "Neon Signs", link: "/neon-signs", img: "/img/menu/NeonSigns.jpg" },
  { name: "Hoarding", link: "/hoarding", img: "/img/menu/Hoarding.jpg" },
  {
    name: "Cut Vinyl Graphics",
    link: "/cut-vinyl-graphics",
    img: "/img/menu/CutVinylGraphics.jpg",
  },
  {
    name: "Flat Cut Letters",
    link: "/flat-letters",
    img: "/img/menu/FlatLetters.jpg",
  },

  {
    name: "Full Colour Graphics",
    link: "/full-colour-graphics",
    img: "/img/menu/FullColourPrintedGraphics.jpg",
  },

  {
    name: "Digital Wallpaper",
    link: "/digital-wallpaper",
    img: "/img/menu/DigitalWallpaper.jpg",
  },
  {
    name: "Trough Lighting",
    link: "/trough-lighting",
    img: "/img/menu/TroughLighting.jpg",
  },

  {
    name: "Bespoke Fabrication",
    link: "/bespoke-fabrication",
    img: "/img/menu/Bespoke.jpg",
  },
  { name: "Digital & Litho Print", link: "/print", img: "/img/menu/Print.jpg" },
  { name: "Trade Signage", link: "/trade", img: "/img/menu/Trade.jpg" },
];

const SignsBySector = [
  {
    name: "Bar & Restaurant",
    link: "/bar-restaurant",
    img: "/img/menu/BarRestaurant.jpg",
    id: "signsBySector",
  },
  {
    name: "Building & Construction",
    link: "/build-construction",
    img: "/img/menu/BuildingConstruction.jpg",
  },
  {
    name: "Estate Agent & Property",
    link: "/estate-agent-property",
    img: "/img/menu/EstateAgentProperty.jpg",
  },
  {
    name: "Exhibition & Event",
    link: "/exhibition-event",
    img: "/img/menu/ExhibitionEvent.jpg",
  },
  {
    name: "Gyms & Fitness",
    link: "/gyms-fitness",
    img: "/img/menu/GymFitness.jpg",
  },
  {
    name: "Office & Corporate",
    link: "/office-corporate",
    img: "/img/menu/OfficeCorporate.jpg",
  },
  {
    name: "School & Education",
    link: "/school-education",
    img: "/img/menu/SchoolEducation.jpg",
  },
  {
    name: "Shops & Retail",
    link: "/shop-retail",
    img: "/img/menu/ShopRetail.jpg",
  },
];

const WhatWeDo = [
  {
    name: "Areas We Cover",
    link: "/locations",
    img: "/img/menu/LocationPin.png",
    id: "whatWeDo",
  },
  {
    name: "Signage Solution",
    link: "/signage-solution",
    img: "/img/menu/SignageSolution.jpg",
    // id: "whatWeDo",
    short: true,
  },
  {
    name: "Case Studies",
    link: "/case-studies",
    img: "/img/menu/CaseStudies.jpg",
    short: true,
  },
  { name: "News", link: "/news", img: "/img/menu/News.jpg", short: true },
];

const OurStory = [
  {
    name: "Our story",
    link: "our-story",
    img: "/img/menu/OurStory.jpg",
    id: "aboutUs",
    short: true,
  },
  {
    name: "Meet the team",
    link: "meet-the-team",
    img: "/img/menu/MeetTheTeam.jpg",
  },
];

const MenuItems = [
  {
    name: "Sign selection",
    id: "signSelectionBtn",
    items: SignSelection,
    columns: 3,
    width: "39rem",
  },
  {
    name: "Signs by sector",
    id: "signsBySectorBtn",
    items: SignsBySector,
    columns: 2,
    width: "28rem",
  },
  {
    name: "What we do",
    id: "whatWeDoBtn",
    items: WhatWeDo,
    columns: 1,
    width: "15rem",
  },
  {
    name: "About Us",
    id: "ourStory",
    items: OurStory,
    columns: 1,
    width: "12rem",
  },
  {
    name: "Quote & site visit",
    id: "quoteSiteVisitBtn",
    link: "/quote-site-visit",
  },
  { name: "Contact Us", id: "contactUsBtn", link: "/contact-us" },
];

let openMegaMenu = (menu, btn, e) => {
  let menuItem = document.getElementById(menu);
  let btnItem = document.getElementById(btn);

  if (e.target.id === menu || e.target.id === btn) {
    // menuItem.style.opacity = 1;
    // menuItem.style.display = "grid";
    // menuItem.style.pointerEvents = "all";
    btnItem.classList.add("active-menu-temp");
  }
};

let closeMegaMenu = (menu, btn, e) => {
  let menuItem = document.getElementById(menu);
  let btnItem = document.getElementById(btn);

  if (e.target.id === menu || e.target.id === btn) {
    // menuItem.style.opacity = 0;
    // menuItem.style.display = "none";
    // menuItem.style.pointerEvents = "none";
    btnItem.classList.remove("active-menu-temp");
  }
};

class TopMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenu: "close",
      activeMenu: "",
    };
    this.timeout = null;
    // this.timeTou
    this.handleMegaMenuOpen = this.handleMegaMenuOpen.bind(this);
    this.handleMegaMenuClose = this.handleMegaMenuClose.bind(this);

    this.handleMobMenuTrigger = this.handleMobMenuTrigger.bind(this);
    this.handleMobMenuClose = this.handleMobMenuClose.bind(this);
    this.handleMobMenuExpand = this.handleMobMenuExpand.bind(this);
  }

  componentDidMount() {
    document.body.addEventListener("mouseover", (e) => {
      let mgDrop = document.getElementsByClassName("mgDrop");
      // if (!e.target.classList.contains("MegaMenu")) {
      //   for (let drop of mgDrop) {
      //     drop.style.opacity = 0;
      //     drop.style.display = "none";
      //     drop.style.pointerEvents = "none";
      //   }

      //   for (let item of MenuItems) {
      //     document.getElementById(item.id).classList.remove("active-menu-temp");
      //   }
      // }
    });
  }

  handleMegaMenuOpen(key) {
    this.setState({ activeMenu: key });
    clearTimeout(this.timeout);
    // openMegaMenu("signSelection", "signSelectionBtn", e);
    // openMegaMenu("signsBySector", "signsBySectorBtn", e);
    // openMegaMenu("whatWeDo", "whatWeDoBtn", e);
    // openMegaMenu("aboutUs", "ourStory", e);
  }

  handleMegaMenuClose() {
    console.log("closing");
    this.timeout = setTimeout(() => {
      this.setState({ activeMenu: "" });
      // closeMegaMenu("signSelection", "signSelectionBtn", e);
      // closeMegaMenu("signsBySector", "signsBySectorBtn", e);
      // closeMegaMenu("whatWeDo", "whatWeDoBtn", e);
      // closeMegaMenu("aboutUs", "ourStory", e);
    }, 200);
  }

  handleMobMenuTrigger(e) {
    if (this.state.mobileMenu === "open") {
      // Close menu
      this.setState({ mobileMenu: "close" });

      // Re-enable scrolling
      document.body.classList.remove("no-scroll");
    } else {
      // Open menu
      this.setState({ mobileMenu: "open" });

      // Disable scrolling
      document.body.classList.add("no-scroll");
    }
  }

  handleMobMenuClose() {
    // Close menu
    this.setState({ mobileMenu: "close" });
    // Re-enable scrolling
    document.body.classList.remove("no-scroll");

    for (let menuItem of MenuItems) {
      if (
        menuItem.id === "quoteSiteVisitBtn" ||
        menuItem.id === "contactUsBtn"
      ) {
      } else {
        let nestedItem = document.getElementById(`${menuItem.id}Mob`);
        let nestedDrop = document.getElementById(`${menuItem.id}MobDrop`);
        nestedItem.style.marginBottom = "0px";
        nestedDrop.style.opacity = "0";
        nestedDrop.style.pointerEvents = "none";
      }
    }
  }

  handleMobMenuExpand(idName) {
    let item = document.getElementById(`${idName}Mob`);
    let drop = document.getElementById(`${idName}MobDrop`);
    let height = drop.offsetHeight;

    // Close all dropdowns
    for (let menuItem of MenuItems) {
      if (
        menuItem.id === "quoteSiteVisitBtn" ||
        menuItem.id === "contactUsBtn" ||
        idName === menuItem.id
      ) {
        console.log(menuItem.id);
      } else {
        let nestedItem = document.getElementById(`${menuItem.id}Mob`);
        let nestedDrop = document.getElementById(`${menuItem.id}MobDrop`);
        nestedItem.style.marginBottom = "0px";
        nestedDrop.style.opacity = "0";
        nestedDrop.style.pointerEvents = "none";
      }
    }

    // Open or close clicked dropdown
    if (item.style.marginBottom === "0px") {
      item.style.marginBottom = `${height}px`;
      drop.style.opacity = "1";
      drop.style.pointerEvents = "all";
    } else {
      item.style.marginBottom = `0px`;
      drop.style.opacity = "0";
      drop.style.pointerEvents = "none";
    }
  }

  render() {
    const normalLinkStyle = {
      color: "inherit",
      textDecoration: "none",
      padding: ".65rem 0",
    };

    const shortLinkStyle = {
      color: "inherit",
      textDecoration: "none",
      padding: ".65rem 2rem .65rem 0",
    };
    const ListMegaMenuItems = (props) => {
      const items = props.items || [];
      const listItems = (items || [])?.map((item, index) => (
        <div key={index} style={{ position: "relative" }}>
          {" "}
          <MegaMenuItem
            // onMouseEnter={() => this.handleMegaMenuOpen(item.id)}
            style={{ ...(item.squash ? { letterSpacing: "-.78px" } : null) }}
            key={index}
            className="MegaMenu"
          >
            <Link
              onClick={() => this.handleMegaMenuClose()}
              to={item.link}
              style={item.short ? shortLinkStyle : normalLinkStyle}
              className="MegaMenu"
            >
              <ImagePreloader
                src={item.img}
                alt={item.name}
                className="MegaMenu"
                style={{
                  zIndex: 10,
                  objectFit: "cover",
                  width: "2.5rem",
                  height: "100%",
                  marginRight: ".5rem",
                }}
              />
              <span className="MegaMenu">{item.name}</span>
            </Link>
          </MegaMenuItem>
        </div>
      ));
      return (
        <MegaMenuWrap
          id={props?.id}
          className="MegaMenu mgDrop"
          onMouseEnter={() => this.handleMegaMenuOpen(props?.id)}
          onMouseLeave={() => this.handleMegaMenuClose()}
          style={{
            marginLeft: `${props.left || 0}`,
            gridTemplateColumns: `repeat(${props.columns || 2}, 1fr)`,
            width: props?.width,
          }}
        >
          {listItems}
        </MegaMenuWrap>
      );
    };

    const ListMenuItems = MenuItems.map((item, index) => (
      <div key={index} style={{ position: "relative" }}>
        <Link to={item.link ? item.link : "#"}>
          <MenuTxt
            id={item.id}
            className="MegaMenu"
            key={item.name}
            onMouseEnter={() => this.handleMegaMenuOpen(item?.id)}
            onMouseLeave={() => this.handleMegaMenuClose()}
            style={{ marginBottom: 0 }}
          >
            {item.name}
            {item.id !== "quoteSiteVisitBtn" && item.id !== "contactUsBtn" ? (
              <span className="MegaMenu">
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className="faIcon MegaMenu"
                  style={{ marginLeft: ".3rem" }}
                />
              </span>
            ) : null}
          </MenuTxt>
        </Link>

        {/* {item.id ==} */}
        {this.state.activeMenu === item?.id && item?.items?.length > 0 && (
          <ListMegaMenuItems
            items={item.items}
            columns={item?.columns}
            width={item?.width}
            id={item?.id}
          />
        )}
      </div>
    ));

    const ListMobSignSelectionDrop = SignSelection.map((item, index) => (
      <Link to={item.link} onClick={this.handleMobMenuClose} key={index}>
        <DropdownItem>{item.name}</DropdownItem>
      </Link>
    ));

    const ListMobSignsBySectorDrop = SignsBySector.map((item, index) => (
      <Link to={item.link} onClick={this.handleMobMenuClose} key={index}>
        <DropdownItem>{item.name}</DropdownItem>
      </Link>
    ));

    const ListMobWhatWeDoDrop = WhatWeDo.map((item, index) => (
      <Link to={item.link} onClick={this.handleMobMenuClose} key={index}>
        <DropdownItem>{item.name}</DropdownItem>
      </Link>
    ));

    const ListMobAboutUsDrop = OurStory.map((item, index) => (
      <Link to={item.link} onClick={this.handleMobMenuClose} key={index}>
        <DropdownItem>{item.name}</DropdownItem>
      </Link>
    ));

    const ListMobMenuItems = MenuItems.map((item, index) => (
      <MobMenuItemWrapper
        id={`${item.id}Mob`}
        style={{ marginBottom: "0" }}
        key={index}
      >
        <Link
          to={item.link ? item.link : "#"}
          onClick={
            item.id === "quoteSiteVisitBtn" || item.id === "contactUsBtn"
              ? this.handleMobMenuClose
              : () => this.handleMobMenuExpand(item.id)
          }
        >
          <MobMenuItem>
            {item.name}
            {item.id !== "quoteSiteVisitBtn" && item.id !== "contactUsBtn" ? (
              <span className="MegaMenu">
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className="faIcon MegaMenu"
                  style={{ marginLeft: ".3rem" }}
                />
              </span>
            ) : null}
          </MobMenuItem>
        </Link>

        {item.id === "signSelectionBtn" ? (
          <Dropdown
            id={`${item.id}MobDrop`}
            style={{ opacity: "0", pointerEvents: "none" }}
          >
            {ListMobSignSelectionDrop}
          </Dropdown>
        ) : null}

        {item.id === "signsBySectorBtn" ? (
          <Dropdown
            id={`${item.id}MobDrop`}
            style={{ opacity: "0", pointerEvents: "none" }}
          >
            {ListMobSignsBySectorDrop}
          </Dropdown>
        ) : null}

        {item.id === "whatWeDoBtn" ? (
          <Dropdown
            id={`${item.id}MobDrop`}
            style={{ opacity: "0", pointerEvents: "none" }}
          >
            {ListMobWhatWeDoDrop}
          </Dropdown>
        ) : null}

        {item.id === "ourStory" ? (
          <Dropdown
            id={`${item.id}MobDrop`}
            style={{ opacity: "0", pointerEvents: "none" }}
          >
            {ListMobAboutUsDrop}
          </Dropdown>
        ) : null}
      </MobMenuItemWrapper>
    ));

    return (
      <div>
        <DesktopMenu>
          <Row>
            <Col style={{ display: "flex", gap: 0, justifyContent: "center" }}>
              {ListMenuItems}
            </Col>
            {/* <ListMegaMenuItems
              items={SignSelection}
              columns={3}
              width="39rem"
            />

            <ListMegaMenuItems
              items={SignsBySector}
              columns={2}
              left="8.4rem"
              width="28rem"
            />

            <ListMegaMenuItems
              items={WhatWeDo}
              columns={1}
              left="16.4rem"
              width="13rem"
            />

            <ListMegaMenuItems
              items={OurStory}
              columns={1}
              left="35rem"
              width="13rem"
            /> */}
          </Row>
        </DesktopMenu>

        <Row>
          <MobileMenuWrapper
            style={
              this.state.mobileMenu === "open"
                ? { zIndex: "4" }
                : { zIndex: "3" }
            }
          >
            <Col>
              <FontAwesomeIcon
                onClick={this.handleMobMenuTrigger}
                icon={faBars}
                style={{
                  width: "1.4rem",
                  height: "1.4rem",
                }}
              />
            </Col>

            <MobileMenu
              style={
                this.state.mobileMenu === "open"
                  ? { opacity: "1", pointerEvents: "all" }
                  : { opacity: "0", pointerEvents: "none" }
              }
            >
              {ListMobMenuItems}
            </MobileMenu>
          </MobileMenuWrapper>
        </Row>
      </div>
    );
  }
}

export default withRouter(TopMenu);
