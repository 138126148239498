import React, { Component } from 'react';
import device from '../common/device';
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const YellowWrapper = styled.div`
  height: 5rem;
  background: var(--ys-yellow);
  background: linear-gradient(90deg, rgba(255,216,0,1) 0%, rgba(255,216,0,1) 50%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%); 
  margin:3rem 0 0 0;
`;

const Heading = styled.h1`
  font-size:6rem;
  font-family: Alternate,'sans-serif';
  line-height:inherit;
  text-transform:uppercase;
  background: white;
  width: 100%;
  color:var(--ys-yellow);
  margin-top: -2rem;
  padding-left: 2rem;
  transform: scale(1) rotate(0deg) translate(0px, 0px) skew(-6deg, 0deg);
  @media ${device.md} {
    font-size:4rem;
    line-height: 1;
    margin-top:-.5rem;
    min-height: 6rem;
  }
`;

// const Title = styled.h2`
//   color:var(--ys-teal);
//   text-transform:uppercase;
//   font-family: Alternate,'sans-serif';
//   font-size:3rem;
//   margin-top: -.5rem;
// `;

// const Subtitle = styled.p`
//   padding: 2rem 0;
//   width: 40%;
// `;

const Subtitle = styled.h3`
  color:var(--ys-teal);
  width: 100%;
  pointer-events:none;
  margin-top:1rem;
  font-size:1.5rem!important;
  padding: 2rem 0;
  width: 100%;
  @media ${device.md} {
    margin-top: 3rem;
  }
`;

const Space = styled.div`
  height:2.5rem;
`;

class HeaderSimple extends Component {
  render() {
    return (
      <div>
        <YellowWrapper>
          <Container>
            <Row>
              <Col>
                <Heading>{this.props.heading}</Heading>
              </Col>
            </Row>
          </Container>
        </YellowWrapper>

        {this.props.subtitle ? ( // Show the container only if there is a subtitle, otherwise add a little space
          <Container>
          <Row>
            <Col>
              {/* <Title>{this.props.title}</Title> */}
              <Subtitle>{this.props.subtitle}</Subtitle>
            </Col>
          </Row>
        </Container>
        ):(
        <Space/> 
        )}

      </div>

    );
  }
}

export default HeaderSimple;