import { Link } from "react-router-dom/cjs/react-router-dom.min";
import GalleryText from "../fragments/services/GalleryText";

const shopsRetailSigns = {
  name: "Shop & Retail Signs",
  content:
    "Shop signs can effectively drive footfall and are available in various materials and styles – from aluminium composite and stainless steel to acrylic and wood. Shop sign trays are often made from aluminium or aluminimum composite. And available in a range of colours.",
  href: "/shops-retail-signs",
  footerContent: (
    <GalleryText>
      <p>
        In such a digital age, surrounded by modern technological ways of
        marketing products to consumers, often businesses need to seek more
        fool-proof and traditional forms of advertisement. Whilst we know social
        media can be powerful, sometimes going back to basics with visual
        marketing is the winner. As one of the oldest and most effective
        marketing models for the high street, shop and retail signs are a
        fantastic form of around-the-clock advertisement that leaves a lasting
        impression on consumers.
      </p>

      <p>
        Why should you care about shop signage? Picture a packed high street:
        What is going to pull a shopper away from the hustle and bustle and draw
        them to your business? Better yet, what can you create as a 24/7
        advertisement with minimal upkeep without pulling at your purse strings?
        Overhead signage allows consumers to see you from all directions,
        whether it's on their daily commute to and from work or just popping
        down the road for a pint of milk, shop signage creates a commercial
        experience that your potential customers will remember.
      </p>

      <p>
        Now let&rsquo;s look at some of your options. The most successful
        businesses have their own identity, and you want your signage to align
        with yours. Signs are a great way to add a sense of luxury and
        exclusivity to your business and standoff letters provide a sleek and
        creativity-friendly option to represent your business and come in a
        variety of shapes sizes and colours to cater to the vibe of your brand.
        Perhaps you&rsquo;re more traditional? There are plenty of ways to
        customise your product to align with your pathos including experimenting
        with colour and texture for a fantastic result.
      </p>

      <p>
        However, price should never compromise on creativity and luckily, we
        offer great value and competitive signage to realise your vision. Two of
        our most popular and budget-friendly, overhead shop signage are flat
        panel and sign trays in aluminium/ aluminium composite which are
        extremely diversifiable with aluminium able to be powder-coated in any
        RAL of your choosing and aluminium composite being available in a
        variety of different colours, to achieve your perfect product.
      </p>

      <p>
        It can be hard to source the perfect production team, especially in busy
        and expansive London, so we want to unload one of your burdens by
        telling you about our Yellow Signs workshop. We fabricate almost
        everything we produce in our production centre in Battersea, using our
        highly experienced team, meaning you have high-quality and excellent
        signage and graphics for shops and retailers in London, ready to be
        installed at your convenience.
      </p>

      <p>
        For examples of our past works, you can check out{" "}
        <Link to="case-studies">https://yellowsigns.co.uk/case-studies</Link>
      </p>

      <p>
        If you&rsquo;re interested in decking out your business with us &ndash;
        using graphics and signage &ndash; please contact one of our signage
        experts at 020 7350 1567 or email{" "}
        <a href="mailto:info@yellowsigns.co.uk">info@yellowsigns.co.uk</a>
      </p>

      <p>
        Alternatively, to request a quick quote visit{" "}
        <Link to="quote-site-visit">
          https://yellowsigns.co.uk/quote-site-visit
        </Link>{" "}
        to get started!
      </p>
    </GalleryText>
  ),
  gallery: [
    {
      img: "/img/signsbysector/8-Shop-Retail/pushed-though-acrylic-letters-faced-with-vinyl.jpg",
      title: "Pushed Through Acrylic Letters Faced With Vinyl",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/halo-illuminated-3d-built-up-letters.jpg",
      title: "Halo Illuminated 3D Built Up Letters",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/aluminium-illuminated-sign-trays-with-acrylic-letters.jpg",
      title: "Aluminium Illuminated Sign Trays With Acrylic Letters",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/10mm-wet-coated-matt-gray-acrylic-letters.jpg",
      title: "10mm Wet Coated Matt Gray Acrylic Letters",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/full-colour-digitally-printed-window-graphics.jpg",
      title: "Full Colour Digitally Printed Window Graphics",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/5mm-black-acrylic-letters--clear-acrylic-plaque.jpg",
      title: "5mm Black Acrylic Letters & Clear Acrylic Plaque",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/3d-built-up-letters.jpg",
      title: "3D Built Up Letters",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/BevelCutStandOffLetterswithTroughLighting.jpg",
      title: "Bevel Cut Stand-Off Letters with Trough Lighting",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/CutFullColourVinyl.jpg",
      title: "Cut & Full Colour Vinyl",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/FlatCutAcrylic.jpg",
      title: "Flat Cut Acrylic",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/FlatCutAcrylicLetters.jpg",
      title: "Flat Cut Acrylic Letters",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/FlatCutAcrylicLettersCutVinyl.jpg",
      title: "Flat Cut Acrylic Letters & Cut Vinyl",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/FlatCutAcryliconAluminiumCompositeTray.jpg",
      title: "Flat Cut Acrylic on Aluminium Composite Tray",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/FlatCutLettersTroughLighting.jpg",
      title: "Flat Cut Letters & Trough Lighting",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/FlatCutVinylAluminiumCompositeTray.jpg",
      title: "Flat Cut Vinyl Aluminium Composite Tray",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/FullColourGraphics.jpg",
      title: "Full Colour Graphics",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/FullColourSwingProjectingSign.jpg",
      title: "Full Colour Swing Projecting Sign",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/PushedThroughAcrylicAluminiumCompositeTray.jpg",
      title: "Pushed-Through Acrylic Aluminium Composite Tray",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/PushedThroughAcrylicAluminiumCompositeTray2.jpg",
      title: "Pushed-Through Acrylic Aluminium Composite Tray",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/StainlessSteelAcrylicIlluminatedProjectingSign.jpg",
      title: "Stainless Steel & Acrylic Illuminated Projecting Sign",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/WET_COATED_ACRYLIC_LETTERS_FROSTING_AND_WINDOW_GRAPHICS.jpg",
      title: "Wet Coated Acrylic Letters, Frosting And Window Graphics",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/ALUMINIUM_COMPOSITE_SIGN_TRAY_WITH_WET_COATED_ACRYLIC_LETTERS.jpg",
      title: "Aluminium Composite Sign Tray With Wet Coated Acrylic Letters",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/BLACK_TINT_APPLIED_TO_INSIDE_OF_GLASS__CUT_WHITE_VINYL_APPLIED_TO_OUTSIDE_OF_GLASS.jpg",
      title:
        "Black Tint Applied To Inside Of Glass & Cut White Vinyl Applied To Outside Of Glass",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/FRONT_ILLUMINATION_3D_LETTERS_&_FRET_CUT_LOGO.jpg",
      title: "Front Illumination 3D Letters & Fret Cut Logo",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/FUL_COLOUR_PRINTED_&_WRAPPED_25MM_FOAM_PVC_SWING_PROJECTING_SIGN.jpg",
      title: "Ful Colour Printed & Wrapped 25mm Foam PVC Swing Projecting Sign",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/FULL_COLOUR_&_WHITE_CUT_VINYL_WINDOW_GRAPHICS.jpg",
      title: "Full Colour & White Cut Vinyl Window Graphics",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/FULL_COLOUR_PRINTED_CONTOUR_CUT_WINDOW_GRAPHICS.jpg",
      title: "Full Colour Printed Contour Cut Window Graphics",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/PUSHED-THROUGH_ACRYLIC_FACED_IN_GOLD_VINYL_INTERNALLY_ILLUMINATED_PRINTED_SIGN_TRAY.jpg",
      title:
        "Pushed-Through Acrylic Faced In Gold Vinyl Internally Illuminated Printed Sign Tray",
    },
    {
      img: "/img/signselection/1-Shop-Retail-Signs/PUSHED-THROUGH_ACRYLIC_FACED_IN_GOLD_VINYL_INTERNALLY_ILLUMINATED_SIGN_TRAY__PROJECTING_SIGN.jpg",
      title:
        "Pushed-Through Acrylic Faced In Gold Vinyl Internally Illuminated Sign Tray + Projecting Sign",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/PUSHED-THROUGH_ACRYLIC_FACED_IN_GOLD_VINYL_INTERNALLY_ILLUMINATED_SIGN_TRAY.jpg",
      title:
        "Pushed-Through Acrylic Faced In Gold Vinyl Internally Illuminated Sign Tray",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/PUSHED-THROUGH_ACRYLIC_INTERNALLY_ILLUMINATED_SIGN_TRAY_WITH_LETTERS_FACED_IN_VINYL.jpg",
      title:
        "Pushed-Through Acrylic Internally Illuminated Sign Tray With Letters Faced In Vinyl",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/STOCK_BLUE_ALUMINIUM_COMPOSITE_SIGN_TRAY_WITH_PUSHED-THROUGH_ACRYLIC_LETTERS_&_MATCHING_PROJECTING_SIGN.jpg",
      title:
        "Stock Blue Aluminium Composite Sign Tray With Pushed-Through Acrylic Letters & Matching Projecting Sign",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/WET_COATED_GOLD_HALO_ILLUMINATED_3D_LETTERS.jpg",
      title: "Wet Coated Gold Halo Illuminated 3D Letters",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/WET_COATED_ACRYLIC_LETTERS,_FROSTING_AND_WINDOW_GRAPHICS.jpg",
      title: "Wet Coated Acrylic Letters, Frosting And Window Graphics",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/WHITE_ALUMINIUM_COMPOSITE_SIGN_TRAY_WITH_10MM_MATT_BLACK_ACRYLIC_LETTERS_APPLIED_DIRECTLY_TO_FACE.jpg",
      title:
        "White Aluminium Composite Sign Tray With 10mm Matt Black Acrylic Letters Applied Directly To Face",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/FRONT_ILLUMINATED_3D_BUILT-UP_LETTERS.jpg",
      title: "Front Illuminated 3D Built-Up Letters",
    },
  ],
};

const threeDBuildUpLetters = {
  name: "3D & Built-Up Letters",
  href: "/3d-builtup-letters",
  content:
    "3d letters are made by building up materials such as acrylic or stainless steel. They can be faced in a wide range of materials and colours and can be illuminated with led lights if required.",
  footerContent: (
    <GalleryText>
      <p>
        One of the most crucial steps of good business is making an impact on
        people. Whether it's via social media, an exhibition or in person,
        making your business stand out from the competition protects your
        business and increases your business. Whilst there are a variety of
        different options you could choose from; our favourite is hands down our
        3D and built-up letter signage.
      </p>

      <p>
        3D signage both literally and metaphorically makes your business stand
        out on the Highstreet; since it stands off from its base and creates
        dimension, it creates a modern finish on your signage and is easy to see
        from greater distances achieving better brand visibility. What&rsquo;s
        more? Built-up lettering can be customised using a variety of materials,
        colours and shapes to suit all spaces, branding and budgets.
      </p>

      <p>
        So, what are some of your options? Well, to name a few, our team can
        produce a variety of different built-up types including flat letters
        which come in a range of materials including aluminium, stainless steel
        and acrylic. To create an extra impact, you can even go as far as
        illuminating your lettering to &lsquo;shine a spotlight' on your
        business and draw in new customers. Built-up signage is the perfect
        overhead, outdoor signage, so much so that we want to offer you the
        ability to choose the application of your product, including the fixings
        we use on the signage to align with your budget requirements.
      </p>

      <p>
        To see some of our past work, please visit:{" "}
        <Link to="case-studies">https://yellowsigns.co.uk/case-studies</Link>
        <br />
        So aside from brand visibility, what makes built-up letters such a
        fantastic choice for your business? Built-up signage is a rare
        opportunity to implement the fun or creative elements of your business
        in a manner that keeps within your professionalism. Maybe you&rsquo;re a
        brand-new coffee shop, and built-up letters can be applied by using a
        personalised colour palette on an interesting backing to shed light on a
        small business. Or maybe you&rsquo;re opening a new branch of a
        well-known chain and want to make it known to your customers. Whatever
        it may be, we want to assure you there are plenty of diverse
        alternatives to highlight your business.
      </p>

      <p>
        Where do we come in? Built-up and 3D lettering is one of our most
        popular options and since we are a London-based business and produce
        almost all of our bespoke products in our Battersea HQ, it means we can
        complete a quick turnaround without outsourcing different products from
        different locations. This allows you to receive your signage or
        graphics, especially built-up signage, in London, anywhere in London. If
        you&rsquo;re struggling for inspiration and would like some help
        producing something unique and exciting, feel free to contact us; our
        experienced team are more than happy to help you.
      </p>

      <p>
        If you&rsquo;re interested in our services &ndash; using graphics and
        signage &ndash; please contact one of our signage experts on 020 7350
        1567 or email{" "}
        <a href="mailto:info@yellowsigns.co.uk">info@yellowsigns.co.uk</a>
      </p>

      <p>
        Alternatively, to request a quick quote visit{" "}
        <Link to="quote-site-visit">
          https://yellowsigns.co.uk/quote-site-visit
        </Link>{" "}
        to get started!
      </p>
    </GalleryText>
  ),
  gallery: [
    {
      img: "/img/signselection/2-3D-Built-Up-Letters/FRONT-ILLUMINATED-3D-LETTERS-WITH-FULL-COLOUR-PRINT-TO-FACE-+-MATCHING-SUSPENDED-LOZENGE.jpg",
      title:
        "FRONT ILLUMINATED 3D LETTERS WITH FULL COLOUR PRINT TO FACE + MATCHING SUSPENDED LOZENGE",
    },
    {
      img: "/img/signselection/2-3D-Built-Up-Letters/FRONT-ILLUMINATED-3D-LETTERS-WITH-FULL-COLOUR-PRINT-TO-FACE.jpg",
      title: "FRONT ILLUMINATED 3D LETTERS WITH FULL COLOUR PRINT TO FACE",
    },
    {
      img: "/img/signselection/2-3D-Built-Up-Letters/FRONT-ILLUMINATED-3D-LETTERS-WITH-FULL-COLOUR-PRINT-TO-ROUNDEL-LOGO.jpg",
      title:
        "FRONT ILLUMINATED 3D LETTERS WITH FULL COLOUR PRINT TO ROUNDEL LOGO",
    },
    {
      img: "/img/signselection/2-3D-Built-Up-Letters/Chenye-Walk.jpg",
      title:
        "HALO ILLUMINATED 3D LETTERS WET COATED TO MATT GOLD WITH CONCEALED WIRING",
    },
    {
      img: "/img/signselection/2-3D-Built-Up-Letters/HALO-ILLUMINATED-3D-LETTERS-WET-COATED-TO-MATT-GOLD.jpg",
      title: "HALO ILLUMINATED 3D LETTERS WET COATED TO MATT GOLD",
    },
    {
      img: "/img/signselection/2-3D-Built-Up-Letters/HALO-ILLUMINATED-3D-LETTERS-WET-COATED-TO-PANTONE-COLOURS.jpg",
      title: "HALO ILLUMINATED 3D LETTERS WET COATED TO PANTONE COLOURS",
    },
    {
      img: "/img/signselection/2-3D-Built-Up-Letters/HALO-ILLUMINATED-3D-LETTERS-WET-COATED-TO-RAL.jpg",
      title: "HALO ILLUMINATED 3D LETTERS WET COATED TO RAL",
    },
    {
      img: "/img/signselection/2-3D-Built-Up-Letters/HALO-ILLUMINATED-ACRYLIC-LETTERS-WET-COATED-TO-MATT-GOLD.jpg",
      title: "HALO ILLUMINATED ACRYLIC LETTERS WET COATED TO MATT GOLD",
    },
    {
      img: "/img/signselection/2-3D-Built-Up-Letters/halo-illuminated-3d-built-up-letters.jpg",
      title: "Halo Illuminated 3D Built Up Letters",
    },
    {
      img: "/img/signselection/2-3D-Built-Up-Letters/25MM_WET_COATED_GOLD_3D_LETTERS_WITH_PINK_HALO_ILLUMINATION.jpg",
      title: "25MM_WET_COATED_GOLD_3D_LETTERS_WITH_PINK_HALO_ILLUMINATION",
    },
    {
      img: "/img/signselection/2-3D-Built-Up-Letters/25MM_WET_COATED_GOLD_3D_LETTERS_WITH_PINK_HALO__ILLUMINATION_-_WORKSHOP.jpg",
      title:
        "25MM_WET_COATED_GOLD_3D_LETTERS_WITH_PINK_HALO__ILLUMINATION_-_WORKSHOP",
    },
    {
      img: "/img/signselection/2-3D-Built-Up-Letters/FRONT_ILLUMINATION_3D_LETTERS_&_FRET_CUT_LOGO.jpg",
      title: "FRONT_ILLUMINATION_3D_LETTERS_&_FRET_CUT_LOGO",
    },
    {
      img: "/img/signselection/2-3D-Built-Up-Letters/WET_COATED_GOLD_HALO_ILLUMINATED_3D_LETTERS.jpg",
      title: "WET_COATED_GOLD_HALO_ILLUMINATED_3D_LETTERS",
    },
    {
      img: "/img/signselection/2-3D-Built-Up-Letters/25MM_WET_COATED_ACRYLIC_LETTERS_WITH_BLUE_HALO_ILLUMINATION.jpg",
      title: "25MM_WET_COATED_ACRYLIC_LETTERS_WITH_BLUE_HALO_ILLUMINATION",
    },
    {
      img: "/img/signselection/2-3D-Built-Up-Letters/FRONT_ILLUMINATION_3D_LETTERS_APPLIED_DIRECTLY_TO_OUTSIDE_OF_GLASS.jpg",
      title:
        "FRONT ILLUMINATION 3D LETTERS APPLIED DIRECTLY TO OUTSIDE OF GLASS",
    },
    {
      img: "/img/signselection/2-3D-Built-Up-Letters/FRONT_ILLUMINATED_3D_BUILT-UP_LETTERS.jpg",
      title: "FRONT ILLUMINATED 3D BUILT-UP LETTERS",
    },
    {
      img: "/img/signselection/2-3D-Built-Up-Letters/FlatFaceAluminiumLetterFaceOn.jpg",
      title: "Flat Face Aluminium Letter Face On",
    },
    {
      img: "/img/signselection/2-3D-Built-Up-Letters/FlatFaceAluminiumLetterCloseUp.jpg",
      title: "Flat Face Aluminium Letter Close Up",
    },
    {
      img: "/img/signselection/2-3D-Built-Up-Letters/FlatFaceIlluminatedLetterWhite.jpg",
      title: "Flat Face Illuminated Letter White",
    },
    {
      img: "/img/signselection/2-3D-Built-Up-Letters/FlatFaceStainlessSteelLetterCloseUp.jpg",
      title: "Flat Face Stainless Steel Letter Close Up",
    },
    {
      img: "/img/signselection/2-3D-Built-Up-Letters/FlatFaceStainlessSteelLetter.jpg",
      title: "Flat Face Stainless Steel Letter",
    },
    {
      img: "/img/signselection/2-3D-Built-Up-Letters/HaloIlluminatedLetterReverse.jpg",
      title: "Halo Illuminated Letter Reverse",
    },
    {
      img: "/img/signselection/2-3D-Built-Up-Letters/HaloIlluminatedLetter.jpg",
      title: "Halo Illuminated Letter",
    },
    {
      img: "/img/signselection/2-3D-Built-Up-Letters/InsertedFaceIlluminatedLetterCloseUp.jpg",
      title: "Inserted Face Illuminated Letter Close Up",
    },
    {
      img: "/img/signselection/2-3D-Built-Up-Letters/InserterFaceIlluminatedLetter.jpg",
      title: "Inserter Face Illuminated Letter",
    },
    {
      img: "/img/signselection/2-3D-Built-Up-Letters/MarqueeLightBulbArrow.jpg",
      title: "Marquee Light Bulb Arrow",
    },
    {
      img: "/img/signselection/2-3D-Built-Up-Letters/MarqueeLightBulbIlluminatedLetter.jpg",
      title: "Marquee Light Bulb Illuminated Letter",
    },
    {
      img: "/img/signselection/2-3D-Built-Up-Letters/MarqueeLightBulbIllumuninatedLetters.jpg",
      title: "Marquee Light Bulb Illuminated Letters",
    },
    {
      img: "/img/signselection/2-3D-Built-Up-Letters/MarqueeLightBulbLetter.jpg",
      title: "Marquee Light Bulb Letter",
    },
    {
      img: "/img/signselection/2-3D-Built-Up-Letters/RimFaceAluminiumLetter.jpg",
      title: "Rim Face Aluminium Letter",
    },
    {
      img: "/img/signselection/2-3D-Built-Up-Letters/RimFaceIlluminatedLetterCloseUp.jpg",
      title: "Rim Face Illuminated Letter Close Up",
    },
    {
      img: "/img/signselection/2-3D-Built-Up-Letters/RimFaceLetterCloseUp.jpg",
      title: "Rim Face Letter Close Up",
    },
    {
      img: "/img/signselection/2-3D-Built-Up-Letters/RimReturnLetterCloseUp.jpg",
      title: "Rim & Return Letter Close Up",
    },
    {
      img: "/img/signselection/2-3D-Built-Up-Letters/RimReturnPaintedLetter.jpg",
      title: "Rim & Return Painted Letter",
    },
    {
      img: "/img/signselection/2-3D-Built-Up-Letters/RimReturnStainlessSteelLetter.jpg",
      title: "Rim & Return Stainless Steel Letter",
    },
  ],
};

const wayfindingPlaques = {
  name: "Wayfinding & Plaques",
  href: "/plaques-wayfinding",
  content:
    "Plaques and general wayfinding is the best way to encourage people to use a particular route or draw attention to areas or aspects of your business. Plaques are available in a variety of materials and thicknesses including acrylic and solid metal.",
  footerContent: (
    <GalleryText>
      <p>
        When you drive down a one-way street, what informs you that you're going
        the right way? Do you live in the area and know the location? Or perhaps
        your friend clued you in. Usually, it's the signage and graphics the
        government have propped up on the roadside that guides you into making
        the discovery, choosing the correct route and preventing a pesky and
        unnecessary fine. This concept is well-known among most and wayfinding
        signage is everywhere, even when you&rsquo;re not looking for it.
        However, wayfinding signage isn't just applied on the roads or in the
        traffic but often in business, commercial and even domestic spaces.
      </p>

      <p>
        Let&rsquo;s take a look at what we mean by wayfinding signage.
        Wayfinding signage combines forms of directional signage and graphics
        and presents them in a variety of colours, styles and methods to move
        people in the particular direction you want. One of the most popular
        items we produce is wayfinding plaques which are the perfect
        wall-mounted form of signage to inform guests, customers, employees and
        clients where to go to receive the service they are looking for. It
        works very well near escalators as it helps navigate the abundance of
        floors to sort through. However, there are also more creative ways of
        creating an impact and directing people.
      </p>

      <p>
        When holding an event, often the venue is unfamiliar to guests making it
        difficult for them to navigate. By installing aluminium composite
        panelling on a wall or a large plaque, you can maximise your brand
        creativity with colour and shape to point people in the direction of
        your space. This can include text, arrows and even maps to create a
        professional and well-organised event or exhibition; we also offer a
        variety of materials from aluminium and vinyl to acrylic.
      </p>

      <p>
        Your plaque choices are not just limited to directional signage either.
        You can use plaques to inform or to lay out rules to keep your customers
        or visitors in check. One of the ways you may see this implemented is in
        health and safety signage. From emergency exit signage for hospitality,
        business, and educational spaces to danger signs to prevent injury at
        construction sites. Is your space a smoke-free zone? Then make it known!
        Plaques are a great way to keep people safe and maintain order.
      </p>

      <p>
        To view some of our past work, please visit:{" "}
        <Link to="case-studies">https://yellowsigns.co.uk/case-studies</Link>{" "}
        <br />
        <br />
        Where do we come in? Since we are a London-based business, our team
        produce almost everything we do in our Battersea production centre to be
        timely and efficient. We know London is a fast-paced working environment
        you need a quick turnaround of products to help your business function
        smoothly so we work in-house to get your signage and graphics to you,
        anywhere in London. If you would like some design help or are seeking
        something bespoke, feel free to contact our team who will be more than
        happy to assist you.
      </p>

      <p>
        If you&rsquo;re interested in producing your signage with us, please
        contact one of our signage experts at 020 7350 1567 or email{" "}
        <a href="mailto:info@yellowsigns.co.uk">info@yellowsigns.co.uk</a>{" "}
      </p>

      <p>
        Alternatively, to request a quick quote visit{" "}
        <Link to="quote-site-visit">
          https://yellowsigns.co.uk/quote-site-visit
        </Link>{" "}
        to get started!
      </p>
    </GalleryText>
  ),
  gallery: [
    {
      img: "/img/signselection/Plaques-Wayfinding/8MM-CLEAR-ACRYLIC-PLAQUES-WITH-FULL-COLOUR-PRINT-TO-REVERSE-+-STAND-OFF-STUDS.jpg",
      title:
        "8MM CLEAR ACRYLIC PLAQUES WITH FULL COLOUR PRINT TO REVERSE + STAND-OFF STUDS",
    },
    {
      img: "/img/signselection/Plaques-Wayfinding/INTERNALLY-ILLUMINATED-WAYFINDING-POST-SIGN-WITH-FRET-CUT-TEXT.jpg",
      title: "INTERNALLY ILLUMINATED WAYFINDING POST SIGN WITH FRET CUT TEXT",
    },
    {
      img: "/img/signselection/Plaques-Wayfinding/plaques-wayfinding-1.jpg",
      title: "ACRYLIC PLAQUES & FULL COLOUR ALUMINIUM COMPOSITE PANELS",
    },
    {
      img: "/img/signselection/Plaques-Wayfinding/plaques-wayfinding-2.jpg",
      title: "BLACK FILLED ETCHED STAINLESS STEEL PLAQUE",
    },
    {
      img: "/img/signselection/Plaques-Wayfinding/plaques-wayfinding-3.jpg",
      title: "BLUE FILLED ETCHED BRUSHED STAINLESS STEEL PLAQUES",
    },
    {
      img: "/img/signselection/Plaques-Wayfinding/plaques-wayfinding-4.jpg",
      title: "CLEAR ACRYLIC PLAQUE WITH FULL COLOUR PRINT TO REVERSE ON STUDS",
    },
    {
      img: "/img/signselection/Plaques-Wayfinding/plaques-wayfinding-5.jpg",
      title:
        "CLEAR ACRYLIC PLAQUES WITH FROST & FULL COLOUR PRINT TO REVERSE ON STUDS",
    },
    {
      img: "/img/signselection/Plaques-Wayfinding/plaques-wayfinding-6.jpg",
      title: "CLEAR ACRYLIC PLAQUES WITH FULL COLOUR PRINT TO REVERSE ON STUDS",
    },
    {
      img: "/img/signselection/Plaques-Wayfinding/plaques-wayfinding-7.jpg",
      title:
        "CLEAR ACRYLIC WAYFINDING PLAQUES WITH FULL COLOUR PRINT TO REVERSE ON STUDS",
    },
    {
      img: "/img/signselection/Plaques-Wayfinding/plaques-wayfinding-8.jpg",
      title: "FULL COLOUR & BLACK FILLED ETCHED STAINLESS STEEL PLAQUE",
    },
    {
      img: "/img/signselection/Plaques-Wayfinding/plaques-wayfinding-9.jpg",
      title: "FULL COLOUR PRINTED ALUMINIUM COMPOSITE TRAFFIC SIGNS",
    },
    {
      img: "/img/signselection/Plaques-Wayfinding/BRUSHED_STAINLESS_STEEL_PLAQUES_WITH_COLOUR_IN-FILL.jpg",
      title: "BRUSHED STAINLESS STEEL PLAQUES WITH COLOUR IN-FILL",
    },
    {
      img: "/img/signselection/Plaques-Wayfinding/POWDER_COATED_ALUMINUM_PLAQUES_WITH_WHITE_VINYL_LOGO.jpg",
      title: "POWDER COATED ALUMINUM PLAQUES WITH WHITE VINYL LOGO",
    },
    {
      img: "/img/signselection/Plaques-Wayfinding/8MM_CLEAR_ACRYLIC_PLAQUES_WITH_FULL_COLOUR_PRINT_TO_BACK_&_STUDS.jpg",
      title: "8MM CLEAR ACRYLIC PLAQUES WITH FULL COLOUR PRINT TO BACK & STUDS",
    },
    {
      img: "/img/signselection/Plaques-Wayfinding/PHOTOLUMINESCENT_ACRYLIC_PLAQUE_WITH_BLACK_CUT_VINYL_APPLIED_TO_FACE.jpg",
      title:
        "PHOTOLUMINESCENT ACRYLIC PLAQUE WITH BLACK CUT VINYL APPLIED TO FACE",
    },
    {
      img: "/img/signselection/Plaques-Wayfinding/ALUMINUM_COMPOSITE_WAYFINDING_SIGNS.jpg",
      title: "ALUMINUM COMPOSITE WAYFINDING SIGNS",
    },
    {
      img: "/img/signselection/Plaques-Wayfinding/ANTI-GRAFFITI_HOARDING.jpg",
      title: "ANTI-GRAFFITI HOARDING",
    },
  ],
};

const privacyFrosting = {
  name: "Privacy Frosting",
  href: "/privacy-frosting",
  footerContent: (
    <GalleryText>
      <p>
        Brands need to be enigmatic; parts of your business need to be heard and
        not seen. Privacy is paramount to every business in order to evolve and
        grow without those glaring, prying eyes. Whether you&rsquo;re an
        office-based business that wants to keep its inner workings private or a
        shopfront business that seek to offer your customers privacy and
        security, it&rsquo;s vital to provide the perfect working and business
        environment for your employees and clients. Luckily, we offer a range of
        options for those private moments in business and one of them is our
        privacy frosting service.
      </p>

      <p>
        So, what is Privacy frosting? Privacy frosting does exactly what it says
        on the tin; essentially, it's a window-based sticker editable according
        to your privacy and colour needs and offers an etched and blurred screen
        to block the visuals from both directions. They are low maintenance and
        easily applied graphics that are self-adhered for high quality and clean
        alternatives to nets, blinds and curtains. They can come self-adhered
        and removable, if you&rsquo;re looking for something on a more temporary
        basis, for example, a rental space. Or maybe you want to change your
        design as your business evolves and want to be able to create something
        interchangeable or diverse. Whatever your needs, we are happy to lend a
        helping hand.
      </p>

      <p>
        At Yellow Signs, we believe you should have your cake and eat it too and
        believe your privacy shouldn&rsquo;t compromise your creativity.
        Therefore, we offer a variety of designs and colours to translate
        whatever image of your business you want whilst still leaving plenty to
        the imagination. The frosting also doesn&rsquo;t need to be one or
        nothing, so we provide partial frosting methods including frosted
        outlines of images or words to prevent your business or domestic space
        from looking flat and lifeless.
      </p>

      <p>
        For some of our past work, please check out:{" "}
        <Link to="case-studies">https://yellowsigns.co.uk/case-studies</Link>
      </p>

      <p>
        We understand the need for a quick and final product which is why we
        produce almost all of everything we do in-house, in our Battersea
        workshop, to supply your products quickly and timely. Our team also know
        how stressful it is to find and source a team with-= a high
        concentration of services in and around London; so, rest assured that
        our highly experienced and creative team can get your signage and
        graphics, such as privacy frosted graphics, anywhere you are in London.
        We are also happy to help with any bespoke or more detailed services you
        are looking for!
      </p>

      <p>
        If you&rsquo;re interested in working with us &ndash; using graphics and
        signage &ndash; please contact one of our signage experts on 020 7350
        1567 or email{" "}
        <a href="mailto:info@yellowsigns.co.uk">info@yellowsigns.co.uk</a>
      </p>

      <p>
        Alternatively, to request a quick quote visit{" "}
        <Link to="quote-site-visit">
          https://yellowsigns.co.uk/quote-site-visit
        </Link>{" "}
        to get started!
      </p>
    </GalleryText>
  ),
  content:
    "Frosted window film is used to provide privacy in offices and industries such as medical services. Frosted film can be cut and printed onto almost any shape or size. Frosting is often required in offices as part of health and safety measures.",
  gallery: [
    {
      img: "/img/signselection/9-Frosted-Privacy-Vinyl/FULL-COLOUR-PRINTED-FROSTED-VINYL-STRIPS.jpg",
      title: "FULL COLOUR PRINTED FROSTED VINYL STRIPS",
    },
    {
      img: "/img/signselection/9-Frosted-Privacy-Vinyl/cut-to-shape-frosted-vinyl--cut-orange-vinyl.jpg",
      title: "Cut to Shape Frosted Vinyl + Cut Orange Vinyl",
    },
    {
      img: "/img/signselection/9-Frosted-Privacy-Vinyl/ContourCutFrostedVinyl.jpg",
      title: "Contour Cut Frosted Vinyl",
    },
    {
      img: "/img/signselection/9-Frosted-Privacy-Vinyl/ContourCutFrostedVinyl2.jpg",
      title: "Contour Cut Frosted Vinyl",
    },
    {
      img: "/img/signselection/9-Frosted-Privacy-Vinyl/FullColourPrintedFrostedVinyl.jpg",
      title: "Full Colour Printed Frosted Vinyl",
    },
    {
      img: "/img/signselection/9-Frosted-Privacy-Vinyl/PrintedFrostedVinyl.jpg",
      title: "Printed Frosted Vinyl",
    },
    {
      img: "/img/signselection/9-Frosted-Privacy-Vinyl/ReverseCutForstedVinyl.jpg",
      title: "Reverse Cut Frosted Vinyl",
    },
    {
      img: "/img/signselection/9-Frosted-Privacy-Vinyl/ReverseCutForstedVinyl2.jpg",
      title: "Reverse Cut Frosted Vinyl",
    },
    {
      img: "/img/signselection/9-Frosted-Privacy-Vinyl/ReverseCutFrostedVinyl.jpg",
      title: "Reverse Cut Frosted Vinyl",
    },
    {
      img: "/img/signselection/9-Frosted-Privacy-Vinyl/WET_COATED_ACRYLIC_LETTERS,_FROSTING_AND_WINDOW_GRAPHICS.jpg",
      title: "WET_COATED_ACRYLIC_LETTERS,_FROSTING_AND_WINDOW_GRAPHICS",
    },
    {
      img: "/img/signselection/9-Frosted-Privacy-Vinyl/FULL_COLOUR_PRINTED_VINYL_MANIFESTATIONS.jpg",
      title: "FULL_COLOUR_PRINTED_VINYL_MANIFESTATIONS",
    },
    {
      img: "/img/signselection/9-Frosted-Privacy-Vinyl/CUT_PRIVACY_FROSTED_VINYL.jpg",
      title: "CUT_PRIVACY_FROSTED_VINYL",
    },
    {
      img: "/img/signselection/9-Frosted-Privacy-Vinyl/FULL_COLOUR_PRINTED_FROSTED_VINYL.jpg",
      title: "FULL_COLOUR_PRINTED_FROSTED_VINYL",
    },
  ],
};

const vehicleGraphics = {
  name: "Vehicle Graphics",
  href: "/vehicle-graphics",
  content:
    "A perfect marketing tool and available in flat cuy vinyl, full colour contour cut vinyl, partial wrap or full wraps. Vehicle graphics can transform a car, van or any vehicle and increase brand awareness or help with specific promotions.",
  footerContent: (
    <GalleryText>
      <p>
        Let&rsquo;s face it: advertising is expensive. With upkeep, production
        and location fees, some forms of advertisement can be extremely costly.
        What&rsquo;s more, often it&rsquo;s not very effective. That&rsquo;s why
        we want to make marketing simple for you and bring your business to the
        customers, so they bring their business to you. We know what
        you&rsquo;re thinking, how can you achieve this with graphics that sit
        firmly within my budget? Well, that&rsquo;s where vehicle graphics come
        to play.
      </p>

      <p>
        Unlike your bog-standard form of display or sign, which sits statutory
        in one place with little reach to the public, vehicle graphics are a
        literal &lsquo;driving force&rsquo; of business and act as an amazing
        portable advertisement. If you&rsquo;re a business that utilises its
        vehicles and hasn't already wrestled with the idea of vehicle graphics,
        you&rsquo;re missing out.
      </p>

      <p>
        Whether you&rsquo;re returning to or from a job site or are stuck in a
        ridiculous traffic jam or just parked up on a side street, vehicle
        graphics and wraps are an on-the-go form of advertisement and make use
        of every minute of every day, 24/7, 365. With graphics being an
        extremely malleable industry, we are able to find the perfect graphics
        e.g. vinyl graphics for your vehicle as they can fit onto almost any
        vehicle material and shape. Not only this, but we can tailor a solution
        to you: Looking to display contact information? Vinyl graphics can be
        popped onto your van or car to list all that important information
        pertinent to your business. Or maybe you are just looking to spice up
        your van without breaking the bank, vehicle wraps can be placed on the
        entirety of your vehicle exterior to personalise any vehicle of
        interest.
      </p>

      <p>
        What&rsquo;s more? Our vehicle wraps and graphics are long-lasting and
        waterproof meaning you have less maintenance to keep up with, so you can
        focus on all your new customers instead of the pesky replacements! To
        see some of our previous work, please feel free to visit:{" "}
        <Link to="case-studies">https://yellowsigns.co.uk/case-studies</Link>
      </p>

      <p>
        How can we help you? Our highly experienced team produce almost all of
        our final products in our Battersea HQ, meaning they will be able to
        produce an amazing result for you completely in-house with a quick
        turnaround. This also allows us to get your signage and graphics,
        especially concerning vehicles, anywhere in London, so you can get back
        to doing what you do best. What&rsquo;s more, since we are a
        London-based business we will be able to offer you our expert knowledge
        about what sells and what will work best for your business space.
      </p>

      <p>
        If you&rsquo;re interested in wrapping your vehicles with us &ndash;
        using graphics and signage &ndash; please contact one of our signage
        experts at 020 7350 1567 or email{" "}
        <a href="mailto:info@yellowsigns.co.uk">info@yellowsigns.co.uk</a>
      </p>

      <p>
        Alternatively, to request a quick quote visit{" "}
        <Link to="quote-site-visit">
          https://yellowsigns.co.uk/quote-site-visit
        </Link>{" "}
        to get started!
      </p>
    </GalleryText>
  ),
  gallery: [
    {
      img: "/img/signselection/5-Vehicle-Graphics/PARTIAL-VEHICLE-WRAP-+-3MM-ALUMINIIUM-PANELS-WITH-WHITE-CUT-VINYL.jpg",
      title:
        "PARTIAL VEHICLE WRAP + 3MM ALUMINIIUM PANELS WITH WHITE CUT VINYL",
    },
    {
      img: "/img/signselection/5-Vehicle-Graphics/cut-vinyl--full-colour-contour-cut-vinyl-lorry-graphics.jpg",
      title: "Cut Vinyl & Full Colour Contour Cut Vinyl Lorry Graphics",
    },
    {
      img: "/img/signselection/5-Vehicle-Graphics/cut-vinyl--full-colour-contour-cut-vinyl-vehicle-graphics.jpg",
      title: "Cut Vinyl & Full Colour Contour Cut Vinyl Vehicle Graphics",
    },
    {
      img: "/img/signselection/5-Vehicle-Graphics/cut-vinyl--full-colour-vinyl-van-graphics.jpg",
      title: "Cut Vinyl & Full Colour Vinyl Van Graphics",
    },
    {
      img: "/img/signselection/5-Vehicle-Graphics/cut-vinyl-van-graphics.jpg",
      title: "Cut Vinyl Car Graphics",
    },
    {
      img: "/img/signselection/5-Vehicle-Graphics/CutVinylCarGraphics.jpg",
      title: "Cut Vinyl Car Graphics",
    },
    {
      img: "/img/signselection/5-Vehicle-Graphics/CutVinylCarGraphics3.jpg",
      title: "Cut Vinyl Car Graphics",
    },
    {
      img: "/img/signselection/5-Vehicle-Graphics/CutVinylFoodTruckGraphics.jpg",
      title: "Cut Vinyl Food Truck Graphics",
    },
    {
      img: "/img/signselection/5-Vehicle-Graphics/CutVinylFullColourCarGraphics.jpg",
      title: "Cut Vinyl & Full Colour Car Graphics",
    },
    {
      img: "/img/signselection/5-Vehicle-Graphics/CutVinylFullColourCarGraphics2.jpg",
      title: "Cut Vinyl & Full Colour Car Graphics",
    },
    {
      img: "/img/signselection/5-Vehicle-Graphics/CutVinylFullColourCarGraphics3.jpg",
      title: "Cut Vinyl & Full Colour Car Graphics",
    },
    {
      img: "/img/signselection/5-Vehicle-Graphics/CutVinylFullColourLorryGraphics.jpg",
      title: "Cut Vinyl & Full Colour Lorry Graphics",
    },
    {
      img: "/img/signselection/5-Vehicle-Graphics/CutVinylFullColourSUVGraphics.jpg",
      title: "Cut Vinyl & Full Colour SUV Graphics",
    },
    {
      img: "/img/signselection/5-Vehicle-Graphics/CutVinylFullColourVanGraphics.jpg",
      title: "Cut Vinyl & Full Colour Van Graphics",
    },
    {
      img: "/img/signselection/5-Vehicle-Graphics/CutVinylFullColourVanGraphics2.jpg",
      title: "Cut Vinyl & Full Colour Van Graphics",
    },
    {
      img: "/img/signselection/5-Vehicle-Graphics/CutVinylFullColourVanGraphics3.jpg",
      title: "Cut Vinyl & Full Colour Van Graphics",
    },
    {
      img: "/img/signselection/5-Vehicle-Graphics/CutVinylJCBGraphics.jpg",
      title: "Cut Vinyl JCB Graphics",
    },
    {
      img: "/img/signselection/5-Vehicle-Graphics/FullColourVanGraphics.jpg",
      title: "Full Colour Van Graphics",
    },
    {
      img: "/img/signselection/5-Vehicle-Graphics/ParticalFullColourVanWrap.jpg",
      title: "Partical Full Colour Van Wrap",
    },
    {
      img: "/img/signselection/5-Vehicle-Graphics/SmartCarFullColourWrap.jpg",
      title: "Smart Car Full Colour Wrap",
    },
    {
      img: "/img/signselection/5-Vehicle-Graphics/MATT_BLACK_CUT_WRAP_VINYL.jpg",
      title: "MATT BLACK CUT WRAP VINYL",
    },
    {
      img: "/img/signselection/5-Vehicle-Graphics/FULL_COLOUR_PARTIAL_WRAP_AND_CONTOUR_CUT_LETTERS.jpg",
      title: "FULL COLOUR PARTIAL WRAP AND CONTOUR CUT LETTERS",
    },
    {
      img: "/img/signselection/5-Vehicle-Graphics/FULL_COLOUR_&_GREEN_CUT_WRAP_VINYL_VEHICLE_GRAPHICS.jpg",
      title: "FULL COLOUR & GREEN CUT WRAP VINYL VEHICLE GRAPHICS",
    },
    {
      img: "/img/signselection/5-Vehicle-Graphics/VEHICLE_GRAPHICS_AND_CHEVRONS.jpg",
      title: "VEHICLE GRAPHICS AND CHEVRONS",
    },
    {
      img: "/img/signselection/5-Vehicle-Graphics/FULL_COLOUR_PRINTED_VINYL_AND_CUT_WHITE_VINYL_SCOOTER_GRAPHICS.jpg",
      title: "FULL COLOUR PRINTED VINYL AND CUT WHITE VINYL SCOOTER GRAPHICS",
    },
  ],
};

const projectSigns = {
  name: "Projecting Signs",
  href: "/projecting-signs",
  footerContent: (
    <GalleryText>
      <p>
        Sometimes regular overhead signage just doesn&rsquo;t cut it. Perhaps
        your shop is surrounded by crowds of other similar businesses and the
        sign option are too saturated and difficult to view, or maybe
        you&rsquo;re based in a hard-to-notice corner of the high street and are
        struggling to drum up or direct your customer's attention. It can be a
        pain to invest money into your externals for them not to be appreciated.
        We think that&rsquo;s a massive shame and we want to tell you a bit
        about projected signs and how they can attract customers from more
        angles regardless of your positioning on the high street.
      </p>

      <p>
        Firstly, what are projected signs? Projected signs are signboards that
        are produced from a fascia with your shop or business name. They can be
        bespoke and designed to align with pre-existing logos or brand
        identities or even new logos you might be considering; varying in
        colour, size and even material to perfect the exact look and budget
        requirements you have set for your signage. Here at Yellow Signs, we
        produce almost all of our work in-house at our Battersea headquarters so
        we can produce and install signage and graphics such as projecting signs
        all over London.
      </p>

      <p>
        Why do projected signs benefit businesses? Unlike your traditional shop
        or retail sign that sits overhead and faces one direction, Projected
        signs are multifaceted and allow you to attract customers from a greater
        deal of angles and a much further distance offering more consumer
        potential and even working better than your main signage at times.
        What&rsquo;s more, they are highly customisable and work as a 24/7
        marketing tool for your business. We offer a range of options including
        your traditional round and circular as well as more bespoke shapes for
        those who want to try something more interesting with their design.
      </p>

      <p>
        For examples of our past works, you can check out{" "}
        <Link to="case-studies">https://yellowsigns.co.uk/case-studies</Link>
      </p>

      <p>
        Let&rsquo;s check out some of your options! A popular and cost-effective
        option we offer is foam swinging PVC boards, with plenty of design
        choices, these are a great way to enhance your business however we
        understand some brands like to take things a step or two further and one
        of our most popular forms of projected signage does just that; our
        lightboxes and illuminated tray projected signs are striking and
        illuminate your customers about your business which is sure to turn
        heads! With lots of colour options and coatings to choose from, make
        your business the perfect shopper experience from start to finish.
      </p>

      <p>
        If you&rsquo;re interested in building your business with Yellow Signs
        &ndash; using graphics and signage &ndash; please contact one of our
        signage experts at 020 7350 1567 or email{" "}
        <a href="mailto:info@yellowsigns.co.uk">info@yellowsigns.co.uk</a>
      </p>

      <p>
        Alternatively, to request a quick quote visit{" "}
        <Link to="quote-site-visit">
          https://yellowsigns.co.uk/quote-site-visit
        </Link>{" "}
        to get started!
      </p>
    </GalleryText>
  ),
  content:
    "These signs are designed to project or protrude from a fascia. They allow potential customers to see the business from a distance. Available in rectangular, circular or bespoke shapes. Projecting signs can often have more impact than the main sign, so great value.",
  gallery: [
    {
      img: "/img/signselection/4-Projecting-Signs/7MM-PUSHED-THROUGH-ACRYLIC-LETTERS-PROJECTING-SIGN.jpg",
      title: "7MM PUSHED-THROUGH ACRYLIC LETTERS PROJECTING SIGN",
    },
    {
      img: "/img/signselection/4-Projecting-Signs/25MM-THICK-CIRCULAR-FOAM-PVC-+-DOUBLE-SIDED-TROUGH-LIGHT-PROOJECTING-SIGN.jpg",
      title:
        "25MM THICK CIRCULAR FOAM PVC + DOUBLE SIDED TROUGH LIGHT PROOJECTING SIGN",
    },
    {
      img: "/img/signselection/4-Projecting-Signs/25MM-THICK-FOAM-PVC-+-MATT-BLACK-ALUMINIUM-BRACKET.jpg",
      title: "25MM THICK FOAM PVC + MATT BLACK ALUMINIUM BRACKET",
    },
    {
      img: "/img/signselection/4-Projecting-Signs/OPAL-ACRYLIC-BACKED-&-FRET-CUT-ALUMINIUM-PROJECTING-SIGNS-POWDER-COATED-TO-RAL.jpg",
      title:
        "OPAL ACRYLIC BACKED & FRET CUT ALUMINIUM PROJECTING SIGNS POWDER COATED TO RAL",
    },
    {
      img: "/img/signselection/4-Projecting-Signs/OPAL-ACRYLIC-LIGHTBOX-WITH-ALUMINIUM-DOUBLE-ARM-BRACKET.jpg",
      title: "OPAL ACRYLIC LIGHTBOX WITH ALUMINIUM DOUBLE ARM BRACKET",
    },
    {
      img: "/img/signselection/4-Projecting-Signs/AluminiumCopperAcrylicBackedIlluminated.jpg",
      title: "Aluminium & Copper Acrylic Backed Illuminated",
    },
    {
      img: "/img/signselection/4-Projecting-Signs/AluminiumLightbox.jpg",
      title: "Aluminium Lightbox",
    },
    {
      img: "/img/signselection/4-Projecting-Signs/AluminiumLightbox2.jpg",
      title: "Aluminium Lightbox",
    },
    {
      img: "/img/signselection/4-Projecting-Signs/AluminiumLightboxCutMirrorGoldVinyl.jpg",
      title: "Aluminium Lightbox & Cut Mirror Gold Vinyl",
    },
    {
      img: "/img/signselection/4-Projecting-Signs/CircularWoodPushedThroughAcrylicIlluminated.jpg",
      title: "Circular Wood & Pushed-Through Acrylic Illuminated",
    },
    {
      img: "/img/signselection/4-Projecting-Signs/CustomAluminiumCutVinyl.jpg",
      title: "Custom Aluminium Cut Vinyl",
    },
    {
      img: "/img/signselection/4-Projecting-Signs/CustomIlluminatedAluminiumAcrylic.jpg",
      title: "Custom Illuminated Aluminium & Acrylic",
    },
    {
      img: "/img/signselection/4-Projecting-Signs/DoubleSidedTroughLitProjectingSign.jpg",
      title: "Double Sided Trough Lit Projecting Sign",
    },
    {
      img: "/img/signselection/4-Projecting-Signs/FoamPVCCutMirrorGoldVinylSwing.jpg",
      title: "Foam PVC & Cut Mirror Gold Vinyl Swing",
    },
    {
      img: "/img/signselection/4-Projecting-Signs/FoamPVCSwing.jpg",
      title: "Foam PVC Swing",
    },
    {
      img: "/img/signselection/4-Projecting-Signs/FoamPVCSwing2.jpg",
      title: "Foam PVC Swing",
    },
    {
      img: "/img/signselection/4-Projecting-Signs/FoamPVCSwing3.jpg",
      title: "Foam PVC Swing",
    },
    {
      img: "/img/signselection/4-Projecting-Signs/StainlessSteelAcrylicIlluminated.jpg",
      title: "Stainless Steel & Acrylic Illuminated",
    },
    {
      img: "/img/signselection/4-Projecting-Signs/POWDER_COATED_ALUMINUM_TRAY_&_PROJECTING_SIGN_WITH_FRONT_ILLUMINATED_3D_LETTERS.jpg",
      title:
        "POWDER_COATED_ALUMINUM TRAY & PROJECTING SIGN WITH FRONT ILLUMINATED 3D LETTERS",
    },
    {
      img: "/img/signselection/4-Projecting-Signs/FUL_COLOUR_PRINTED_&_WRAPPED_25MM_FOAM_PVC_SWING_PROJECTING_SIGN.jpg",
      title: "FUL COLOUR PRINTED & WRAPPED 25MM FOAM PVC SWING PROJECTING SIGN",
    },
    {
      img: "/img/signselection/4-Projecting-Signs/POWDER_COATED_ALUMINIUM_CIRCULAR_PROJECTING_SIGN_WITH_GOLD_WET_COATED_ACRYLIC_LETTERS.jpg",
      title:
        "POWDER COATED ALUMINIUM CIRCULAR PROJECTING SIGN WITH GOLD WET COATED ACRYLIC LETTERS",
    },
    {
      img: "/img/signselection/4-Projecting-Signs/OPAL_ACRYLIC_LIGHTBOX_PROJECTING_SIGN_WITH_WET_COATED_5MM_ACRYLIC_LOGO.jpg",
      title:
        "OPAL ACRYLIC LIGHTBOX PROJECTING SIGN WITH WET COATED 5MM ACRYLIC LOGO",
    },
    {
      img: "/img/signselection/4-Projecting-Signs/OPAL_ACRYLIC_LIGHTBOX_PROJECTING_SIGN_WITH_BLACK_CUT_VINYL_LOGO.jpg",
      title: "OPAL ACRYLIC LIGHTBOX PROJECTING SIGN WITH BLACK CUT VINYL LOGO",
    },
    {
      img: "/img/signselection/4-Projecting-Signs/POWDER_COATED_ALUMINIUM_LIGHTBOX_PROJECTING_SIGN.jpg",
      title: "POWDER COATED ALUMINIUM LIGHTBOX PROJECTING SIGN",
    },
    {
      img: "/img/signselection/4-Projecting-Signs/POWDER_COATED_ALUMINIUM_PROJECTING_SIGN_WITH_5MM_WET_COATED_LETTERS_AND_DOWN-LIGHTER.jpg",
      title:
        "POWDER COATED ALUMINIUM PROJECTING SIGN WITH 5MM WET COATED LETTERS AND DOWN-LIGHTER",
    },
    {
      img: "/img/signselection/4-Projecting-Signs/POWDER_COATED_PROJECTING_SIGN_WITH_DOUBLE_SIDED_TROUGH_LIGHTS.jpg",
      title: "POWDER COATED PROJECTING SIGN WITH DOUBLE SIDED TROUGH LIGHTS",
    },
  ],
};

const neonSigns = {
  name: "Neon Signs",
  href: "/neon-signs",
  content:
    "Not only are led neon signs more environmentally friendly, but they are also much safer than regular neon signs so you can rest assured you are exposing your consumers to a safe and approved form of signage. For eco-friendly businesses, an led neon sign is a fantastic form of marketing. People feel inclined to post on social media with the trendy style and colour palette. In the age of eco-friendly and cruelty-free life, a form of décor like this is considered quirky and respectable.",
  footerContent: (
    <GalleryText>
      <p>
        If you&rsquo;ve ventured onto Instagram in recent years, you'll have
        seen a wide range of posts shared, liked and commented on. From
        lunchtime pics to nights out, social media has become a haven for people
        to share their daily lives for others to see. But social media
        doesn&rsquo;t just benefit the general public: it is also a fantastic
        tool for businesses to get themselves out there and attract a new group
        of potential customers. Whilst there are plenty of ways to make yourself
        a hashtable business, one of our favourite ways of getting those
        location tags is neon signs.
      </p>

      <p>
        In recent years neon has become a growing trend: With the colours being
        vibrant and exciting, we love posting pics or sharing snaps of
        aesthetically pleasing places and things. You might be asking; how will
        this help me? You can utilise your internal or external signage for your
        business to drive those customers to produce guerrilla advertising for
        your business. For those not clued in with the ins and outs of
        marketing, guerrilla advertisement are non-conventional ways to drive
        brand awareness with the use of design. When consumers seek something
        new, LED neon does the job! So, let&rsquo;s capture a snapshot of your
        business in the best way we know how.
      </p>

      <p>
        So, what is neon signage? Neon signs involve brightly-coloured &ndash;
        think of your favourite highlighter stationary - lighting suspended in
        either bulbs or tubes in a variety of shapes to fit a brand aesthetic.
        It ranges in size, colour, text and shape and can be used in a variety
        of different ways to shine a spotlight on your business. Since safety
        and sustainability are paramount to our business, we provide a
        sustainable and safer alternative to neon using LED neon to ensure we
        are exposing your customers to only the safest form of signage! But
        don&rsquo;t worry, LED neon doesn&rsquo;t compromise your creativity at
        all; in fact, you&rsquo;ll have even more colour range and keep the
        environmentally conscious in mind!
        <br />
        <br />
        To see some of our past work, feel free to visit:{" "}
        <Link to="case-studies">https://yellowsigns.co.uk/case-studies</Link>
      </p>

      <p>
        At Yellow Signs, we produce essentially everything we do in our
        Battersea HQ so we can supply our final products quickly and timely.
        Since we understand the importance of great signage, we want to get your
        signage and graphics, especially the neon variety, to you, anywhere in
        London, to get those Instagram highlights up and running! If
        you&rsquo;re struggling for inspiration or looking for something more
        bespoke, feel free to contact our team who will be happy to help you
        create something you and your customers will love.
      </p>

      <p>
        If you&rsquo;re interested in highlighting your business with us&ndash;
        using graphics and signage &ndash; please contact one of our signage
        experts at 020 7350 1567 or email{" "}
        <a href="mailto:info@yellowsigns.co.uk">info@yellowsigns.co.uk</a>
      </p>

      <p>
        Alternatively, to request a quick quote visit{" "}
        <Link to="quote-site-visit">
          https://yellowsigns.co.uk/quote-site-visit
        </Link>{" "}
        to get started!
      </p>
    </GalleryText>
  ),
  gallery: [
    {
      img: "/img/signselection/4-Projecting-Signs/7MM-PUSHED-THROUGH-ACRYLIC-LETTERS-PROJECTING-SIGN.jpg",
      title: "7MM PUSHED-THROUGH ACRYLIC LETTERS PROJECTING SIGN",
    },
    {
      img: "/img/signselection/4-Projecting-Signs/25MM-THICK-CIRCULAR-FOAM-PVC-+-DOUBLE-SIDED-TROUGH-LIGHT-PROOJECTING-SIGN.jpg",
      title:
        "25MM THICK CIRCULAR FOAM PVC + DOUBLE SIDED TROUGH LIGHT PROOJECTING SIGN",
    },
    {
      img: "/img/signselection/4-Projecting-Signs/25MM-THICK-FOAM-PVC-+-MATT-BLACK-ALUMINIUM-BRACKET.jpg",
      title: "25MM THICK FOAM PVC + MATT BLACK ALUMINIUM BRACKET",
    },
    {
      img: "/img/signselection/4-Projecting-Signs/OPAL-ACRYLIC-BACKED-&-FRET-CUT-ALUMINIUM-PROJECTING-SIGNS-POWDER-COATED-TO-RAL.jpg",
      title:
        "OPAL ACRYLIC BACKED & FRET CUT ALUMINIUM PROJECTING SIGNS POWDER COATED TO RAL",
    },
    {
      img: "/img/signselection/4-Projecting-Signs/OPAL-ACRYLIC-LIGHTBOX-WITH-ALUMINIUM-DOUBLE-ARM-BRACKET.jpg",
      title: "OPAL ACRYLIC LIGHTBOX WITH ALUMINIUM DOUBLE ARM BRACKET",
    },
    {
      img: "/img/signselection/4-Projecting-Signs/AluminiumCopperAcrylicBackedIlluminated.jpg",
      title: "Aluminium & Copper Acrylic Backed Illuminated",
    },
    {
      img: "/img/signselection/4-Projecting-Signs/AluminiumLightbox.jpg",
      title: "Aluminium Lightbox",
    },
    {
      img: "/img/signselection/4-Projecting-Signs/AluminiumLightbox2.jpg",
      title: "Aluminium Lightbox",
    },
    {
      img: "/img/signselection/4-Projecting-Signs/AluminiumLightboxCutMirrorGoldVinyl.jpg",
      title: "Aluminium Lightbox & Cut Mirror Gold Vinyl",
    },
    {
      img: "/img/signselection/4-Projecting-Signs/CircularWoodPushedThroughAcrylicIlluminated.jpg",
      title: "Circular Wood & Pushed-Through Acrylic Illuminated",
    },
    {
      img: "/img/signselection/4-Projecting-Signs/CustomAluminiumCutVinyl.jpg",
      title: "Custom Aluminium Cut Vinyl",
    },
    {
      img: "/img/signselection/4-Projecting-Signs/CustomIlluminatedAluminiumAcrylic.jpg",
      title: "Custom Illuminated Aluminium & Acrylic",
    },
    {
      img: "/img/signselection/4-Projecting-Signs/DoubleSidedTroughLitProjectingSign.jpg",
      title: "Double Sided Trough Lit Projecting Sign",
    },
    {
      img: "/img/signselection/4-Projecting-Signs/FoamPVCCutMirrorGoldVinylSwing.jpg",
      title: "Foam PVC & Cut Mirror Gold Vinyl Swing",
    },
    {
      img: "/img/signselection/4-Projecting-Signs/FoamPVCSwing.jpg",
      title: "Foam PVC Swing",
    },
    {
      img: "/img/signselection/4-Projecting-Signs/FoamPVCSwing2.jpg",
      title: "Foam PVC Swing",
    },
    {
      img: "/img/signselection/4-Projecting-Signs/FoamPVCSwing3.jpg",
      title: "Foam PVC Swing",
    },
    {
      img: "/img/signselection/4-Projecting-Signs/StainlessSteelAcrylicIlluminated.jpg",
      title: "Stainless Steel & Acrylic Illuminated",
    },
    {
      img: "/img/signselection/4-Projecting-Signs/POWDER_COATED_ALUMINUM_TRAY_&_PROJECTING_SIGN_WITH_FRONT_ILLUMINATED_3D_LETTERS.jpg",
      title:
        "POWDER COATED ALUMINUM TRAY & PROJECTING SIGN WITH FRONT ILLUMINATED 3D LETTERS",
    },
    {
      img: "/img/signselection/4-Projecting-Signs/FUL_COLOUR_PRINTED_&_WRAPPED_25MM_FOAM_PVC_SWING_PROJECTING_SIGN.jpg",
      title: "FUL COLOUR PRINTED & WRAPPED 25MM FOAM PVC SWING PROJECTING SIGN",
    },
    {
      img: "/img/signselection/4-Projecting-Signs/POWDER_COATED_ALUMINIUM_CIRCULAR_PROJECTING_SIGN_WITH_GOLD_WET_COATED_ACRYLIC_LETTERS.jpg",
      title:
        "POWDER COATED ALUMINIUM CIRCULAR PROJECTING SIGN WITH GOLD WET COATED ACRYLIC LETTERS",
    },
    {
      img: "/img/signselection/4-Projecting-Signs/OPAL_ACRYLIC_LIGHTBOX_PROJECTING_SIGN_WITH_WET_COATED_5MM_ACRYLIC_LOGO.jpg",
      title:
        "OPAL ACRYLIC LIGHTBOX PROJECTING SIGN WITH WET COATED 5MM ACRYLIC LOGO",
    },
    {
      img: "/img/signselection/4-Projecting-Signs/OPAL_ACRYLIC_LIGHTBOX_PROJECTING_SIGN_WITH_BLACK_CUT_VINYL_LOGO.jpg",
      title: "OPAL ACRYLIC LIGHTBOX PROJECTING SIGN WITH BLACK CUT VINYL LOGO",
    },
    {
      img: "/img/signselection/4-Projecting-Signs/POWDER_COATED_ALUMINIUM_LIGHTBOX_PROJECTING_SIGN.jpg",
      title: "POWDER COATED ALUMINIUM LIGHTBOX PROJECTING SIGN",
    },
    {
      img: "/img/signselection/4-Projecting-Signs/POWDER_COATED_ALUMINIUM_PROJECTING_SIGN_WITH_5MM_WET_COATED_LETTERS_AND_DOWN-LIGHTER.jpg",
      title:
        "POWDER COATED ALUMINIUM PROJECTING SIGN WITH 5MM WET COATED LETTERS AND DOWN-LIGHTER",
    },
    {
      img: "/img/signselection/4-Projecting-Signs/POWDER_COATED_PROJECTING_SIGN_WITH_DOUBLE_SIDED_TROUGH_LIGHTS.jpg",
      title: "POWDER COATED PROJECTING SIGN WITH DOUBLE SIDED TROUGH LIGHTS",
    },
  ],
};

const hoarding = {
  name: "Hoarding",
  href: "/hoarding",
  content:
    "Hoarding is the perfect advertising opportunity for any building site or construction project. It usually consists of 1220mm x 2440mm aluminium composite boards with either full colour print or cut vinyl applied to the face.",
  footerContent: (
    <GalleryText>
      <p>
        Hoarding finishes are available in matt, satin or gloss with or without
        lamination - we also offer anti-graffiti lamination for extra
        protection.
      </p>

      <p>
        Hoarding can be simple full sheets or it can be more bespoke. Smaller
        projects usually include more intricate shapes as well as cut-out for
        letterboxes, locks and doors – something that our highly skilled
        production and installation team are all too familiar with.
      </p>

      <p>
        High-end residential refurbishments and construction projects are a
        perfect way to protect the site and advertise the various parties
        involved in the project, such as the contractor, sub-contractors,
        architects, developers and designers. It’s quite common for all parties
        to share the cost of the hoarding, making it an extremely good value
        form of advertising and brand reinforcement.
      </p>

      <p>
        For examples of our past works, you can check out{" "}
        <Link to="case-studies">https://yellowsigns.co.uk/case-studies</Link>
      </p>

      <p>
        If you’re interested in hoarding for your business please contact one of
        our signage experts at 020 7350 1567 or email{" "}
        <a href="mailto:info@yellowsigns.co.uk">info@yellowsigns.co.uk</a>
      </p>
      <p>
        Alternatively, to request a quick quote visit{" "}
        <a href="https://yellowsigns.co.uk/quote-site-visit">
          yellowsigns.co.uk/quote-site-visit
        </a>{" "}
        to get started!
      </p>
    </GalleryText>
  ),
  gallery: [
    {
      img: "/img/signselection/Hoarding/FULL_COLOUR__CUT_VINYL_ALUMINIUM_COMPOSITE_HOARDING.jpg",
      title: "FULL_COLOUR_&_CUT_VINYL_ALUMINIUM_COMPOSITE_HOARDING",
    },
    {
      img: "/img/signselection/Hoarding/FULL_COLOUR_ALUMINIUM_COMPOSITE_ANTI-GRAFFITI_HOARDING.jpg",
      title: "FULL_COLOUR_ALUMINIUM_COMPOSITE_ANTI-GRAFFITI_HOARDING",
    },
    {
      img: "/img/signselection/Hoarding/FULL_COLOUR_ALUMINIUM_COMPOSITE_DIRECTIONAL_PANELS.jpg",
      title: "FULL_COLOUR_ALUMINIUM_COMPOSITE_DIRECTIONAL_PANELS",
    },
    {
      img: "/img/signselection/Hoarding/FULL_COLOUR_ALUMINIUM_COMPOSITE_HOARDING_WITH_MATCHING_KICK-STANDS.jpg",
      title:
        "FULL_COLOUR_ALUMINIUM_COMPOSITE_HOARDING_WITH_MATCHING_KICK-STANDS",
    },
    {
      img: "/img/signselection/Hoarding/FULL_COLOUR_ALUMINIUM_COMPOSITE_HOARDING.jpg",
      title: "FULL_COLOUR_ALUMINIUM_COMPOSITE_INTERNAL_HOARDING",
    },
    {
      img: "/img/signselection/Hoarding/FULL_COLOUR_ALUMINIUM_COMPOSITE_INTERNAL_HOARDING.jpg",
      title: "FULL_COLOUR_ALUMINIUM_COMPOSITE_INTERNAL_HOARDING",
    },
  ],
};

const cut = {
  name: "Cut Vinyl Graphics",
  href: "/cut-vinyl-graphics",
  content:
    "Cut vinyl is ideal for solid colour designs and suitable for smooth surfaces such as glass, metal, gloss painted wood, windows and vehicles. It is a great value solution and gives a crisp and punchy colour which can be cut to almost any size or shape.",
  footerContent: (
    <GalleryText>
      <p>
        Let&rsquo;s face it, signage and graphics can get pricey! With
        production, installation and a cacophony of shapes, colours, sizes and
        materials to choose from, translating your brand image can become a
        pretty difficult task when you have budget restrictions; luckily, we are
        here to help. At Yellow Signs, we don&rsquo;t want to sell you signs, we
        want to sell you a vision of exactly what they can do for your business;
        we want to offer you the creative freedom to represent your business in
        the way you wish without breaking the bank.
      </p>

      <p>
        So, what can we offer you? If you&rsquo;re looking for something
        long-lasting and highly customisable, look no further than cut vinyl
        graphics. They&rsquo;re the bread and butter of graphic signage and can
        completely transform your business space both internally, externally and
        everything in between. From colourful and eclectic window decals - to
        entice new customers and give your space life - to wall graphics to
        represent your brand identity in pictures or text. Since cut vinyl is
        easy to diversify, we can produce every shape under the sun in a variety
        of colours to highlight your business in the best way we know how.
      </p>

      <p>
        Cut vinyl graphics work by taking adhesive-coloured vinyl and
        customising them to your every whim. This means they're long-lasting yet
        extremely easy to remove. There are plenty of options for vinyl graphics
        including window decals - to draw customers from the outside in - or
        brand-new wall art to temporarily spice up a rental space or permanent
        space once you get them in the door; cut-vinyl graphics are water
        resistant and can be easily cleaned and maintained, making them one of
        the most cost-efficient and good value form of signage for any business.
      </p>

      <p>
        What can we do? We know that London can be heinous for businesses and
        making your business stand out is vital in bolstering your sales and
        experiences, so we want to ensure your product is produced and to your
        location as quickly as possible. Our highly skilled team produce almost
        all of our products, especially cut-vinyl graphics, in our Battersea HQ
        meaning we can skip those pesky waiting times and get your signage and
        graphics, including cut-vinyl, to you, anywhere in London, at the drop
        of a pin! If you need something more bespoke or are struggling for
        inspiration, feel free to contact a member of our team to help get you
        started.
      </p>

      <p>
        To see some of our past work, feel free to visit our case studies page
        at:{" "}
        <Link to="case-studies">https://yellowsigns.co.uk/case-studies</Link> to
        give you some inspo.
      </p>

      <p>
        If you&rsquo;re interested in decking out your event with us &ndash;
        using graphics and signage &ndash; please contact one of our signage
        experts at 020 7350 1567 or email{" "}
        <a href="mailto:info@yellowsigns.co.uk">info@yellowsigns.co.uk</a>
      </p>
    </GalleryText>
  ),
  gallery: [
    {
      img: "/img/signselection/7-Cut-Vinyl-Graphics/2-COLOUR-CUT-VINYL.jpg",
      title: "2 COLOUR CUT VINYL",
    },
    {
      img: "/img/signselection/7-Cut-Vinyl-Graphics/FULL-COLOUR-PRINTED-&-CONTOUR-CUT-VINYL.jpg",
      title: "FULL COLOUR PRINTED & CONTOUR CUT VINYL",
    },
    {
      img: "/img/signselection/7-Cut-Vinyl-Graphics/HIGH-TACK-MATT-BLACK-CUT-VINYL.jpg",
      title: "HIGH-TACK MATT BLACK CUT VINYL",
    },
    {
      img: "/img/signselection/7-Cut-Vinyl-Graphics/MATT-METALLIC-GOLD-CUT-VINYL.jpg",
      title: "MATT METALLIC GOLD CUT VINYL",
    },
    {
      img: "/img/signselection/7-Cut-Vinyl-Graphics/cut-vinyl-letters--suspended-clear-acrylic-roundel.jpg",
      title: "Cut Vinyl Letters & Suspended Clear Acrylic Roundel",
    },
    {
      img: "/img/signselection/7-Cut-Vinyl-Graphics/cut-vinyl-window-graphics.jpg",
      title: "Cut Vinyl Window Graphics",
    },
    {
      img: "/img/signselection/7-Cut-Vinyl-Graphics/CutVinylFrostedPrivacyWindowVinyl.jpg",
      title: "Cut Vinyl & Frosted Privacy Window Vinyl",
    },
    {
      img: "/img/signselection/7-Cut-Vinyl-Graphics/CutVinylFullColourWindowGraphics.jpg",
      title: "Cut Vinyl & Full Colour Window Graphics",
    },
    {
      img: "/img/signselection/7-Cut-Vinyl-Graphics/CutVinylGraphics.jpg",
      title: "Cut Vinyl Graphics",
    },
    {
      img: "/img/signselection/7-Cut-Vinyl-Graphics/CutVinylGraphics2.jpg",
      title: "Cut Vinyl Graphics",
    },
    {
      img: "/img/signselection/7-Cut-Vinyl-Graphics/CutVinylWindowGraphics2.jpg",
      title: "Cut Vinyl Window Graphics",
    },
    {
      img: "/img/signselection/7-Cut-Vinyl-Graphics/WHITE_CUT_VINYL.jpg",
      title: "WHITE CUT VINYL",
    },
    {
      img: "/img/signselection/7-Cut-Vinyl-Graphics/HIGH-TACK_WHITE_CUT_VINYL.jpg",
      title: "HIGH-TACK WHITE CUT VINYL",
    },
    {
      img: "/img/signselection/7-Cut-Vinyl-Graphics/WHITE_CUT_VINYL_OPENING_TIMES.jpg",
      title: "WHITE CUT VINYL OPENING TIMES",
    },
    {
      img: "/img/signselection/7-Cut-Vinyl-Graphics/TEAL_CUT_VINYL_WINDOW_GRAPHICS.jpg",
      title: "TEAL CUT VINYL WINDOW GRAPHICS",
    },
    {
      img: "/img/signselection/7-Cut-Vinyl-Graphics/WET_COATED_ACRYLIC_LETTERS,_FROSTING_AND_WINDOW_GRAPHICS.jpg",
      title: "WET COATED ACRYLIC LETTERS, FROSTING AND WINDOW GRAPHICS",
    },
    {
      img: "/img/signselection/7-Cut-Vinyl-Graphics/BLACK_TINT_APPLIED_TO_INSIDE_OF_GLASS__CUT_WHITE_VINYL_APPLIED_TO_OUTSIDE_OF_GLASS.jpg",
      title:
        "BLACK TINT APPLIED TO INSIDE OF GLASS + CUT WHITE VINYL APPLIED TO OUTSIDE OF GLASS",
    },
    {
      img: "/img/signselection/7-Cut-Vinyl-Graphics/FULL_COLOUR_PRINTED_HIGH-TACK_VINYL.jpg",
      title: "FULL COLOUR PRINTED HIGH-TACK VINYL",
    },
    {
      img: "/img/signselection/7-Cut-Vinyl-Graphics/BLACK_CUT_VINYL.jpg",
      title: "BLACK CUT VINYL",
    },
    {
      img: "/img/signselection/7-Cut-Vinyl-Graphics/ANTI-GRAFFITI_HOARDING.jpg",
      title: "ANTI-GRAFFITI HOARDING",
    },
    {
      img: "/img/signselection/7-Cut-Vinyl-Graphics/FULL_COLOUR_PRNITED_VINYL__WHITE_CUT_VINYL.jpg",
      title: "FULL COLOUR PRINTED VINYL + WHITE CUT VINYL",
    },
    {
      img: "/img/signselection/7-Cut-Vinyl-Graphics/RED_CUT_VINYL.jpg",
      title: "RED CUT VINYL",
    },
  ],
};

const fullColour = {
  name: "Full Colour Graphics",
  href: "/full-colour-graphics",
  content:
    "Full colour graphics are ideal for retail spaces or exhibitions and can be printed in almost any size using solvent and spot UV ink plus optional lamination for extra durability. There is a larger range of colours available compared to cut vinyl.",
  footerContent: (
    <GalleryText>
      <p>
        Aesthetics are essentially the bread and butter of the visual marketing
        industry. It takes ordinary people and captures their attention through
        colour, design and concepts. As business leaders and workers, possibly
        considering dipping into your visual advertisement game, you should
        carefully consider how colour plays a role in your agenda. We use colour
        online on our websites and logos, but how can we flip this into daily
        life: and better yet how can you enhance your graphics game in one easy
        step? Three words: full colour graphics.
      </p>

      <p>
        What are they and how can you use these to influence your consumer
        behaviour? Full colour graphics really are as simple as it sounds;
        it&rsquo;s a mix of the four main printing colours to produce a vibrant,
        eye catching and high-quality finish that can be applied to a variety of
        different forms of signage and graphics. Maybe you&rsquo;re looking to
        create an indoor-outdoor experience and think some new window graphics
        might spice up your exterior, or perhaps you&rsquo;ve just bought some
        overhead counter menus for your brand-new caf&eacute; and want to make
        your customers to judge the book by its cover. Whatever your reason is,
        full colour graphics are a great way to produce a quality and
        professional results you can&rsquo;t receive from your average printing
        or application service.
      </p>

      <p>
        One of our most popular materials we use for full colour graphics is
        vinyl as it has plenty of colour diversity &ndash; more colour variety
        than cut vinyl - and can be used in a variety of different ways to
        highlight your business. A great option to personalise a brands products
        or vehicles is vinyl wrapping which is a great way to help conventional
        of basic marketing products to align with your companies brand identity.
        Not only this, but full colour graphics on vinyl is incredible cost
        effective unlike some advertisement options which is a great alternative
        to some of the more price intensive options.
      </p>

      <p>
        To see some of our past work, feel free to check out:{" "}
        <Link to="case-studies">https://yellowsigns.co.uk/case-studies</Link>
      </p>

      <p>
        So how can we help you? Our team here at Yellow Signs produce almost all
        of everything we do, including full colour graphics, in our Battersea
        headquarters. As a London based business, we know that prices can be
        steep so we want to assure you that we offer competitive prices that
        don&rsquo;t compromise on the integrity of your designs. Since we are so
        local, it also means we can provide your signage and graphics, like full
        colour graphics, to you in London!
      </p>

      <p>
        If you&rsquo;re interested in decking out your event with us &ndash;
        using graphics and signage &ndash; please contact one of our signage
        experts on 020 7350 1567 or email{" "}
        <a href="mailto:info@yellowsigns.co.uk">info@yellowsigns.co.uk</a>
      </p>

      <p>
        Alternatively, to request a quick quote visit{" "}
        <Link to="quote-site-visit">
          https://yellowsigns.co.uk/quote-site-visit
        </Link>{" "}
        to get started!
      </p>
    </GalleryText>
  ),
  gallery: [
    {
      img: "/img/signselection/8-Full-Colour-Graphics/FULL-COLOUR-PRINTED-TEMPORARY-PROMOTIONAL-VINYL.jpg",
      title: "FULL COLOUR PRINTED TEMPORARY PROMOTIONAL VINYL",
    },
    {
      img: "/img/signselection/8-Full-Colour-Graphics/FULL-COLOUR-PRINTED-TEMPORARY-VINYL.jpg",
      title: "FULL COLOUR PRINTED TEMPORARY VINYL",
    },
    {
      img: "/img/signselection/8-Full-Colour-Graphics/FULL-COLOUR-PRINTED-VINYL-MOUNTED-ON-ALUMINIUM-COMPOSITE-PANELS.jpg",
      title: "FULL COLOUR PRINTED VINYL MOUNTED ON ALUMINIUM COMPOSITE PANELS",
    },
    {
      img: "/img/signselection/8-Full-Colour-Graphics/FULL-COLOUR-PRINTED-VINYL.jpg",
      title: "FULL COLOUR PRINTED VINYL",
    },
    {
      img: "/img/signselection/8-Full-Colour-Graphics/5mm-acrylic-letters--full-colour-window-graphics.jpg",
      title: "5mm Acrylic Letters & Full Colour Window Graphics",
    },
    {
      img: "/img/signselection/8-Full-Colour-Graphics/contour-cut-full-colour-digitall-printed-window-graphics.jpg",
      title: "Contour Cut Full Colour Digital Printed Window Graphics",
    },
    {
      img: "/img/signselection/8-Full-Colour-Graphics/fridge-vinyl-wrap.jpg",
      title: "Fridge Vinyl Wrap",
    },
    {
      img: "/img/signselection/8-Full-Colour-Graphics/full-colour-digitally-printed-lightbox-menus.jpg",
      title: "Full Colour Digitally Printed Lightbox Menus",
    },
    {
      img: "/img/signselection/8-Full-Colour-Graphics/full-colour-digitally-printed-vinyl.jpg",
      title: "Full Colour Digitally Printed Vinyl",
    },
    {
      img: "/img/signselection/8-Full-Colour-Graphics/full-colour-digitally-printed-window-graphics-1.jpg",
      title: "Full Colour Digitally Printed Window Graphics",
    },
    {
      img: "/img/signselection/8-Full-Colour-Graphics/full-colour-digitally-printed-window-graphics-2.jpg",
      title: "Full Colour Digitally Printed Window Graphics",
    },
    {
      img: "/img/signselection/8-Full-Colour-Graphics/full-colour-digitally-printed-window-graphics.jpg",
      title: "Full Colour Digitally Printed Window Graphics",
    },
    {
      img: "/img/signselection/8-Full-Colour-Graphics/FullColourAluminiumComposite.jpg",
      title: "Full Colour Aluminium Composite",
    },
    {
      img: "/img/signselection/8-Full-Colour-Graphics/FullColourGraphicsFrostedPrivacyVinyl.jpg",
      title: "Full Colour Graphics & Frosted Privacy Vinyl",
    },
    {
      img: "/img/signselection/8-Full-Colour-Graphics/FullColourWindowGraphics.jpg",
      title: "Full Colour Window Graphics",
    },
    {
      img: "/img/signselection/8-Full-Colour-Graphics/FullColourWindowGraphics2.jpg",
      title: "Full Colour Window Graphics",
    },
    {
      img: "/img/signselection/8-Full-Colour-Graphics/FullColourWindowGraphics3.jpg",
      title: "Full Colour Window Graphics",
    },
    {
      img: "/img/signselection/8-Full-Colour-Graphics/FullColourWindowGraphics4.jpg",
      title: "Full Colour Window Graphics",
    },
    {
      img: "/img/signselection/8-Full-Colour-Graphics/FullColourWindowGraphics5.jpg",
      title: "Full Colour Window Graphics",
    },
    {
      img: "/img/signselection/8-Full-Colour-Graphics/FullColourWindowGraphics7.jpg",
      title: "Full Colour Window Graphics",
    },
    {
      img: "/img/signselection/8-Full-Colour-Graphics/WrappedFullColourVinyl.jpg",
      title: "Wrapped Full Colour Vinyl",
    },
    {
      img: "/img/signselection/8-Full-Colour-Graphics/FULL_COLOUR_WINDOW_GRAPHICS.jpg",
      title: "FULL COLOUR WINDOW GRAPHICS",
    },
  ],
};

const flatCutLetters = {
  name: "Flat Cut Letters",
  href: "/flat-letters",
  content:
    "Flat letters can also be made by routering to shape from a variety of wood or metal and are ideal for shop and retail signs, attached directly or with stand-off locators. Flat letters are great value and offer a straightforward solution which can work with most brands.",
  footerContent: (
    <GalleryText>
      <p>
        There&rsquo;s nothing worse than forking out a huge chunk of your
        marketing budget only for it to be ineffective and unnoticed. It&rsquo;s
        difficult to find a cost-effective yet highly effective and creative
        form of in-person signage without it yanking at your purse strings.
        Whether it&rsquo;s a compromise on creative choices or scaling, we
        believe that every business deserves to put itself in the spotlight,
        either online or in person. That&rsquo;s where we come in. Flat letter
        signage is a fantastic way to elevate your business space without
        worrying about missing out on your customisable options or brand
        alignment.
      </p>

      <p>
        Flat letters are essentially wall or overhead signage that can be used
        internally or externally to heighten your business appeal and direct
        consumer attention to your business in a sea of lots of shops on the
        high street. Much like built-up letters, Flat letters give off a 3D
        effect without a back-breaking budget and be customised in a variety of
        shapes from shape, size, colour and stylistic choice. Unlike your
        smaller scale, flat letters are an attractive and aesthetic form of
        visual marketing that can point and inform people in the direction of
        your business.
      </p>

      <p>
        So, let&rsquo;s talk about some of your many options! We&rsquo;ve
        produced a variety of different flat letter products for other
        businesses and are highly experienced in producing the perfect product
        for a specific business. From internal flat letters in a wood detail to
        aluminium letters for an overhead external option. We can offer you
        straightforward and interesting products that can suit your design
        choices. We produce our flat-letter signage in a variety of materials
        with one of our most popular solutions being acrylic cut lettering,
        which many businesses find much success using. But maybe you want to go
        the extra mile and make something extra striking; stand-off locators are
        a type of wall fixing that can mount your product and create a less
        flush, and more 3D option of signage to pop out and be visually
        accessible for consumers.
      </p>

      <p>
        For more information on our past work, feel free to visit:{" "}
        <Link to="case-studies">https://yellowsigns.co.uk/case-studies</Link>
      </p>

      <p>
        As a London-based business, we know just how taxing London prices can be
        which is why we want to offer you competitive and realistic pricing
        options so you can make the most of your budget. We produce almost all
        of our products in-house in our Battersea workshop so we can supply your
        signage and graphics, especially flat letter signage, anywhere in
        London, in a timely manner, so you can make the most of your final
        product. If you are struggling with ideas or just fancy something a bit
        more bespoke, feel free to contact a member of our team and start an
        enquiry. We are here to help!
      </p>

      <p>
        If you&rsquo;re interested in utilising signage with us &ndash; using
        graphics and signage &ndash; please contact one of our signage experts
        at 020 7350 1567 or email{" "}
        <a href="mailto:info@yellowsigns.co.uk">info@yellowsigns.co.uk</a>
      </p>

      <p>
        Alternatively, to request a quick quote visit{" "}
        <Link to="quote-site-visit">
          https://yellowsigns.co.uk/quote-site-visit
        </Link>{" "}
        to get started!
      </p>
    </GalleryText>
  ),
  gallery: [
    {
      img: "/img/signselection/3-Flat-Letters/5MM-ACRYLIC-LETTERS-WET-COATED-TO-MATT-GOLD-APPLIED-DIRECTLY-TO-TILES.jpg",
      title:
        "5MM ACRYLIC LETTERS WET COATED TO MATT GOLD APPLIED DIRECTLY TO TILES",
    },
    {
      img: "/img/signselection/3-Flat-Letters/5MM-ACRYLIC-LETTERS-WET-COATED-TO-RAL.jpg",
      title: "5MM ACRYLIC LETTERS WET COATED TO RAL",
    },
    {
      img: "/img/signselection/3-Flat-Letters/6MM-AGED-BRASS-LETTERS-ON-THREADED-RODS-WITH-SPACERS.jpg",
      title: "6MM AGED BRASS LETTERS ON THREADED RODS WITH SPACERS",
    },
    {
      img: "/img/signselection/3-Flat-Letters/6MM-AGED-BRASS-LETTERS.jpg",
      title: "6MM AGED BRASS LETTERS",
    },
    {
      img: "/img/signselection/3-Flat-Letters/10MM-MATT-WHITE-ACRYLIC-LETTERS-WITH-FULL-COLOUR-PRINT-TO-FACE.jpg",
      title: "10MM MATT WHITE ACRYLIC LETTERS WITH FULL COLOUR PRINT TO FACE",
    },
    {
      img: "/img/signselection/3-Flat-Letters/19MM-EUROPEAN-OAK-LETTERS-APPLIED-DIRECTLY-TO-FASCIA.jpg",
      title: "19MM EUROPEAN OAK LETTERS APPLIED DIRECTLY TO FASCIA",
    },
    {
      img: "/img/signselection/3-Flat-Letters/19MM-EUROPEAN-OAK-LETTERS.jpg",
      title: "19MM EUROPEAN OAK LETTERS",
    },
    {
      img: "/img/signselection/3-Flat-Letters/20MM-ACRYLIC-LETTERS-WET-COATED-TO-RAL.jpg",
      title: "20MM ACRYLIC LETTERS WET COATED TO RAL",
    },
    {
      img: "/img/signselection/3-Flat-Letters/20MM,-10MM-&-5MM-ACRYLIC-LETTERS-WET-COATED-TO-MATT-GOLD.jpg",
      title: "20MM, 10MM & 5MM ACRYLIC LETTERS WET COATED TO MATT GOLD",
    },
    {
      img: "/img/signselection/3-Flat-Letters/wet-coated-matt-orange-10mm-acrylic-letters.jpg",
      title: "Cut Solid Wood",
    },
    {
      img: "/img/signselection/3-Flat-Letters/white-5mm-acrylic-letters.jpg",
      title: "White 5mm Acrylic Letters",
    },
    {
      img: "/img/signselection/3-Flat-Letters/wet-coated-matt-black-5mm-letters-on-stand-off-locators.jpg",
      title: "Wet Coated Matt Black 5mm Letters on Stand-Off Locators",
    },
    {
      img: "/img/signselection/3-Flat-Letters/wet-coated-acrylic-letters-on-stand-off-locators.jpg",
      title: "Wet Coated Acrylic Letters on Stand-Off Locators",
    },
    {
      img: "/img/signselection/3-Flat-Letters/5mm-black-acrylic-letters--clear-acrylic-plaque.jpg",
      title: "5mm Black Acrylic Letters & Clear Acrylic Plaque",
    },
    {
      img: "/img/signselection/3-Flat-Letters/10mm-wet-coated-matt-gray-acrylic-letters.jpg",
      title: "10mm Wet Coated Matt Gray Acrylic Letters",
    },
    {
      img: "/img/signselection/3-Flat-Letters/wet-coated-matt-black-5mm-acrylic-letters.jpg",
      title: "Wet Coated Matt Black 5mm Acrylic Letters",
    },
    {
      img: "/img/signselection/3-Flat-Letters/wet-coated-5mm-acrylic-letters-on-stand-off-locators.jpg",
      title: "Wet Coated 5mm Acrylic Letters on Stand-Off Locators",
    },
    {
      img: "/img/signselection/3-Flat-Letters/CutSolidWood.jpg",
      title: "Cut Solid Wood",
    },
    {
      img: "/img/signselection/3-Flat-Letters/StainlessSteelAcrylicLetters.jpg",
      title: "Stainless Steel & Acrylic Letters",
    },
    {
      img: "/img/signselection/3-Flat-Letters/StainlessSteelFacedAluminiumComposite.jpg",
      title: "Stainless Steel Faced Aluminium Composite",
    },
    {
      img: "/img/signselection/3-Flat-Letters/StainlessSteelFacedAluminiumCompositeLights.jpg",
      title: "Stainless Steel Faced Aluminium Composite & Lights",
    },
    {
      img: "/img/signselection/3-Flat-Letters/ALUMINIUM_COMPOSITE_SIGN_TRAY_WITH_WET_COATED_ACRYLIC_LETTERS.jpg",
      title: "ALUMINIUM_COMPOSITE_SIGN_TRAY_WITH_WET_COATED_ACRYLIC_LETTERS",
    },
    {
      img: "/img/signselection/3-Flat-Letters/GOLD_WET_COATED_5MM_ACRYLIC_LETTERS_APPPLIED_DIRECTLY_TO_FASCIA.jpg",
      title: "GOLD_WET_COATED_5MM_ACRYLIC_LETTERS_APPPLIED_DIRECTLY_TO_FASCIA",
    },
    {
      img: "/img/signselection/3-Flat-Letters/WET_COATED_5MM_ACRYLIC_LETTERS_APPLIED_TO_YELLOW_ALUMINIUM_COMPOSITE_PANEL.jpg",
      title:
        "WET_COATED_5MM_ACRYLIC_LETTERS_APPLIED_TO_YELLOW_ALUMINIUM_COMPOSITE_PANEL",
    },
    {
      img: "/img/signselection/3-Flat-Letters/WEAT_COATED_AND_FULL_COLOUR_PRINTED__CUT_ACRYLIC.jpg",
      title: "WET_COATED_AND_FULL_COLOUR_PRINTED__CUT_ACRYLIC",
    },
    {
      img: "/img/signselection/3-Flat-Letters/WHITE_ALUMINIUM_COMPOSITE_SIGN_TRAY_WITH_10MM_MATT_BLACK_ACRYLIC_LETTERS_APPLIED_DIRECTLY_TO_FACE.jpg",
      title:
        "WHITE_ALUMINIUM_COMPOSITE_SIGN_TRAY_WITH_10MM_MATT_BLACK_ACRYLIC_LETTERS_APPLIED_DIRECTLY_TO_FACE",
    },
    {
      img: "/img/signselection/3-Flat-Letters/WET_COATED_10MM_ACRYLIC_LETTERS_ON_STAND-OFF_LOCATORS.jpg",
      title: "WET_COATED_10MM_ACRYLIC_LETTERS_ON_STAND-OFF_LOCATORS",
    },
    {
      img: "/img/signselection/3-Flat-Letters/WET_COATED_10MM_ACRYLIC_LETTERS_WITH_FULL_COLOUR_PRINTED_VINYL_TO_FACE.jpg",
      title:
        "WET_COATED_10MM_ACRYLIC_LETTERS_WITH_FULL_COLOUR_PRINTED_VINYL_TO_FACE",
    },
    {
      img: "/img/signselection/3-Flat-Letters/GOLD_WET_COATED_20MM_ACRYLIC_LETTERS_ON_STAND-OFF_LOCATORS__ALUMINIUM_PROJECTING_SIGN.jpg",
      title:
        "GOLD_WET_COATED_20MM_ACRYLIC_LETTERS_ON_STAND-OFF_LOCATORS_+_ALUMINIUM_PROJECTING_SIGN",
    },
    {
      img: "/img/signselection/3-Flat-Letters/WET_COATED_10MM_ACRYLIC_LETTERS_WITH_25MM_BLACK_FOAM_PROJECTING_SIGN.jpg",
      title:
        "WET_COATED_10MM_ACRYLIC_LETTERS_WITH_25MM_BLACK_FOAM_PROJECTING_SIGN",
    },
    {
      img: "/img/signselection/3-Flat-Letters/StandOffLocatorLetter.jpg",
      title: "Stand-Off Locator Letter",
    },
    {
      img: "/img/signselection/3-Flat-Letters/StandOffLocatorLetters.jpg",
      title: "Stand-Off Locator Letters",
    },
    {
      img: "/img/signselection/3-Flat-Letters/StandOffLocatorLettersReverse.jpg",
      title: "Stand-Off Locator Letters Reverse",
    },
  ],
};

const digitalWallpaper = {
  name: "Digital Wallpaper",
  href: "/digital-wallpaper",
  content:
    "Full colour printed wallpaper can bring any wall to life with any design you supply. Suitable for flat surfaces such as sealed painted walls and glass. Printed wallpaper can transform spaces in commercial and domestic environments and can replace the need to paint.",
  footerContent: (
    <GalleryText>
      <p>
        Businesses are more than just the four walls of a building. It&rsquo;s
        about the brand ethos, the people that work in the company and most
        importantly, the customers. However, there is no harm in making those
        four walls align with your business identity with personalised graphics
        to heighten your business visibility. How can you achieve this?
        Let&rsquo;s talk about digital wallpaper. <br />
        <br />
        Firstly, what is digital wallpaper? Well, it&rsquo;s exactly what it
        says on the tin. Digital wallpaper is a wallpaper that can be adorned
        with custom designs, prints colours and imagery. Unlike your usual
        shop-bought products, digital wallpaper is made to measure and is
        printed in short runs, meaning you don&rsquo;t get that unfortunate
        extra stock lying around, collecting dust. It allows you more scope for
        creativity and can align with your business brand image, logos or colour
        palette. <br />
        <br />
        If we handed you a canvas and asked you to draw your business values,
        what would it say? Digital wallpaper is your blank canvas and acts as
        personalised artwork for your business. Are you trying to get your
        company noticed and want to advance your social media presence? There's
        nothing a gen Z or millennial loves more than an insta-worthy snap. So,
        make your business a backdrop worthy of being tagged and draw in a new
        customer base. But hey, sometimes it&rsquo;s not just about business,
        maybe you just want to make your space a beautiful place to be, and who
        could blame you? However, digital wallpaper isn't exclusive to
        businesses alone; domestic settings can also be accented with brand-new
        bespoke graphics for aesthetic purposes as is perfect for rental spaces.
        Our highly experienced team are happy to help with your requests.
      </p>

      <p>
        To see some of our previous work, please visit:{" "}
        <Link to="case-studies">https://yellowsigns.co.uk/case-studies</Link>
      </p>

      <p>
        Now for the technical: digital wallpaper can be applied to any flat
        surface including walls, and glass to elevate business spaces. They are
        easy to remove so are perfect for temporary spaces or special events and
        promotions making it quick and easy to switch in and out. At Yellow
        Signs, we can produce your products with a quick turnaround since we
        create almost everything we do, in-house. This means that we can supply
        your signage and graphics, including digital wallpaper, anywhere in
        London, as quickly as possible. If you are struggling to find the
        winning design you&rsquo;re looking for and need some assistance in
        creating your perfect installation, our team are more than happy to help
        you to figure something out we are sure you will love!
      </p>

      <p>
        If you&rsquo;re interested in redesigning your business with us &ndash;
        using graphics and signage &ndash; please contact one of our signage
        experts on 020 7350 1567 or email{" "}
        <a href="mailto:info@yellowsigns.co.uk">info@yellowsigns.co.uk</a>
      </p>

      <p>
        Alternatively, to request a quick quote visit{" "}
        <Link to="quote-site-visit">
          https://yellowsigns.co.uk/quote-site-visit
        </Link>{" "}
        to get started!
      </p>
    </GalleryText>
  ),
  gallery: [
    {
      img: "/img/signselection/10-Full-Colour-Wallpaper/FULL-COLOUR-PRINTED-DIGITAL-WALLPAPER.jpg",
      title: "FULL COLOUR PRINTED DIGITAL WALLPAPER",
    },
    {
      img: "/img/signselection/10-Full-Colour-Wallpaper/full-colour-digitally-printed-wallpaper.jpg",
      title: "Full Colour Digitally Printed Wallpaper",
    },
    {
      img: "/img/signselection/10-Full-Colour-Wallpaper/CutVinylFullColourWallpaper.jpg",
      title: "Cut Vinyl & Full Colour Wallpaper",
    },
    {
      img: "/img/signselection/10-Full-Colour-Wallpaper/FullColourPartialWrapWallpaper.jpg",
      title: "Full Colour Partial Wrap Wallpaper",
    },
    {
      img: "/img/signselection/10-Full-Colour-Wallpaper/FullColourWallpaper2.jpg",
      title: "Full Colour Wallpaper",
    },
    {
      img: "/img/signselection/10-Full-Colour-Wallpaper/FullColourWallpaper3.jpg",
      title: "Full Colour Wallpaper",
    },
    {
      img: "/img/signselection/10-Full-Colour-Wallpaper/FullColourWallpaper4.jpg",
      title: "Full Colour Wallpaper",
    },
    {
      img: "/img/signselection/10-Full-Colour-Wallpaper/FullColourWallpaperCounterWrap.jpg",
      title: "Full Colour Wallpaper & Counter Wrap",
    },
    {
      img: "/img/signselection/10-Full-Colour-Wallpaper/FullColourWallpaperFloorGraphics.jpg",
      title: "Full Colour Wallpaper & Floor Graphics",
    },
    {
      img: "/img/signselection/10-Full-Colour-Wallpaper/MapFullColourWallpaper.jpg",
      title: "Map Full Colour Wallpaper",
    },
  ],
};

const troughLight = {
  name: "Trough Lighting",
  href: "/trough-lighting",
  content:
    "Trough lighting is usually used for shop or retail signs where there is no internal illumination. Black or white slimline lights are most popular but a wide range of colours are available, with finishes in matt, satin or gloss finish.",
  footerContent: (
    <GalleryText>
      <p>
        Everything has pros and cons. Whether we like it or not, this happens in
        business too. There&rsquo;s nothing worse than spending money on a piece
        of physical, visual marketing where it can only be appreciated for 12
        hours of the day. After the time you&rsquo;ve spent perfecting your
        final design it would be a huge shame if it was less effective. We would
        love to say that we could solve everything but that might be an
        overstatement to say the least! However, to keep you illuminated 24/7
        and highlight your business in the best way we know how, our signature
        trough lighting will do the job.
      </p>

      <p>
        Trough lighting is basically the lightbulb to your business. We take
        your choice of lighting and fit it onto the external or overhead of your
        sign to provide an illuminated glow over or aside your sign which offers
        higher levels of visibility even in the darkest of nights. Since new
        signage can be expensive and might not be in your budget, we don&rsquo;t
        want to leave you without a solution to 24/7 advertising and trough
        lighting is a cheap alternative to purchasing a new illuminated sign.
        Trough lighting also gives a sleek and professional look to your
        business and translates a sense of consideration and clarity to your
        consumers to keep them looking for you on the high street.
      </p>

      <p>
        But as a London based business, we know how unreliable the weather can
        be. Luckily, our trough lighting is weather resistance regardless of
        rain, sleet or snow, so you have less to maintain. So how will this
        benefit you? Being able to access your consumer regardless of the time
        of day leaves you accessible to a more successful business. Whether your
        consumer is returning home after a late one in the office on a winters
        night or a family are on their way home from a dinner nearby, being able
        to convey your brand visibility regardless of the time of day is
        imperative in achieving commercial success.
      </p>

      <p>
        Where do we come in? We here, at Yellow Signs, produce almost all of
        everything that we do - in-house at our Battersea production centre - on
        the regular so that we can complete a quick and efficient turnaround of
        products. So much so, that we can get your graphics and signage and
        everything in between, such as your trough lighting, in London, where
        ever you are! Sound good? It does to us too!
      </p>

      <p>
        To see more of our past work, please visit:{" "}
        <Link to="case-studies">https://yellowsigns.co.uk/case-studies</Link>
      </p>

      <p>
        If you&rsquo;re interested in shining a light on your business with us
        &ndash; using graphics and signage &ndash; please contact one of our
        signage experts on 020 7350 1567 or email{" "}
        <a href="mailto:info@yellowsigns.co.uk">info@yellowsigns.co.uk</a>
      </p>

      <p>
        Alternatively, to request a quick quote visit{" "}
        <Link to="quote-site-visit">
          https://yellowsigns.co.uk/quote-site-visit
        </Link>{" "}
        to get started!
      </p>
    </GalleryText>
  ),
  gallery: [
    {
      img: "/img/signselection/12-Trough-Lighting/POST-SIGN-SLIMLINE-TROUGH-LIGHT-POWDER-COATED-TO-RAL.jpg",
      title: "POST SIGN SLIMLINE TROUGH LIGHT POWDER COATED TO RAL",
    },
    {
      img: "/img/signselection/12-Trough-Lighting/SLIMLINE-TROUGH-LIGHT-POWDER-COATED-TO-RAL.jpg",
      title: "SLIMLINE TROUGH LIGHT POWDER COATED TO RAL",
    },
    {
      img: "/img/signselection/12-Trough-Lighting/SLIMLINE-TROUGH-LIGHTS-POWDER-COATED-TO-RAL.jpg",
      title: "SLIMLINE TROUGH LIGHTS POWDER COATED TO RAL",
    },
    {
      img: "/img/signselection/12-Trough-Lighting/WHITE-SLIMLINE-TROUGH-LIGHT.jpg",
      title: "WHITE SLIMLINE TROUGH LIGHT",
    },
    {
      img: "/img/signselection/12-Trough-Lighting/BlackTroughLights.jpg",
      title: "Black Trough Lights",
    },
    {
      img: "/img/signselection/12-Trough-Lighting/SlimlineBlackTroughLights.jpg",
      title: "Slimline Black Trough Lights",
    },
    {
      img: "/img/signselection/12-Trough-Lighting/SlimlineBlackTroughLights2.jpg",
      title: "Slimline Black Trough Lights",
    },
    {
      img: "/img/signselection/12-Trough-Lighting/SlimlineGreyTroughLights.jpg",
      title: "Slimline Grey Trough Lights",
    },
    {
      img: "/img/signselection/12-Trough-Lighting/SlimlineWhiteTroughLights.jpg",
      title: "Slimline White Trough Lights",
    },
    {
      img: "/img/signselection/12-Trough-Lighting/POWDER_COATED_SLIMLINE_TROUGH_LIGHT.jpg",
      title: "POWDER_COATED_SLIMLINE_TROUGH_LIGHT",
    },
    {
      img: "/img/signselection/12-Trough-Lighting/POWDER_COATED_DOUBLE_SLIMLINE_LED_TROUGH_LIGHTS.jpg",
      title: "POWDER_COATED_DOUBLE_SLIMLINE_LED_TROUGH_LIGHTS",
    },
    {
      img: "/img/signselection/12-Trough-Lighting/POWDER_COATED_PROJECTING_SIGN_WITH_DOUBLE_SIDED_TROUGH_LIGHTS.jpg",
      title: "POWDER_COATED_PROJECTING_SIGN_WITH_DOUBLE_SIDED_TROUGH_LIGHTS",
    },
    {
      img: "/img/signselection/12-Trough-Lighting/POWDER_COATED_SLIMLINE_LED_TROUGH_LIGHT.jpg",
      title: "POWDER_COATED_SLIMLINE_LED_TROUGH_LIGHT",
    },
  ],
};

const bespokeFabrication = {
  name: "Bespoke Fabrication",
  href: "/bespoke-fabrication",
  content:
    "Do you need some signage that you can’t see on our website? Chances are we still do it! We specialize in fabricating our signs and graphics in-house from our Battersea production centre and have a large list of machines, which means we can fabricate almost anything you can think of.",
  footerContent: (
    <GalleryText>
      <p>
        Through a combination of machinery and professional know-how, our team
        of experts can help you realise your next project idea.
      </p>

      <p>
        As well as traditional print, graphics and signage equipment we also
        utilise the latest 3D printing technology including eco-friendly
        consumables. 3D Printing is a useful fabrication tool which we often use
        in conjunction with other production methods and machinery for more
        bespoke projects and solutions.
      </p>

      <p>
        PET-G is the most common filament used in 3D printing. It’s extremely
        hard wearing and suitable for outdoor use – it’s virtually
        indestructible.
      </p>
      <p>
        PLA is a more eco-friendly and sustainable product. It is a recyclable,
        natural thermoplastic polyester that is derived from renewable resources
        such as corn starch or sugar cane. It’s still very hard wearing but we
        recommend it for indoor use only.
      </p>

      <p>
        For examples of our past works, you can check out{" "}
        <Link to="case-studies">https://yellowsigns.co.uk/case-studies</Link>
      </p>

      <p>
        If you’re interested in bespoke fabrication or 3D printing for your
        business please contact one of our signage experts at 020 7350 1567 or
        email <a href="mailto:info@yellowsigns.co.uk">info@yellowsigns.co.uk</a>
      </p>
      <p>
        Alternatively, to request a quick quote visit{" "}
        <a href="https://yellowsigns.co.uk/quote-site-visit">
          yellowsigns.co.uk/quote-site-visit
        </a>{" "}
        to get started!
      </p>
    </GalleryText>
  ),
  gallery: [
    {
      img: "/img/signselection/Bespoke-Fabrication/4-COLOUR_3D_PRINTING.jpg",
      title: "4-COLOUR_3D_PRINTING",
    },
    {
      img: "/img/signselection/Bespoke-Fabrication/ACRYLIC_BILTONG_AIR_DRYER.jpg",
      title: "ACRYLIC_BILTONG_AIR_DRYER",
    },
    {
      img: "/img/signselection/Bespoke-Fabrication/ARTIFICIAL_GRASS__ACRYLIC.jpg",
      title: "ARTIFICIAL_GRASS_&_ACRYLIC",
    },
    {
      img: "/img/signselection/Bespoke-Fabrication/BESPOKE_HALO_LED_ACRYLIC.jpg",
      title: "BESPOKE_HALO_LED_ACRYLIC",
    },
    {
      img: "/img/signselection/Bespoke-Fabrication/CUSTOMISED_LED_DOWNLIGHERS.jpg",
      title: "CUSTOMISED_LED_DOWNLIGHERS",
    },
    {
      img: "/img/signselection/Bespoke-Fabrication/FAUX_NEON_ROUNDEL.jpg",
      title: "FAUX_NEON_ROUNDEL",
    },
    {
      img: "/img/signselection/Bespoke-Fabrication/HOSPITALITY_HIDE.jpg",
      title: "HOSPITALITY_HIDE",
    },
    {
      img: "/img/signselection/Bespoke-Fabrication/SPEAKER_WRAPPING.jpg",
      title: "SPEAKER_WRAPPING",
    },
    {
      img: "/img/signselection/Bespoke-Fabrication/SUSPENDED_CEILING_LED_NEON.jpg",
      title: "SUSPENDED_CEILING_LED_NEON",
    },
  ],
};

const digitalLithoPrint = {
  name: "Digital & Litho Print",
  href: "/print",
  content:
    "Yellow Print has been delivering the best in digital and litho print for nearly 20 years, with a core customer base in London but also customers all over the UK.",
  footerContent: (
    <GalleryText>
      <p>
        Yellow Print offers same-day delivery in London, as well as next-day
        delivery to anywhere in the UK.
      </p>

      <p>
        You can also collect your print job from the production centre in the
        same building as Yellow Signs, at Unit 5B River Reach, Gartons Way,
        London SW11 3SX.
      </p>

      <p>
        Yellow Print offers a complete print solution for any business – whether
        it be an SME, non-profit or large corporate company. Products range from
        short run digital print such as flyers and business cards, to long run
        leaflets and booklets, as well as large format print and bespoke
        printing.
      </p>

      <p>
        Bespoke print includes embossing, debossing, foiling, colour edging and
        various laminating options so you can always find the right product and
        solution for your business.
      </p>
      <p>
        Yellow Print holds a huge range of papers and cards including premium
        ranges including GF Smith for those extra special and more luxurious
        print jobs where you need to make a statement or standout from the
        crowd. Feel free to pop into the production centre to look at samples –
        just contact us before you pop in to make sure we have the right samples
        ready for you.
      </p>

      <p>
        For more information and to see examples of print from our sister
        company, please visit{" "}
        <a href="https://yellowprint.co.uk ">www.yellowprint.co.uk </a>
      </p>

      <p>
        Alternatively, if you would like to place an order for print or would
        like some advice, please contact one of our print experts on 020 8875
        0332 or email{" "}
        <a href="mailto:info@yellowprint.co.uk">info@yellowprint.co.uk</a>
      </p>
    </GalleryText>
  ),
  gallery: [
    {
      img: "/img/signselection/Print/booklets-brochures.jpg",
      title: "booklets brochures",
    },
    {
      img: "/img/signselection/Print/business-stationery.jpg",
      title: "business stationery",
    },
    {
      img: "/img/signselection/Print/document-printing.jpg",
      title: "document printing",
    },
    {
      img: "/img/signselection/Print/embossing-and-foiling.jpg",
      title: "embossing and foiling",
    },
    {
      img: "/img/signselection/Print/flyers.jpg",
      title: "flyers",
    },
    {
      img: "/img/signselection/Print/folded-leaflets.jpg",
      title: "folded leaflets",
    },
    {
      img: "/img/signselection/Print/graphics-and-vinyl.jpg",
      title: "graphics and vinyl",
    },
    {
      img: "/img/signselection/Print/labels-and-stickers.jpg",
      title: "labels and stickers",
    },
    {
      img: "/img/signselection/Print/occasional-print.jpg",
      title: "occasional print",
    },
    {
      img: "/img/signselection/Print/posters-and-plans.jpg",
      title: "posters and plans",
    },
    {
      img: "/img/signselection/Print/signage.jpg",
      title: "signage",
    },
    {
      img: "/img/signselection/Print/wedding-stationery.jpg",
      title: "wedding stationery",
    },
  ],
};

const tradeSignage = {
  name: "Trade Signage",
  href: "/trade",
  content:
    "We work with and supply other signage, print and graphics companies as a trade supplier due to our fast turnaround times and strategic location in central London.",
  footerContent: (
    <GalleryText>
      <p>
        We specialise in built-up 3D letters and same-day/next-day turnaround
        times on UV cured print. Our super-fast Mimaki UV printers mean that we
        can print and deliver print same-day in London!
      </p>

      <p>
        We offer supply only as well as supply and installation, with optional
        plain clothes installers.
      </p>

      <p>
        We also utilise our equipment such as router, laser cutter, metal
        bender, 3D printers and spray booth to offer the best prices and fastest
        turnaround times on built-up 3D letters. Our built-up letters can be
        finished in any RAL or Pantone colour and can be front and/or halo
        illuminated, as well as side illuminated too.
      </p>

      <p>
        For examples of our past works, you can check out{" "}
        <Link to="case-studies">https://yellowsigns.co.uk/case-studies</Link>
      </p>

      <p>
        If you’re interested in getting a quote for your next project or you’re
        thinking about buying trade signage &ndash; please contact one of our
        signage experts at 020 7350 1567 or email{" "}
        <a href="mailto:info@yellowsigns.co.uk">info@yellowsigns.co.uk</a>
      </p>
      <p>
        Alternatively, to request a quick quote visit{" "}
        <a href="https://yellowsigns.co.uk/quote-site-visit">
          yellowsigns.co.uk/quote-site-visit
        </a>{" "}
        to get started!
      </p>
    </GalleryText>
  ),
  gallery: [
    {
      img: "/img/signselection/Trade/4-COLOUR_3D_PRINTING.jpg",
      title: "4-COLOUR_3D_PRINTING",
    },
    {
      img: "/img/signselection/Trade/CONTOUR_&_KISS-CUT_PRINTING.jpg",
      title: "CONTOUR_&_KISS-CUT_PRINTING",
    },
    {
      img: "/img/signselection/Trade/FRONT,_BACK_&_SIDE_ILLUMINATED_3D_LETTERS.jpg",
      title: "FRONT,_BACK_&_SIDE_ILLUMINATED_3D_LETTERS",
    },
    {
      img: "/img/signselection/Trade/INTERNALLY_ILLUMINATED_BUILT-UP_LETTERS.jpg",
      title: "INTERNALLY_ILLUMINATED_BUILT-UP_LETTERS",
    },
    {
      img: "/img/signselection/Trade/LASER.jpg",
      title: "LASER",
    },
    {
      img: "/img/signselection/Trade/LED_NEON.jpg",
      title: "LED_NEON",
    },
    {
      img: "/img/signselection/Trade/PAINTED_LETTERS.jpg",
      title: "PAINTED_LETTERS",
    },
    {
      img: "/img/signselection/Trade/ROUTER.jpg",
      title: "ROUTER",
    },
    {
      img: "/img/signselection/Trade/UV_CURED_INK.jpg",
      title: "UV_CURED_INK",
    },
  ],
};

const buildConstruction = {
  name: "Building & Construction",
  href: "/build-construction",
  content:
    "Internal signage for the building and construction industry usually includes wayfinding (directional signage), window graphics and floor graphics. External signage usually includes aluminium composite hoarding which is designed to cover up building sites and offer advertising and marketing space for the project or the building company. PVC banners are also popular for smaller projects.",
  footerContent: (
    <GalleryText>
      <p>
        If you stroll down your local town centre or high street, chances are
        you&rsquo;ll spot at least one if not more construction sites busily
        being readied for their purpose. Often you will notice the huge
        scaffolding structures or the sound of builders drilling and hammering
        until they clock off from work. As humans we are naturally curious: when
        we see something new in our local area we want to know what it is and if
        is it something we want to or should invest our time in. As we said,
        building and construction surround us; so much so, that it becomes a
        perfect place to market a new building, business or project, even before
        the build has started and is completed.
      </p>

      <p>
        We&rsquo;ve made the point so let&rsquo;s back it up. There are a
        variety of options for signage and graphics your business or
        construction site can utilise to create brand awareness during your
        building process. After all, the journey is just as important as the
        destination. Let's talk options for those building sites, covered head
        to toe in scaffolding, PVC banners can play a big role in representing
        your business's work. If a passer-by sees the work ethic or quality of
        your building team or production method, what better way to engage and
        offer your services than by advertising off the back of your current
        projects?
      </p>

      <p>
        What's more? Some projects are, needless to say, much more dangerous
        than others. With large-scale builds often requiring more intensive
        work, health and safety require levels of protective barriers to keep
        members of the public safe from debris and danger. Hoardings are the
        perfect non-invasive barrier for your business and act as a signpost for
        your business, perfect for displaying contact information, blueprints or
        projected results! However, building sites aren&rsquo;t the only area
        you can signpost in the construction industry. Vehicles and shop fronts
        play a vital role in the way in which we receive our marketing. From
        aluminium overhead trays to vehicle wraps and graphics, using your
        everyday business investment as a form of 24/7 advertisement is a great
        way to drive sales.
      </p>

      <p>
        For more information on our past work, please visit:{" "}
        <Link to="case-studies">https://yellowsigns.co.uk/case-studies</Link>
      </p>

      <p>
        How can we help? As a London-based business, we know the demand in the
        construction industry is intense and requires quick turnarounds of
        products. Luckily, we produce almost everything we do in-house in our
        Battersea workshop meaning we can get your signage and graphics anywhere
        in London quickly so you can focus on making your projects everything
        you need them to be. If you would like help producing something more
        bespoke, feel free to contact a member of our qualified team to get
        started; alternatively, if you need any design help we are happy to
        help!
      </p>

      <p>
        If you&rsquo;re interested in working with us &ndash; using graphics and
        signage &ndash; please contact one of our signage experts at 020 7350
        1567 or email
        <a href="mailto:info@yellowsigns.co.uk">info@yellowsigns.co.uk</a>
      </p>

      <p>
        Alternatively, to request a quick quote visit{" "}
        <Link to="quote-site-visit">
          https://yellowsigns.co.uk/quote-site-visit
        </Link>{" "}
        to get started!
      </p>
    </GalleryText>
  ),
  gallery: [
    {
      img: "/img/signsbysector/2-Building-Construction/white-5mm-acrylic-letters.jpg",
      title: "White 5mm Acrylic Letters",
    },
    {
      img: "/img/signsbysector/2-Building-Construction/AcrylicBackedAluminiumCompositeTray.jpg",
      title: "Acrylic Backed Aluminium Composite Tray",
    },
    {
      img: "/img/signsbysector/2-Building-Construction/CutFullColourVinylVehicleGraphics.jpg",
      title: "Cut & Full Colour Vinyl Vehicle Graphics",
    },
    {
      img: "/img/signsbysector/2-Building-Construction/CutVinyl.jpg",
      title: "Cut Vinyl",
    },
    {
      img: "/img/signsbysector/2-Building-Construction/CutVinylAluminiumCompositeHoarding.jpg",
      title: "Cut Vinyl Aluminium Composite Hoarding",
    },
    {
      img: "/img/signsbysector/2-Building-Construction/CutVinylJCBGraphics.jpg",
      title: "Cut Vinyl JCB Graphics",
    },
    {
      img: "/img/signsbysector/2-Building-Construction/FlatCutVinylTruckGraphics.jpg",
      title: "Flat Cut Vinyl Truck Graphics",
    },
    {
      img: "/img/signsbysector/2-Building-Construction/FullColourVehicleGraphics.jpg",
      title: "Full Colour Vehicle Graphics",
    },
    {
      img: "/img/signsbysector/2-Building-Construction/PVCBanner.jpg",
      title: "PVC Banner",
    },
    {
      img: "/img/signsbysector/2-Building-Construction/VehicleGraphics3.jpg",
      title: "3D Built-Up Letters on Tray",
    },
    {
      img: "/img/signsbysector/2-Building-Construction/VEHICLE_GRAPHICS_AND_CHEVRONS.jpg",
      title: "VEHICLE GRAPHICS AND CHEVRONS",
    },
    {
      img: "/img/signsbysector/2-Building-Construction/BLACK_CUT_VINYL_VEHICLE_GRAPHICS.jpg",
      title: "BLACK CUT VINYL VEHICLE GRAPHICS",
    },
  ],
};

const estateAgencyProperty = {
  name: "Estate Agent & Property",
  href: "/estate-agent-property",
  content:
    "Single or multi-branch estate agencies often use many types of signage – from vehicle graphics and window graphics to shop fascias and projecting signs. Property refurbishment companies often use outdoor PVC banners, as well as hoarding and foam PVC boards.",
  footerContent: (
    <GalleryText>
      <p>
        Imagine you&rsquo;re a couple looking to purchase your first home; a
        simple search on Google for &lsquo;estate agents near me&rsquo; can lead
        you to a wild assortment of businesses making different promises which
        can undoubtedly be annoying. Estate agents in the UK started in the
        Capital so the industry is highly-saturated with lots of agencies trying
        to get ahead of the curve. So what makes your business different? Do you
        offer competitive prices or have you won industry awards that make you
        more notable than the rest? This NEEDS to be seen. Let us help you
        create incredible visuals that are sure to impress.
      </p>

      <p>
        Where to begin? Let's start with externals. Overhead signage is almost
        always the first part of a business a consumer will look at on the high
        street. It lists the name of your business and draws their attention
        from others shops to yours and what it can offer them. From built-up
        trays to flat letter acrylics - that will impress from close and far
        distances - to window graphics that can list your many achievements and
        stats, choosing high-quality and well-designed outdoor signage and
        graphics is vital in getting customers to choose your business as their
        destination of choice.
      </p>

      <p>
        Your advertising doesn&rsquo;t finish with your business space. As
        people that work in property, you will know that getting people through
        the door doesn&rsquo;t mean the property sells itself. There are other
        ways you can enhance your customer experience and foster a sense of
        reliability and brand authenticity. One way is by using your vehicles as
        portable advertising. If your business is lucky enough to utilise
        company vehicles, you can add cut vinyl graphics and wraps to cover your
        vehicle in your brand image and ensure your customers know exactly who's
        at their doorstep. Not only this but vehicles are constantly seen
        whether they're on the move or parked up on the roadside. This means
        they are seen; it only takes one person to take an interest in your
        business to bolster a sale.
      </p>

      <p>
        So, how can we help? As business routed in London, we know how London
        works. Whether it&rsquo;s identifying what form of visual marketing
        works the best in the area, to the best product or products for your
        business space, we want to make your experience as simplistic as
        possible. This is why we produce almost everything we do in our
        Battersea production house so we can complete a quick turnaround of
        products to supply your winning signage and graphics, especially for the
        property industry, anywhere in London in little to no time.
      </p>

      <p>
        To view, some of our past work, visit:{" "}
        <Link to="case-studies">https://yellowsigns.co.uk/case-studies</Link>
      </p>

      <p>
        If you&rsquo;re interested in working with us &ndash; using graphics and
        signage &ndash; please contact one of our signage experts at 020 7350
        1567 or email{" "}
        <a href="mailto:info@yellowsigns.co.uk">info@yellowsigns.co.uk</a>
      </p>

      <p>
        Alternatively, to request a quick quote visit{" "}
        <Link to="quote-site-visit">
          https://yellowsigns.co.uk/quote-site-visit
        </Link>{" "}
        to get started!
      </p>
    </GalleryText>
  ),
  gallery: [
    {
      img: "/img/signsbysector/3-Estate-Agent-Property/illuminated-aluminium-pushed-through-acrylic-sign-tray.jpg",
      title: "Illuminated Aluminium Pushed Through Acrylic Sign Tray",
    },
    {
      img: "/img/signsbysector/3-Estate-Agent-Property/full-colour-digitally-printed-window-graphics-1.jpg",
      title: "Full Colour Digitally Printed Window Graphics",
    },
    {
      img: "/img/signsbysector/3-Estate-Agent-Property/full-colour-digitally-printed-window-graphics-2.jpg",
      title: "Full Colour Digitally Printed Window Graphics",
    },
    {
      img: "/img/signsbysector/3-Estate-Agent-Property/AcrylicBackedAluminiumCompositeTray.jpg",
      title: "Acrylic Backed Aluminium Composite Tray",
    },
    {
      img: "/img/signsbysector/3-Estate-Agent-Property/CutVinylCarGraphics.jpg",
      title: "Cut Vinyl Car Graphics",
    },
    {
      img: "/img/signsbysector/3-Estate-Agent-Property/CutVinylFacedAluminiumCompositeTray.jpg",
      title: "Cut Vinyl Faced Aluminium Composite Tray",
    },
    {
      img: "/img/signsbysector/3-Estate-Agent-Property/CutVinylFullColourCarGraphics.jpg",
      title: "Cut Vinyl & Full Colour Car Graphics",
    },
    {
      img: "/img/signsbysector/3-Estate-Agent-Property/CutVinylStainlessSteelPlaque.jpg",
      title: "Cut Vinyl Stainless Steel Plaque",
    },
    {
      img: "/img/signsbysector/3-Estate-Agent-Property/FlatCutAcrylicBrushedStainlessSteelLetters.jpg",
      title: "Flat Cut Acrylic & Brushed Stainless Steel Letters",
    },
    {
      img: "/img/signsbysector/3-Estate-Agent-Property/FlatCutAcrylicLettersonAluminiumTray.jpg",
      title: "Flat Cut Acrylic Letters on Aluminium Tray",
    },
    {
      img: "/img/signsbysector/3-Estate-Agent-Property/FlatCutBlueBrushedStainlessSteelLetters.jpg",
      title: "Flat Cut Blue Brushed Stainless Steel Letters",
    },
    {
      img: "/img/signsbysector/3-Estate-Agent-Property/FlushAcrylicAluminiumTray.jpg",
      title: "Flush Acrylic Aluminium Tray",
    },
    {
      img: "/img/signsbysector/3-Estate-Agent-Property/FullColourCarWrap.jpg",
      title: "Full Colour Car Wrap",
    },
    {
      img: "/img/signsbysector/3-Estate-Agent-Property/PartialWrapCarGraphics.jpg",
      title: "Partial Wrap Car Graphics",
    },
    {
      img: "/img/signsbysector/3-Estate-Agent-Property/PushedThroughAcrylicAluminiumCompositeTray.jpg",
      title: "Pushed-Through Acrylic Aluminium Composite Tray",
    },
  ],
};

const exhibitionEvent = {
  name: "Exhibition & Event",
  href: "/exhibition-event",
  content:
    "There is a wide range of signage products for exhibitions and events. Popup portable displays and roller banners, as well as flags and backdrops are popular to help promote the business or a specific product. Wide format prints and lightboxes are perfect for exhibitions and events.",
  footerContent: (
    <GalleryText>
      <p>
        If you do a quick search online for &lsquo;events near me, your webpage
        will be hounded with hundreds of events - ranging from big to small -
        all over London, ready for the public to take some time out of their day
        to visit. A common theme with these events is graphics. From small-scale
        logos to large-scale exhibition displays; utilising graphic and signage
        design adds a level of professionalism and trust to an event and helps
        to engage a large crowd. With every event catering to different subjects
        and industries, it can be hard to find the perfect products for your
        business; better yet, it's even harder knowing how they can be used.
        Luckily we are here to make it that little bit easier.
      </p>

      <p>
        A highly popular and extremely diversifiable form of event display is
        full-colour graphics which can be used in a variety of ways. From window
        graphics to entice people into the building to PVC stand-up displays to
        direct or inform, by choosing a customisable form or graphic, you can
        enhance your event space and help things to run smoothly both
        directionally and informationally. Roller banners and PVC banners are a
        form of signage that can be designed in a variety of sizes and colours
        to display useful information. Perhaps you&rsquo;re producing a food
        exhibition and want passers-by to know exactly when and where they need
        to be to try out your tasty treats. Or maybe you&rsquo;re looking to
        point people in the correct direction of the toilets at a music event
        without breaking the bank, banners are a great way to keep all that
        much-needed info at eye view to make your entire event run smoothly.
      </p>

      <p>
        For more, please visit:{" "}
        <Link to="case-studies">https://yellowsigns.co.uk/case-studies</Link>
      </p>

      <p>
        However, not all signage and graphics need to be fit for purpose.
        Sometimes it&rsquo;s just fun to have something that looks really cool.
        We completely understand this, and we love your creativity! There are
        many aesthetic ways to translate your vision and create something
        visually amazing. So what can we offer you? Coated polystyrene is a
        fantastic way of producing portable displays of figurines and objects
        which are great for an interesting photo op. A statue of a reindeer for
        a Xmas fair? Get those Instagram hashtags rolling in and make your event
        the place to be!
      </p>

      <p>
        Where do we come in? as a London-based company we know all about the
        event circuit. We know exactly what works and how to make it. Our highly
        experienced team are also more than happy to help with bespoke requests.
        We produce almost all of everything we do in our Battersea production
        space meaning we have a quick turnaround of products, getting your
        signage and graphics, especially for events and exhibitions, in London
        ready to go.
      </p>

      <p>
        If you&rsquo;re interested in decking out your event with us &ndash;
        using graphics and signage &ndash; please contact one of our signage
        experts on 020 7350 1567 or email{" "}
        <a href="mailto:info@yellowsigns.co.uk">info@yellowsigns.co.uk</a>
      </p>

      <p>
        Alternatively, to request a quick quote visit{" "}
        <Link to="quote-site-visit">
          https://yellowsigns.co.uk/quote-site-visit
        </Link>{" "}
        to get started!
      </p>
    </GalleryText>
  ),
  gallery: [
    {
      img: "/img/signsbysector/4-Exhibition-Event/FULL-COLOUR-PRINTED-VINYL.jpg",
      title: "FULL COLOUR PRINTED VINYL",
    },
    {
      img: "/img/signsbysector/4-Exhibition-Event/HIGH-TACK-MATT-BLACK-CUT-VINYL.jpg",
      title: "HIGH-TACK MATT BLACK CUT VINYL",
    },
    {
      img: "/img/signsbysector/4-Exhibition-Event/cut-vinyl-van-graphics.jpg",
      title: "Cut Vinyl Car Graphics",
    },
    {
      img: "/img/signsbysector/4-Exhibition-Event/CoatedPolystyrene.jpg",
      title: "Coated Polystyrene",
    },
    {
      img: "/img/signsbysector/4-Exhibition-Event/FullColourGraphics.jpg",
      title: "Full Colour Graphics",
    },
    {
      img: "/img/signsbysector/4-Exhibition-Event/FullColourVinylFoamPVC.jpg",
      title: "Full Colour Vinyl & Foam PVC",
    },
    {
      img: "/img/signsbysector/4-Exhibition-Event/FullColourWallpaper.jpg",
      title: "Full Colour Wallpaper",
    },
    {
      img: "/img/signsbysector/4-Exhibition-Event/LightBoxGraphics.jpg",
      title: "Light Box Graphics",
    },
    {
      img: "/img/signsbysector/4-Exhibition-Event/PVCBanners.jpg",
      title: "PVC Banners",
    },
    {
      img: "/img/signsbysector/4-Exhibition-Event/WoodFramedFullColourGraphics.jpg",
      title: "Wood Framed Full Colour Graphics",
    },
    {
      img: "/img/signsbysector/4-Exhibition-Event/ALUMINIUM_COMPOSITE_ENTRANCE_SIGN_DROP.jpg",
      title: "ALUMINIUM COMPOSITE ENTRANCE SIGN DROP",
    },
  ],
};

const officeCorporate = {
  name: "Office & Corporate",
  href: "/office-corporate",
  footerContent: (
    <GalleryText>
      <p>
        For office-based businesses, displaying a degree of professionalism is
        essential in fostering a successful work environment. From the second a
        client or new employee steps into your office, it should set the tone
        and standard for your business and convey the exact tone you want to
        pass along. From privacy to direction, your intentions in business
        should be clear and what better way to portray this than through
        striking new signage and graphics?
      </p>

      <p>
        One of our favourite ways to up your graphics game in more formal
        settings whilst offering a practical benefit is through privacy
        frosting. This process is achieved by using a sheer-opaque or
        &lsquo;frosted&rsquo; window sticker which blurs or masks the visuals.
        This can vary in blurriness so you can control how much or little you
        would like people to view without compromising on your lighting or
        accessibility. This is a fantastic way to spice up a potentially bland
        office space, add a sense of luxury and most importantly, shield
        sensitive information which I&rsquo;m sure your clients will value.
      </p>

      <p>
        Similarly, wayfinding signage is great for those large,
        tricky-to-navigate office spaces; by creating a simplistic work
        environment, both employees will be able to get where they need to be
        and clients will feel at ease with your business. Nobody likes getting
        lost and these added details can enhance your overall experience from
        the second you enter the door to the moment you leave and everything in
        between. But aside from the practical signage and graphics, what can you
        include in your corporate space to represent your business? How about
        your name?! Acrylic letters are the perfect way to translate your
        business into your location. It offers a degree of reliability and
        professionalism as well as just making your office space stylish.
      </p>

      <p>
        At Yellow Signs, we know how important corporate spaces in London are.
        We also know that receiving your graphics in good time is incredibly
        crucial. That is why we produce almost all of our products in our
        Battersea production centre meaning we have a quick turnaround of
        products. Since our signage for the corporate sector is in high demand,
        we produce our products in-house so we can supply your signage and
        graphics, especially for office spaces and corporate buildings, anywhere
        in London, in little to no time at all. If you are looking for something
        more bespoke or need some design help to push your office space over the
        edge, feel free to contact our team who will be more than happy to
        assist you.
      </p>

      <p>
        For more information on our past work, please visit{" "}
        <Link to="case-studies">https://yellowsigns.co.uk/case-studies</Link>
      </p>

      <p>
        If you&rsquo;re interested in enhancing your corporate space with us
        &ndash; using graphics and signage &ndash; please contact one of our
        signage experts at 020 7350 1567 or email{" "}
        <a href="mailto:info@yellowsigns.co.uk">info@yellowsigns.co.uk</a>
      </p>

      <p>
        Alternatively, to request a quick quote visit{" "}
        <Link to="quote-site-visit">
          https://yellowsigns.co.uk/quote-site-visit
        </Link>{" "}
        to get started!
      </p>
    </GalleryText>
  ),
  content:
    "Privacy frosting is often essential as part of good health and safety practice. Wayfinding is often used for larger and shared offices, as well as signage for meeting rooms and other communal areas. Window graphics and wide format prints are also popular for offices and corporate premises.",
  gallery: [
    {
      img: "/img/signsbysector/6-Office-Corporate/5MM-MATT-WHITE-ACRYLIC-LETTERS-ON-STAND-OFF-LOCATORS-ON-FULL-COLOUR-PRINTED-ALUMINIUM-COMPOSITE-TRAY.jpg",
      title:
        "5MM MATT WHITE ACRYLIC LETTERS ON STAND-OFF LOCATORS ON FULL COLOUR PRINTED ALUMINIUM COMPOSITE TRAY",
    },
    {
      img: "/img/signsbysector/6-Office-Corporate/10MM-MATT-WHITE-ACRYLIC-LETTERS-WITH-FULL-COLOUR-PRINT-TO-FACE.jpg",
      title: "10MM MATT WHITE ACRYLIC LETTERS WITH FULL COLOUR PRINT TO FACE",
    },
    {
      img: "/img/signsbysector/6-Office-Corporate/20MM-ACRYLIC-LETTERS-WET-COATED-TO-RAL.jpg",
      title: "20MM ACRYLIC LETTERS WET COATED TO RAL",
    },
    {
      img: "/img/signsbysector/6-Office-Corporate/BLACK-ACRYLIC-WITH-HALO-ILLUMINATION.jpg",
      title: "BLACK ACRYLIC WITH HALO ILLUMINATION",
    },
    {
      img: "/img/signsbysector/6-Office-Corporate/wet-coated-matt-black-5mm-acrylic-letters.jpg",
      title: "Wet Coated Matt Black 5mm Acrylic Letters",
    },
    {
      img: "/img/signsbysector/6-Office-Corporate/cut-to-shape-frosted-vinyl--cut-orange-vinyl.jpg",
      title: "Cut to Shape Frosted Vinyl + Cut Orange Vinyl",
    },
    {
      img: "/img/signsbysector/6-Office-Corporate/wet-coated-5mm-acrylic-letters-on-stand-off-locators.jpg",
      title: "Wet Coated 5mm Acrylic Letters on Stand-Off Locators",
    },
    {
      img: "/img/signsbysector/6-Office-Corporate/cut-vinyl-window-graphics.jpg",
      title: "Cut Vinyl Window Graphics",
    },
    {
      img: "/img/signsbysector/6-Office-Corporate/3DBuiltupIlluminatedLetters.jpg",
      title: "3D Built-up Illuminated Letters",
    },
    {
      img: "/img/signsbysector/6-Office-Corporate/BuiltUpPolishedStainlessSteelLetters.jpg",
      title: "Built-Up Polished Stainless Steel Letters",
    },
    {
      img: "/img/signsbysector/6-Office-Corporate/CutVinyl.jpg",
      title: "Cut Vinyl",
    },
    {
      img: "/img/signsbysector/6-Office-Corporate/CutVinylFrostedVinylPlaques.jpg",
      title: "Cut Vinyl & Frosted Vinyl Plaques",
    },
    {
      img: "/img/signsbysector/6-Office-Corporate/FrostedVinyl.jpg",
      title: "Frosted Vinyl",
    },
    {
      img: "/img/signsbysector/6-Office-Corporate/FullColourVinylCutVinyl.jpg",
      title: "Full Colour Vinyl & Cut Vinyl",
    },
    {
      img: "/img/signsbysector/6-Office-Corporate/FullColourWallpaper.jpg",
      title: "Full Colour Wallpaper",
    },
    {
      img: "/img/signsbysector/6-Office-Corporate/FullColourWallpaper2.jpg",
      title: "Full Colour Wallpaper",
    },
    {
      img: "/img/signsbysector/6-Office-Corporate/FullColourWallpaper3.jpg",
      title: "Full Colour Wallpaper",
    },
    {
      img: "/img/signsbysector/6-Office-Corporate/FullColourWallpaperPanels.jpg",
      title: "Full Colour Wallpaper Panels",
    },
    {
      img: "/img/signsbysector/6-Office-Corporate/ReverseCutFrostedVinyl.jpg",
      title: "Reverse Cut Frosted Vinyl",
    },
    {
      img: "/img/signsbysector/6-Office-Corporate/WEAT_COATED_AND_FULL_COLOUR_PRINTED_CUT_ACRYLIC.jpg",
      title: "WET COATED AND FULL COLOUR PRINTED CUT ACRYLIC",
    },
    {
      img: "/img/signsbysector/6-Office-Corporate/WET_COATED_10MM_ACRYLIC_LETTERS_ON_STAND-OFF_LOCATORS.jpg",
      title: "WET COATED 10MM ACRYLIC LETTERS ON STAND-OFF LOCATORS",
    },
    {
      img: "/img/signsbysector/6-Office-Corporate/FULL_COLOUR_PRINTED_HIGH-TACK_VINYL_GRAPHICS.jpg",
      title: "FULL COLOUR PRINTED HIGH-TACK VINYL GRAPHICS",
    },
    {
      img: "/img/signsbysector/6-Office-Corporate/16MM_SILICON_EDGE_FULL_COLOUR_PRINTED_GRAPHICS.jpg",
      title: "16MM SILICON EDGE FULL COLOUR PRINTED GRAPHICS",
    },
    {
      img: "/img/signsbysector/6-Office-Corporate/TEAL_CUT_VINYL_WINDOW_GRAPHICS.jpg",
      title: "TEAL CUT VINYL WINDOW GRAPHICS",
    },
    {
      img: "/img/signsbysector/6-Office-Corporate/FULL_COLOUR_PRINTED_WALLPAPER_VINYL.jpg",
      title: "FULL COLOUR PRINTED WALLPAPER VINYL",
    },
    {
      img: "/img/signsbysector/6-Office-Corporate/FULL_COLOUR_PRINTED_VINYL_MANIFESTATIONS.jpg",
      title: "FULL COLOUR PRINTED VINYL MANIFESTATIONS",
    },
    {
      img: "/img/signsbysector/6-Office-Corporate/CUT_PRIVACY_FROSTED_VINYL.jpg",
      title: "CUT PRIVACY FROSTED VINYL",
    },
    {
      img: "/img/signsbysector/6-Office-Corporate/WET_COATED_10MM_ACRYLIC_LETTERS_WITH_FULL_COLOUR_PRINTED_VINYL_TO_FACE.jpg",
      title:
        "WET COATED 10MM ACRYLIC LETTERS WITH FULL COLOUR PRINTED VINYL TO FACE",
    },
  ],
};

const schoolEducation = {
  name: "School & Education",
  href: "/school-education",
  content:
    "Primary schools, secondary schools, colleges and universities often use a wide range of internal and external signage – from floor decals and wall graphics to outside post sides and PVC banners. PTAs often use banners and boards for events such as school fairs and fetes.",
  footerContent: (
    <GalleryText>
      <p>
        With all the uncertainty in the world, the one thing we can rely on as a
        stable institution in our society is education. We learn every day
        through the people we interact with, the books and articles we read;
        even the posts we see on Twitter. We are constantly learning and
        growing. At school, we get our first look at the world around us; from
        English to Geography we enter a building ready to inspire and teach. But
        how can we make those 4 walls of a classroom a teachable and
        inspirational moment for this generation of students; better yet, how
        can we do this using graphics and signage?
      </p>

      <p>
        Regardless of what educational site you&rsquo;re looking to redesign,
        every school, college and university need signage to direct and help
        navigate students to their required rooms or study spaces. Whether you
        seek an aluminium composite cut vinyl sign to point you in the direction
        of the science block or built-up letters to label your canteen, signage
        is vital to ensure your students aren't left wandering in the hallways
        and are where they need to be. Especially as developing minds enter the
        school sector, the need for clarity and easy-to-read signage grows
        yearly. However, your large-scale signage isn&rsquo;t your only option
        for directional signage. Wall and floor decals and graphics can provide
        the perfect tool to point guests in the right direction for open days or
        to maintain distance after post-covid restrictions as they are easily
        removable and non-damaging.
      </p>

      <p>
        However, signage doesn&rsquo;t always need to be for the important
        details, it can also be used to spice up the halls of a school or
        nursery and provide a fantastic entertaining or motivational outlet for
        students. When students open up their books for their examinations and
        the heat begins to rise, cut-vinyl graphics denoting positive quotes are
        a great way to encourage and calm down worried students in stressful
        moments of their academic careers. For a library, surrounded by exciting
        fiction or mythological tales, transport your students inside the books
        they read with bespoke digital wallpaper to immerse them in their
        learning.
      </p>

      <p>
        To view some of our past work, visit:{" "}
        <Link to="case-studies">https://yellowsigns.co.uk/case-studies</Link>
      </p>

      <p>
        At Yellow Signs, we want to be a part of making education and schools
        thrive. As a London-based business, we have supplied plenty of signage
        and graphics for schools in London, so we know just how the environment
        works. We also understand that with term times and schools being
        incredibly busy, finding that perfect timeframe to produce and install
        signage is key to prevent any disruption; we produce almost everything
        we do in our Battersea workshop to get your products completed quickly
        and installed! If you need any help designing or require a bespoke
        service, feel free to contact our team, who will be happy to help you.
      </p>

      <p>
        If you&rsquo;re interested in working with us &ndash; using graphics and
        signage &ndash; please contact one of our signage experts at 020 7350
        1567 or email
        <a href="mailto:info@yellowsigns.co.uk">info@yellowsigns.co.uk</a>
      </p>

      <p>
        Alternatively, to request a quick quote visit{" "}
        <Link to="quote-site-visit">
          https://yellowsigns.co.uk/quote-site-visit
        </Link>{" "}
        to get started!
      </p>
    </GalleryText>
  ),
  gallery: [
    {
      img: "/img/signsbysector/7-School-Education/FULL-COLOUR-PRINTED-3MM-ALUMINIUM-COMPOSITE-PANEL.jpg",
      title: "FULL COLOUR PRINTED 3MM ALUMINIUM COMPOSITE PANEL",
    },
    {
      img: "/img/signsbysector/7-School-Education/HALO-ILLUMINATED-ACRYLIC-LETTERS-WET-COATED-TO-MATT-GOLD-ON-ALUMINIUM-TRAY.jpg",
      title:
        "HALO ILLUMINATED ACRYLIC LETTERS WET COATED TO MATT GOLD ON ALUMINIUM TRAY",
    },
    {
      img: "/img/signsbysector/7-School-Education/POST-SIGN-WITH-2-COLOUR-PAINTING-&-POWDER-COATING-+-5MM-ACRYLIC-LETTERS-&-TROUGH-LIGHT.jpg",
      title:
        "POST SIGN WITH 2 COLOUR PAINTING & POWDER COATING + 5MM ACRYLIC LETTERS & TROUGH LIGHT",
    },
    {
      img: "/img/signsbysector/7-School-Education/full-colour-digitally-printed-vinyl.jpg",
      title: "Full Colour Digitally Printed Vinyl",
    },
    {
      img: "/img/signsbysector/7-School-Education/AluminiumCompositeCutVinylSign.jpg",
      title: "Aluminium Composite Cut Vinyl Sign",
    },
    {
      img: "/img/signsbysector/7-School-Education/AluminiumPostSigns.jpg",
      title: "Aluminium Post Signs",
    },
    {
      img: "/img/signsbysector/7-School-Education/AluminiumPostSigns2.jpg",
      title: "Aluminium Post Signs",
    },
    {
      img: "/img/signsbysector/7-School-Education/BuiltUpStainlessSteelPostSign.jpg",
      title: "Built-Up Stainless Steel Post Sign",
    },
    {
      img: "/img/signsbysector/7-School-Education/FullColourAluminiumCompositeGraphics.jpg",
      title: "Full Colour Aluminium Composite Graphics",
    },
    {
      img: "/img/signsbysector/7-School-Education/StainlessSteelFaceLettersonTrayPostSign.jpg",
      title: "Stainless Steel Face Letters on Tray Post Sign",
    },
    {
      img: "/img/signsbysector/7-School-Education/ALUMINIUM_COMPOSITE_SIGN_TRAYS_WITH_FULL_COLOUR_PRINT.jpg",
      title: "ALUMINIUM COMPOSITE SIGN TRAYS WITH FULL COLOUR PRINT",
    },
    {
      img: "/img/signsbysector/7-School-Education/FULL_COLOUR_PVC_BANNER_SUSPENSION_SYSTEMS.jpg",
      title: "FULL COLOUR PVC BANNER SUSPENSION SYSTEMS",
    },
  ],
};

const shopsRetail = {
  name: "Shop & Retail",
  href: "/shop-retail",
  content:
    "Shop signs and fascias are important to drive traffic into the premises. As well as aluminium and aluminium composite shop signs there is also a wide range of projecting signs to suit most businesses and their brand. Shops and retail spaces also use window graphics for semi-permanent signage and promotional material.",
  footerContent: (
    <GalleryText>
      <p>
        As consumers, we often find ourselves wandering our local shopping
        centre, perusing around the local high street or login into our
        favourite shopping site on our days off. With different industries ever
        expanding with new products being sold, we see a sudden storm of new
        shop fronts lining our busy streets, especially in London. More than
        ever, business owners like yourself are seeking new and interesting ways
        to diversify themselves from the crowd to make their shop the first
        option for shoppers everywhere. What better way to do this than by
        updating your internal and external graphics game to get all eyes on
        you?
      </p>

      <p>
        There are a variety of options for business owners to choose from to wow
        and make an impact. One of our most popular options is our custom
        overhead signage. These range in size, colour, material and most
        importantly price to slip firmly inside your budget. From 3D &amp;
        built-up lettering - to create dimension and both literally and
        metaphorically pop out at shoppers &ndash; all the way to aluminium
        trays and projecting signage to capture audiences from all sorts of
        angles and distances. However, such forms of signage are not limited to
        your shop front; why not involve your interior by bringing some acrylic
        letters into the game?
      </p>

      <p>
        However, we know that might not be what you&rsquo;re looking for. Fret
        not! We offer lots of other forms of external signage to elevate your
        externals. Window graphics are an extremely popular option for shop
        fronts as they can be made in almost any style, size, design and colour
        thanks to our full-colour graphics and vinyl graphics service to fit any
        bespoke space. They are the perfect addition to any window space as they
        are fully customisable, to stand out from the crowd. Not only this,
        utilising full-colour window graphics in specific are a great way to
        increase your colour customisation options to match your brand logo or
        identity perfectly.
      </p>

      <p>
        For more information on our past work, please visit:
        <Link to="case-studies">https://yellowsigns.co.uk/case-studies</Link>
      </p>

      <p>
        We know that securing the perfect designs is crucial in drawing
        customers in which is why we want to assure you that your products will
        be completed quickly and professionally. Since we produce almost
        everything we do in-house in our Battersea production centre, we have a
        fast turnaround of products to get your signage and graphics anywhere in
        London. You&rsquo;re more than welcome to contact our team if you are
        seeking something bespoke. Alternatively, if you need any design aid we
        are more than happy to help!
      </p>

      <p>
        If you&rsquo;re interested in decking out your retail space with us
        &ndash; using graphics and signage &ndash; please contact <br />
        one of our signage experts at 020 7350 1567 or email{" "}
        <a href="mailto:info@yellowsigns.co.uk">info@yellowsigns.co.uk</a>{" "}
        <br />
        Alternatively, to request a quick quote visit
        <Link to="quote-site-visit">
          https://yellowsigns.co.uk/quote-site-visit
        </Link>{" "}
        to get started!
      </p>
    </GalleryText>
  ),
  gallery: [
    {
      img: "/img/signsbysector/8-Shop-Retail/5MM-ACRYLIC-LETTERS-WET-COATED-TO-PANTONE-ON-WHITE-ALUMINIUM-COMPOSITE-TRAY.jpg",
      title:
        "5MM ACRYLIC LETTERS WET COATED TO PANTONE ON WHITE ALUMINIUM COMPOSITE TRAY",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/5MM-ACRYLIC-LETTERS-WET-COATED-TO-PANTONE-WITH-TROUGH-LIGHT-&-LED-NEON.jpg",
      title:
        "5MM ACRYLIC LETTERS WET COATED TO PANTONE WITH TROUGH LIGHT & LED NEON",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/7MM-PUSHED-THROUGH-OPAL-ACRYLIC-LOGO-&-LETTERS-+-WINDOW-GRAPHICS.jpg",
      title: "7MM PUSHED-THROUGH OPAL ACRYLIC LOGO & LETTERS + WINDOW GRAPHICS",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/7MM-PUSHED-THROUGH-OPAL-ACRYLIC-LOGO-&-LETTERS-ON-FULL-COLOUR-PRINTED-ALUMINIUM-COMPOSITE-TRAY.jpg",
      title:
        "7MM PUSHED-THROUGH OPAL ACRYLIC LOGO & LETTERS ON FULL COLOUR PRINTED ALUMINIUM COMPOSITE TRAY",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/10MM-&-5MM-ACRYLIC-LETTERS-WET-COATED-TO-PANTONE.jpg",
      title: "10MM & 5MM ACRYLIC LETTERS WET COATED TO PANTONE",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/20MM,-10MM-&-5MM-ACRYLIC-LETTERS-WET-COATED-TO-MATT-GOLD.jpg",
      title: "20MM, 10MM & 5MM ACRYLIC LETTERS WET COATED TO MATT GOLD",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/FRONT-ILLUMINATED-3D-LETTERS-ON-POWDER-COATED-ALUMINIUM-TRAY.jpg",
      title: "FRONT ILLUMINATED 3D LETTERS ON POWDER COATED ALUMINIUM TRAY",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/FRONT-ILLUMINATED-3D-LETTERS-WITH-FULL-COLOUR-PRINT-TO-FACE-+-MATCHING-SUSPENDED-LOZENGE.jpg",
      title:
        "FRONT ILLUMINATED 3D LETTERS WITH FULL COLOUR PRINT TO FACE + MATCHING SUSPENDED LOZENGE",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/HALO-ILLUMINATED-3D-LETTERS-WET-COATED-TO-MATT-BLACK-+-LIGHTBOX-PROJECTING-SIGNS.jpg",
      title:
        "HALO ILLUMINATED 3D LETTERS WET COATED TO MATT BLACK + LIGHTBOX PROJECTING SIGNS",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/MATT-METALLIC-GOLD-CUT-VINYL.jpg",
      title: "MATT METALLIC GOLD CUT VINYL",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/pushed-though-acrylic-letters-faced-with-vinyl.jpg",
      title: "Pushed Through Acrylic Letters Faced With Vinyl",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/halo-illuminated-3d-built-up-letters.jpg",
      title: "Halo Illuminated 3D Built Up Letters",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/aluminium-illuminated-sign-trays-with-acrylic-letters.jpg",
      title: "Aluminium Illuminated Sign Trays With Acrylic Letters",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/10mm-wet-coated-matt-gray-acrylic-letters.jpg",
      title: "10mm Wet Coated Matt Gray Acrylic Letters",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/full-colour-digitally-printed-window-graphics.jpg",
      title: "Full Colour Digitally Printed Window Graphics",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/5mm-black-acrylic-letters--clear-acrylic-plaque.jpg",
      title: "5mm Black Acrylic Letters & Clear Acrylic Plaque",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/3d-built-up-letters.jpg",
      title: "3D Built Up Letters",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/BevelCutStandOffLetterswithTroughLighting.jpg",
      title: "Bevel Cut Stand-Off Letters with Trough Lighting",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/CutFullColourVinyl.jpg",
      title: "Cut & Full Colour Vinyl",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/FlatCutAcrylic.jpg",
      title: "Flat Cut Acrylic",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/FlatCutAcrylicLetters.jpg",
      title: "Flat Cut Acrylic Letters",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/FlatCutAcrylicLettersCutVinyl.jpg",
      title: "Flat Cut Acrylic Letters & Cut Vinyl",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/FlatCutAcryliconAluminiumCompositeTray.jpg",
      title: "Flat Cut Acrylic on Aluminium Composite Tray",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/FlatCutLettersTroughLighting.jpg",
      title: "Flat Cut Letters & Trough Lighting",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/FlatCutVinylAluminiumCompositeTray.jpg",
      title: "Flat Cut Vinyl Aluminium Composite Tray",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/FullColourGraphics.jpg",
      title: "Full Colour Graphics",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/FullColourSwingProjectingSign.jpg",
      title: "Full Colour Swing Projecting Sign",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/PushedThroughAcrylicAluminiumCompositeTray.jpg",
      title: "Pushed-Through Acrylic Aluminium Composite Tray",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/PushedThroughAcrylicAluminiumCompositeTray2.jpg",
      title: "Pushed-Through Acrylic Aluminium Composite Tray",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/StainlessSteelAcrylicIlluminatedProjectingSign.jpg",
      title: "Stainless Steel & Acrylic Illuminated Projecting Sign",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/WET_COATED_ACRYLIC_LETTERS_FROSTING_AND_WINDOW_GRAPHICS.jpg",
      title: "WET COATED ACRYLIC LETTERS, FROSTING AND WINDOW GRAPHICS",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/ALUMINIUM_COMPOSITE_SIGN_TRAY_WITH_WET_COATED_ACRYLIC_LETTERS.jpg",
      title: "ALUMINIUM COMPOSITE SIGN TRAY WITH WET COATED ACRYLIC LETTERS",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/BLACK_TINT_APPLIED_TO_INSIDE_OF_GLASS__CUT_WHITE_VINYL_APPLIED_TO_OUTSIDE_OF_GLASS.jpg",
      title:
        "BLACK TINT APPLIED TO INSIDE OF GLASS & CUT WHITE VINYL APPLIED TO OUTSIDE OF GLASS",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/FRONT_ILLUMINATION_3D_LETTERS_&_FRET_CUT_LOGO.jpg",
      title: "FRONT ILLUMINATION 3D LETTERS & FRET CUT LOGO",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/FUL_COLOUR_PRINTED_&_WRAPPED_25MM_FOAM_PVC_SWING_PROJECTING_SIGN.jpg",
      title: "FUL COLOUR PRINTED & WRAPPED 25MM FOAM PVC SWING PROJECTING Sign",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/FULL_COLOUR_&_WHITE_CUT_VINYL_WINDOW_GRAPHICS.jpg",
      title: "FULL COLOUR & WHITE CUT VINYL WINDOW GRAPHICS",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/FULL_COLOUR_PRINTED_CONTOUR_CUT_WINDOW_GRAPHICS.jpg",
      title: "FULL COLOUR PRINTED CONTOUR CUT WINDOW GRAPHICS",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/PUSHED-THROUGH_ACRYLIC_FACED_IN_GOLD_VINYL_INTERNALLY_ILLUMINATED_PRINTED_SIGN_TRAY.jpg",
      title:
        "PUSHED-THROUGH ACRYLIC FACED IN GOLD VINYL INTERNALLY ILLUMINATED PRINTED SIGN TRAY",
    },
    {
      img: "/img/signselection/1-Shop-Retail-Signs/PUSHED-THROUGH_ACRYLIC_FACED_IN_GOLD_VINYL_INTERNALLY_ILLUMINATED_SIGN_TRAY__PROJECTING_SIGN.jpg",
      title:
        "PUSHED-THROUGH ACRYLIC FACED IN GOLD VINYL INTERNALLY ILLUMINATED SIGN TRAY + PROJECTING SIGN",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/PUSHED-THROUGH_ACRYLIC_FACED_IN_GOLD_VINYL_INTERNALLY_ILLUMINATED_SIGN_TRAY.jpg",
      title:
        "PUSHED-THROUGH ACRYLIC FACED IN GOLD VINYL INTERNALLY ILLUMINATED SIGN TRAY",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/PUSHED-THROUGH_ACRYLIC_INTERNALLY_ILLUMINATED_SIGN_TRAY_WITH_LETTERS_FACED_IN_VINYL.jpg",
      title:
        "PUSHED-THROUGH ACRYLIC INTERNALLY ILLUMINATED SIGN TRAY WITH LETTERS FACED IN VINYL",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/STOCK_BLUE_ALUMINIUM_COMPOSITE_SIGN_TRAY_WITH_PUSHED-THROUGH_ACRYLIC_LETTERS_&_MATCHING_PROJECTING_SIGN.jpg",
      title:
        "STOCK BLUE ALUMINIUM COMPOSITE SIGN TRAY WITH PUSHED-THROUGH ACRYLIC LETTERS & MATCHING PROJECTING SIGN",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/WET_COATED_GOLD_HALO_ILLUMINATED_3D_LETTERS.jpg",
      title: "WET COATED GOLD HALO ILLUMINATED 3D LETTERS",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/WET_COATED_ACRYLIC_LETTERS,_FROSTING_AND_WINDOW_GRAPHICS.jpg",
      title: "WET COATED ACRYLIC LETTERS, FROSTING AND WINDOW GRAPHICS",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/WHITE_ALUMINIUM_COMPOSITE_SIGN_TRAY_WITH_10MM_MATT_BLACK_ACRYLIC_LETTERS_APPLIED_DIRECTLY_TO_FACE.jpg",
      title:
        "WHITE ALUMINIUM COMPOSITE SIGN TRAY WITH 10MM MATT BLACK ACRYLIC LETTERS APPLIED DIRECTLY TO FACE",
    },
    {
      img: "/img/signsbysector/8-Shop-Retail/FRONT_ILLUMINATED_3D_BUILT-UP_LETTERS.jpg",
      title: "FRONT ILLUMINATED 3D BUILT-UP LETTERS",
    },
  ],
};

const barRestaurant = {
  name: "Bar & Restaurant",
  content:
    "Popular signage for bars and restaurants includes menus, fascia sign, window graphics and floor decals. Bar and restaurant signs help build the brand as well as serving as direction signage and pricelists for food and drink. We work with a large number of bars and restaurants, from small single premises outfits to large multi-site businesses.",
  footerContent: (
    <GalleryText>
      <p>
        Wherever we are, we all know the feeling of going out to a bar or
        restaurant. Putting on something nice to wear, meeting up with your
        friends for a bite to eat or a drink with co-workers and checking our
        bank balances the morning after with dread! But what was it that enticed
        you to that bar or restaurant in the first place? Was it the location?
        Menu? Or maybe the price? Well, whatever it was just sealed the deal,
        WEguarantee that you saw something that made that bar a winner. For
        business owners, you want your customers to feel this every time your
        shop is open to achieve success. Luckily, signage and graphics are one
        of the easiest ways that you can elevate your hospitality game and keep
        the drinks flowing.
      </p>

      <p>
        There&rsquo;s a wide variety of signage and graphics you can utilise in
        your hospitality business to create a united theme both internally and
        externally in your business. From stunning digitally printed menus to
        LED neon signs to draw in a quirky crowd. At Yellow Signs, we provide
        plenty of methods to shine a light on your business 24/7. One of our
        favourite ways to build your brand image and to direct your customers to
        your store is using window graphics which are customisable to your every
        whim. We typically use vinyl as a fantastic cost-cutting alternative to
        graphics and allow you a wide range of creative diversity. Signage is a
        great option to engage your customers and offer a competitive edge.
      </p>

      <p>
        We recognise that no two bars are alike; as such, we want to be able to
        offer you products that align with your brand image. Whether you are a
        well-known chain of bars or restaurants or a rustic and independent
        family-run business, rest assured we have endless choices for every
        brand identity. For the quirkier bunch, wild colours and designs to
        tailor to a new or current identity e.g. illuminated signage to floor
        decals. And what about the luxury market? Our built-up and facia signs
        are the perfect way to become iconographic at your business location to
        direct your customers to enjoy your food and drinks.
      </p>

      <p>
        To view, some of our past work, visit:{" "}
        <Link to="case-studies">https://yellowsigns.co.uk/case-studies</Link>
      </p>

      <p>
        Yellow Signs is a business firmly rooted in London so we know how
        diverse brands can be! We love to help new and old brands increase their
        brand visibility and customer profiles. We produce almost all of our
        products in-house, in our Battersea headquarters to provide your signage
        and graphics, especially for the hospitality industry, in London
        speedily; all so you can get back to what you do best!
      </p>

      <p>
        If you&rsquo;re interested in feeding your business &ndash; using
        graphics and signage &ndash; please contact one of our signage experts
        on 020 7350 1567 or email{" "}
        <a href="mailto:info@yellowsigns.co.uk">info@yellowsigns.co.uk</a>
      </p>

      <p>
        Alternatively, to request a quick quote visit{" "}
        <Link to="quote-site-visit">
          https://yellowsigns.co.uk/quote-site-visit
        </Link>{" "}
        to get started!
      </p>
    </GalleryText>
  ),
  href: "/bar-restaurant",
  gallery: [
    {
      img: "/img/signsbysector/1-Bar-Restaurant/wet-coated-matt-orange-10mm-acrylic-letters.jpg",
      title: "Wet Coated Matt Orange 10mm Acrylic Letters",
    },
    {
      img: "/img/signsbysector/1-Bar-Restaurant/wet-coated-matt-black-5mm-letters-on-stand-off-locators.jpg",
      title: "Wet Coated Matt Black 5mm Letters on Stand-Off Locators",
    },
    {
      img: "/img/signsbysector/1-Bar-Restaurant/pink-led-neon.jpg",
      title: "Pink LED Neon",
    },
    {
      img: "/img/signsbysector/1-Bar-Restaurant/full-colour-digitally-printed-wallpaper.jpg",
      title: "Full Colour Digitally Printed Wallpaper",
    },
    {
      img: "/img/signsbysector/1-Bar-Restaurant/full-colour-digitally-printed-lightbox-menus.jpg",
      title: "Full Colour Digitally Printed Lightbox Menus",
    },
    {
      img: "/img/signsbysector/1-Bar-Restaurant/green-led-neon.jpg",
      title: "Green LED Neon",
    },
    {
      img: "/img/signsbysector/1-Bar-Restaurant/fridge-vinyl-wrap.jpg",
      title: "Fridge Vinyl Wrap",
    },
    {
      img: "/img/signsbysector/1-Bar-Restaurant/aluminium-composite-sign-tray-trough-light--window-graphics.jpg",
      title: "Aluminium Composite Sign, Tray, Trough, Light & Window Graphics",
    },
    {
      img: "/img/signsbysector/1-Bar-Restaurant/cut-vinyl-letters-&-suspended-clear-acrylic-roundel.jpg",
      title: "Cut Vinyl Letters & Suspended Clear Acrylic Roundel",
    },
    {
      img: "/img/signsbysector/1-Bar-Restaurant/contour-cut-full-colour-digitall-printed-window-graphics.jpg",
      title: "Contour Cut Full Colour Digitally Printed Window Graphics",
    },
    {
      img: "/img/signsbysector/1-Bar-Restaurant/aluminium-composite-panel-faced-with-vinyl.jpg",
      title: "Aluminium Composite Panel Faced with Vinyl",
    },
    {
      img: "/img/signsbysector/1-Bar-Restaurant/5mm-acrylic-letters--full-colour-window-graphics.jpg",
      title: "5mm Acrylic Letters & Full Colour Window Graphics",
    },
    {
      img: "/img/signsbysector/1-Bar-Restaurant/3DBuiltUpLettersonTray.jpg",
      title: "3D Built-Up Letters on Tray",
    },
    {
      img: "/img/signsbysector/1-Bar-Restaurant/3DBuiltUpRimReturnFrontIlluminated.jpg",
      title: "3D Built-Up Rim & Return Front Illuminated",
    },
    {
      img: "/img/signsbysector/1-Bar-Restaurant/AcrylicBackedIlluminatedAluminiumCompositeTray.jpg",
      title: "Acrylic Backed Illuminated Aluminium Composite Tray",
    },
    {
      img: "/img/signsbysector/1-Bar-Restaurant/CNCRoutedWood.jpg",
      title: "CNC Routed Wood",
    },
    {
      img: "/img/signsbysector/1-Bar-Restaurant/CNCRoutedWoodStencilPainted.jpg",
      title: "CNC Routed Wood & Stencil Painted",
    },
    {
      img: "/img/signsbysector/1-Bar-Restaurant/CutVinylWrappedMDF.jpg",
      title: "Cut Vinyl Wrapped MDF",
    },
    {
      img: "/img/signsbysector/1-Bar-Restaurant/CutVinylWrappedMDF2.jpg",
      title: "Cut Vinyl Wrapped MDF",
    },
    {
      img: "/img/signsbysector/1-Bar-Restaurant/FlatAcrylicLettersonWood.jpg",
      title: "Flat Acrylic Letters on Wood",
    },
    {
      img: "/img/signsbysector/1-Bar-Restaurant/FlatCutSolidWood.jpg",
      title: "Flat Cut Solid Wood",
    },
    {
      img: "/img/signsbysector/1-Bar-Restaurant/FullColourWallpaper.jpg",
      title: "Full Colour Wallpaper",
    },
    {
      img: "/img/signsbysector/1-Bar-Restaurant/WET_COATED_ACRYLIC_LETTERS_AND_CUT_VINYL_WINDOW_GRAPHICS.jpg",
      title: "WET COATED ACRYLIC LETTERS AND CUT VINYL WINDOW GRAPHICS",
    },
    {
      img: "/img/signsbysector/1-Bar-Restaurant/TEMPORARY_FULL_COLOUR_PRINTED_VINYL.jpg",
      title: "TEMPORARY FULL COLOUR PRINTED VINYL",
    },
    {
      img: "/img/signsbysector/1-Bar-Restaurant/INTERNALLY_ILLUMINATED_LOCKABLE_LIGHTBOX.jpg",
      title: "INTERNALLY ILLUMINATED LOCKABLE LIGHTBOX",
    },
    {
      img: "/img/signsbysector/1-Bar-Restaurant/FULL_COLOUR_PRINTED_WRAP_VINYL.jpg",
      title: "FULL COLOUR PRINTED WRAP VINYL",
    },
    {
      img: "/img/signsbysector/1-Bar-Restaurant/WET_COATED_ACRYLIC_LETTERS_AND_CUT_VINYL_WINDOW_GRAPHICS.jpg",
      title: "WET COATED ACRYLIC LETTERS AND CUT VINYL WINDOW GRAPHICS",
    },
    {
      img: "/img/signsbysector/1-Bar-Restaurant/INTERNALLY_ILLUMINATED_OPAL_ACRYLIC_LIGHTBOX_PROJECTING_SIGN_WITH_WET_COATED_ACRYLIC_LOGO.jpg",
      title:
        "INTERNALLY ILLUMINATED OPAL ACRYLIC LIGHTBOX PROJECTING SIGN WITH WET COATED ACRYLIC LOGO",
    },
    {
      img: "/img/signsbysector/1-Bar-Restaurant/WHITE_ALUMINIUM_COMPOSITE_SIGN_TRAY_WITH_10MM_MATT_BLACK_ACRYLIC_LETTERS_APPLIED_DIRECTLY_TO_FACE.jpg",
      title:
        "WHITE ALUMINIUM COMPOSITE SIGN TRAY WITH 10MM MATT BLACK ACRYLIC LETTERS APPLIED DIRECTLY TO FACE",
    },
    {
      img: "/img/signsbysector/1-Bar-Restaurant/PUSHED-THROUGH_ACRYLIC_FACED_IN_GOLD_VINYL_INTERNALLY_ILLUMINATED_SIGN_TRAY.jpg",
      title:
        "PUSHED-THROUGH ACRYLIC FACED IN GOLD VINYL INTERNALLY ILLUMINATED SIGN TRAY",
    },
    {
      img: "/img/signsbysector/1-Bar-Restaurant/GOLD_WET_COATED_5MM_ACRYLIC_LETTERS_APPPLIED_DIRECTLY_TO_FASCIA.jpg",
      title: "GOLD WET COATED 5MM ACRYLIC LETTERS APPLIED DIRECTLY TO FASCIA",
    },
    {
      img: "/img/signsbysector/1-Bar-Restaurant/HIGH-TACK_WHITE_CUT_VINYL.jpg",
      title: "HIGH-TACK WHITE CUT VINYL",
    },
    {
      img: "/img/signsbysector/1-Bar-Restaurant/25MM_WET_COATED_GOLD_3D_LETTERS_WITH_PINK_HALO_ILLUMINATION.jpg",
      title: "25MM WET COATED GOLD 3D LETTERS WITH PINK HALO ILLUMINATION",
    },
    {
      img: "/img/signsbysector/1-Bar-Restaurant/25MM_WET_COATED_GOLD_3D_LETTERS_WITH_PINK_HALO__ILLUMINATION_-_WORKSHOP.jpg",
      title:
        "25MM WET COATED GOLD 3D LETTERS WITH PINK HALO ILLUMINATION - WORKSHOP",
    },
    {
      img: "/img/signsbysector/1-Bar-Restaurant/FAUX_LED_NEON.jpg",
      title: "FAUX LED NEON",
    },
    {
      img: "/img/signsbysector/1-Bar-Restaurant/POWER_COATED_ALUMINIUM_TRAY_&_TROUGH_LIGHT_WITH_BRUSHED_GOLD_LETTERS.jpg",
      title:
        "POWDER COATED ALUMINIUM TRAY & TROUGH LIGHT WITH BRUSHED GOLD LETTERS",
    },
  ],
};

const estateProperty = {
  name: "Estate agent & Property",
  href: "/estate-agent-property",
  gallery: [
    {
      img: "/img/signsbysector/3-Estate-Agent-Property/illuminated-aluminium-pushed-through-acrylic-sign-tray.jpg",
      title: "Illuminated Aluminium Pushed Through Acrylic Sign Tray",
    },
    {
      img: "/img/signsbysector/3-Estate-Agent-Property/full-colour-digitally-printed-window-graphics-1.jpg",
      title: "Full Colour Digitally Printed Window Graphics",
    },
    {
      img: "/img/signsbysector/3-Estate-Agent-Property/full-colour-digitally-printed-window-graphics-2.jpg",
      title: "Full Colour Digitally Printed Window Graphics",
    },
    {
      img: "/img/signsbysector/3-Estate-Agent-Property/AcrylicBackedAluminiumCompositeTray.jpg",
      title: "Acrylic Backed Aluminium Composite Tray",
    },
    {
      img: "/img/signsbysector/3-Estate-Agent-Property/CutVinylCarGraphics.jpg",
      title: "Cut Vinyl Car Graphics",
    },
    {
      img: "/img/signsbysector/3-Estate-Agent-Property/CutVinylFacedAluminiumCompositeTray.jpg",
      title: "Cut Vinyl Faced Aluminium Composite Tray",
    },
    {
      img: "/img/signsbysector/3-Estate-Agent-Property/CutVinylFullColourCarGraphics.jpg",
      title: "Cut Vinyl & Full Colour Car Graphics",
    },
    {
      img: "/img/signsbysector/3-Estate-Agent-Property/CutVinylStainlessSteelPlaque.jpg",
      title: "Cut Vinyl Stainless Steel Plaque",
    },
    {
      img: "/img/signsbysector/3-Estate-Agent-Property/FlatCutAcrylicBrushedStainlessSteelLetters.jpg",
      title: "Flat Cut Acrylic & Brushed Stainless Steel Letters",
    },
    {
      img: "/img/signsbysector/3-Estate-Agent-Property/FlatCutAcrylicLettersonAluminiumTray.jpg",
      title: "Flat Cut Acrylic Letters on Aluminium Tray",
    },
    {
      img: "/img/signsbysector/3-Estate-Agent-Property/FlatCutBlueBrushedStainlessSteelLetters.jpg",
      title: "Flat Cut Blue Brushed Stainless Steel Letters",
    },
    {
      img: "/img/signsbysector/3-Estate-Agent-Property/FlushAcrylicAluminiumTray.jpg",
      title: "Flush Acrylic Aluminium Tray",
    },
    {
      img: "/img/signsbysector/3-Estate-Agent-Property/FullColourCarWrap.jpg",
      title: "Full Colour Car Wrap",
    },
    {
      img: "/img/signsbysector/3-Estate-Agent-Property/PartialWrapCarGraphics.jpg",
      title: "Partial Wrap Car Graphics",
    },
    {
      img: "/img/signsbysector/3-Estate-Agent-Property/PushedThroughAcrylicAluminiumCompositeTray.jpg",
      title: "Pushed-Through Acrylic Aluminium Composite Tray",
    },
  ],
};

const gym = {
  name: "Gyms & Fitness",
  href: "/gyms-fitness",
  content:
    "Gyms and fitness studios often require a wide range of internal and external signage, including floor decals, wayfinding (directional signage) for lockers, toilets and changing rooms, as well as window graphics and shop fascia signs.",
  footerContent: (
    <GalleryText>
      <p>
        On the return from a jet-setting holiday, filled with a cacophony of
        different sweets and treats to delve into, packing on those holiday
        pounds becomes a growing concern for us all! Maybe you start a diet or
        perhaps you&rsquo;re enjoying that holiday weight. For those of us
        trying to rid ourselves of those pesky pounds, often we pop into the
        gym. There are a variety of things that make a good gym and one of them
        thinks of clarity and convenience. With lots of machinery and heavy
        weights to keep you in shape, a gym, first and foremost, should look out
        for the health and safety of its members.
      </p>

      <p>
        So, what kind of gym and fitness business signage can we offer you? If
        you&rsquo;re looking for informative signage look no further than floor
        decals which are the perfect directional tool to point your members in
        the direction of the appropriate equipment or sanitising stations for
        our post covid safety rituals! But signage is never just limited to the
        mundane health and safety features. Gyms are full of people that may
        need that added motivation to keep them going. After all, for some that
        first step to the gym is a big deal and keeping them in your fitness
        space will give you a lot of benefits financially! Window graphics and
        full-colour vinyl graphics are the optimal options to add some
        inspirational quotes or helpful tips to keep them going. Full-colour
        vinyl can be customised in a variety of colours, sizes and shapes to
        articulate whatever brand image you wish to convey.
      </p>

      <p>
        We know that gyms act as sanctuaries for many people. A stress reliever
        after a stressful day at work, a place to look sweaty and not be judged
        or even a space to work on self-improvement. Here at Yellow Signs, we
        believe that some moments should be private and safe spaces should be
        created for these people! That&rsquo;s why privacy frosting and graphics
        are a perfect way to create an enclosed, non-judgemental space
        concerning your guest's privacy. It can be customised from full frosting
        to partial frosting to choose how much you want to show and maintain
        plenty of light.
      </p>

      <p>
        To view, some of our past work, visit:{" "}
        <Link to="case-studies">https://yellowsigns.co.uk/case-studies</Link>
      </p>

      <p>
        Yellow Signs is a business built in London for London. With many gym
        members working in the city, we know the demand for gyms is high and as
        such, we want to provide you with signage and graphics in London quickly
        and efficiently so you can get up and running and beat the competition.
        This is why we produce almost everything we do in our Battersea
        production house to supply your signage and graphics asap. If you need
        help with something bespoke or even just need some design help, feel
        free to contact our team who will be more than happy to help you out.
      </p>

      <p>
        If you&rsquo;re interested in decking out your fitness space &ndash;
        using graphics and signage &ndash; please contact one of our signage
        experts at 020 7350 1567 or email{" "}
        <a href="mailto:info@yellowsigns.co.uk">info@yellowsigns.co.uk</a>
      </p>

      <p>
        Alternatively, to request a quick quote visit{" "}
        <Link to="quote-site-visit">
          https://yellowsigns.co.uk/quote-site-visit
        </Link>{" "}
        to get started!
      </p>
    </GalleryText>
  ),
  gallery: [
    {
      img: "/img/signsbysector/5-Gyms-Fitness/wet-coated-acrylic-letters-on-stand-off-locators.jpg",
      title: "Wet Coated Acrylic Letters on Stand-Off Locators",
    },
    {
      img: "/img/signsbysector/5-Gyms-Fitness/aluminium-composite-sign-trays--window-graphics.jpg",
      title: "Aluminium Composite Sign Trays & Window Graphics",
    },
    {
      img: "/img/signsbysector/5-Gyms-Fitness/AcrylicBackedAluminiumCompositeTray.jpg",
      title: "Acrylic Backed Aluminium Composite Tray",
    },
    {
      img: "/img/signsbysector/5-Gyms-Fitness/AcrylicBackedIlluminatedAluminiumCompositeTray.jpg",
      title: "Acrylic Backed Illuminated Aluminium Composite Tray",
    },
    {
      img: "/img/signsbysector/5-Gyms-Fitness/AluminiumProjectingLightBox.jpg",
      title: "Aluminium Projecting Light Box",
    },
    {
      img: "/img/signsbysector/5-Gyms-Fitness/CutVinylPrivacyFrostedVinyl.jpg",
      title: "Cut Vinyl & Privacy Frosted Vinyl",
    },
    {
      img: "/img/signsbysector/5-Gyms-Fitness/FloorDecals.jpg",
      title: "Floor Decals",
    },
    {
      img: "/img/signsbysector/5-Gyms-Fitness/FullColourGraphicsCutVinyl.jpg",
      title: "Full Colour Graphics & Cut Vinyl",
    },
    {
      img: "/img/signsbysector/5-Gyms-Fitness/FullColourWindowGraphics.jpg",
      title: "Full Colour Window Graphics",
    },
    {
      img: "/img/signsbysector/5-Gyms-Fitness/FRONT_ILLUMINATION_3D_LETTERS_&_FRET_CUT_LOGO.jpg",
      title: "Front Illumination 3D Letters & Fret Cut Logo",
    },
    {
      img: "/img/signsbysector/5-Gyms-Fitness/FULL_COLOUR_PRINTED_&_WHITE_CUT_HIGH-TACK_VINYL_GRAPHICS.jpg",
      title: "Full Colour Printed & White Cut High-Tack Vinyl Graphics",
    },
    {
      img: "/img/signsbysector/5-Gyms-Fitness/WET_COATED_ACRYLIC_LETTERS,_FROSTING_AND_WINDOW_GRAPHICS.jpg",
      title: "Wet Coated Acrylic Letters, Frosting and Window Graphics",
    },
    {
      img: "/img/signsbysector/5-Gyms-Fitness/FULL_COLOUR_PRINTED_HIGH-TACK_VINYL.jpg",
      title: "Full Colour Printed High-Tack Vinyl",
    },
    {
      img: "/img/signsbysector/5-Gyms-Fitness/BLACK_&_WHITE_HIGH-TACK_VINYL_GRAPHICS.jpg",
      title: "Black & White High-Tack Vinyl Graphics",
    },
    {
      img: "/img/signsbysector/5-Gyms-Fitness/HIGH-TACK_BLACK_CUT_VINYL.jpg",
      title: "High-Tack Black Cut Vinyl",
    },
  ],
};
const education = {
  name: "School & Education",
  href: "/school-education",
  gallery: [
    {
      img: "/img/signsbysector/7-School-Education/full-colour-digitally-printed-vinyl.jpg",
      title: "Full Colour Digitally Printed Vinyl",
    },
    {
      img: "/img/signsbysector/7-School-Education/AluminiumCompositeCutVinylSign.jpg",
      title: "Aluminium Composite Cut Vinyl Sign",
    },
    {
      img: "/img/signsbysector/7-School-Education/AluminiumPostSigns.jpg",
      title: "Aluminium Post Signs",
    },
    {
      img: "/img/signsbysector/7-School-Education/AluminiumPostSigns2.jpg",
      title: "Aluminium Post Signs",
    },
    {
      img: "/img/signsbysector/7-School-Education/BuiltUpStainlessSteelPostSign.jpg",
      title: "Built-Up Stainless Steel Post Sign",
    },
    {
      img: "/img/signsbysector/7-School-Education/FullColourAluminiumCompositeGraphics.jpg",
      title: "Full Colour Aluminium Composite Graphics",
    },
    {
      img: "/img/signsbysector/7-School-Education/StainlessSteelFaceLettersonTrayPostSign.jpg",
      title: "Stainless Steel Face Letters on Tray Post Sign",
    },
    {
      img: "/img/signsbysector/7-School-Education/ALUMINIUM_COMPOSITE_SIGN_TRAYS_WITH_FULL_COLOUR_PRINT.jpg",
      title: "Aluminium Composite Sign Trays with Full Colour Print",
    },
    {
      img: "/img/signsbysector/7-School-Education/FULL_COLOUR_PVC_BANNER_SUSPENSION_SYSTEMS.jpg",
      title: "Full Colour PVC Banner Suspension Systems",
    },
  ],
};
const building = {
  name: "Building & Construction",
  href: "/build-construction",
  gallery: [
    {
      img: "/img/signsbysector/2-Building-Construction/white-5mm-acrylic-letters.jpg",
      title: "White 5mm Acrylic Letters",
    },
    {
      img: "/img/signsbysector/2-Building-Construction/AcrylicBackedAluminiumCompositeTray.jpg",
      title: "Acrylic Backed Aluminium Composite Tray",
    },
    {
      img: "/img/signsbysector/2-Building-Construction/CutFullColourVinylVehicleGraphics.jpg",
      title: "Cut & Full Colour Vinyl Vehicle Graphics",
    },
    {
      img: "/img/signsbysector/2-Building-Construction/CutVinyl.jpg",
      title: "Cut Vinyl",
    },
    {
      img: "/img/signsbysector/2-Building-Construction/CutVinylAluminiumCompositeHoarding.jpg",
      title: "Cut Vinyl Aluminium Composite Hoarding",
    },
    {
      img: "/img/signsbysector/2-Building-Construction/CutVinylJCBGraphics.jpg",
      title: "Cut Vinyl JCB Graphics",
    },
    {
      img: "/img/signsbysector/2-Building-Construction/FlatCutVinylTruckGraphics.jpg",
      title: "Flat Cut Vinyl Truck Graphics",
    },
    {
      img: "/img/signsbysector/2-Building-Construction/FullColourVehicleGraphics.jpg",
      title: "Full Colour Vehicle Graphics",
    },
    {
      img: "/img/signsbysector/2-Building-Construction/PVCBanner.jpg",
      title: "PVC Banner",
    },
    {
      img: "/img/signsbysector/2-Building-Construction/VehicleGraphics3.jpg",
      title: "3D Built-Up Letters on Tray",
    },
    {
      img: "/img/signsbysector/2-Building-Construction/VEHICLE_GRAPHICS_AND_CHEVRONS.jpg",
      title: "Vehicle Graphics and Chevrons",
    },
    {
      img: "/img/signsbysector/2-Building-Construction/BLACK_CUT_VINYL_VEHICLE_GRAPHICS.jpg",
      title: "Black Cut Vinyl Vehicle Graphics",
    },
  ],
};
const exhibition = {
  name: "Exhibition & Event",
  href: "/exhibition-event",
  gallery: [
    {
      img: "/img/signsbysector/4-Exhibition-Event/cut-vinyl-van-graphics.jpg",
      title: "Cut Vinyl Car Graphics",
    },
    {
      img: "/img/signsbysector/4-Exhibition-Event/CoatedPolystyrene.jpg",
      title: "Coated Polystyrene",
    },
    {
      img: "/img/signsbysector/4-Exhibition-Event/FullColourGraphics.jpg",
      title: "Full Colour Graphics",
    },
    {
      img: "/img/signsbysector/4-Exhibition-Event/FullColourVinylFoamPVC.jpg",
      title: "Full Colour Vinyl & Foam PVC",
    },
    {
      img: "/img/signsbysector/4-Exhibition-Event/FullColourWallpaper.jpg",
      title: "Full Colour Wallpaper",
    },
    {
      img: "/img/signsbysector/4-Exhibition-Event/LightBoxGraphics.jpg",
      title: "Light Box Graphics",
    },
    {
      img: "/img/signsbysector/4-Exhibition-Event/PVCBanners.jpg",
      title: "PVC Banners",
    },
    {
      img: "/img/signsbysector/4-Exhibition-Event/WoodFramedFullColourGraphics.jpg",
      title: "Wood Framed Full Colour Graphics",
    },
    {
      img: "/img/signsbysector/4-Exhibition-Event/ALUMINIUM_COMPOSITE_ENTRANCE_SIGN_DROP.jpg",
      title: "Aluminium Composite Entrance Sign Drop",
    },
  ],
};

const office = {
  name: "Office & Corporate",
  href: "/office-corporate",
  gallery: [
    {
      img: "/img/signsbysector/6-Office-Corporate/wet-coated-matt-black-5mm-acrylic-letters.jpg",
      title: "Wet Coated Matt Black 5mm Acrylic Letters",
    },
    {
      img: "/img/signsbysector/6-Office-Corporate/cut-to-shape-frosted-vinyl--cut-orange-vinyl.jpg",
      title: "Cut to Shape Frosted Vinyl + Cut Orange Vinyl",
    },
    {
      img: "/img/signsbysector/6-Office-Corporate/wet-coated-5mm-acrylic-letters-on-stand-off-locators.jpg",
      title: "Wet Coated 5mm Acrylic Letters on Stand-Off Locators",
    },
    {
      img: "/img/signsbysector/6-Office-Corporate/cut-vinyl-window-graphics.jpg",
      title: "Cut Vinyl Window Graphics",
    },
    {
      img: "/img/signsbysector/6-Office-Corporate/3DBuiltupIlluminatedLetters.jpg",
      title: "3D Built-up Illuminated Letters",
    },
    {
      img: "/img/signsbysector/6-Office-Corporate/BuiltUpPolishedStainlessSteelLetters.jpg",
      title: "Built-Up Polished Stainless Steel Letters",
    },
    {
      img: "/img/signsbysector/6-Office-Corporate/CutVinyl.jpg",
      title: "Cut Vinyl",
    },
    {
      img: "/img/signsbysector/6-Office-Corporate/CutVinylFrostedVinylPlaques.jpg",
      title: "Cut Vinyl & Frosted Vinyl Plaques",
    },
    {
      img: "/img/signsbysector/6-Office-Corporate/FrostedVinyl.jpg",
      title: "Frosted Vinyl",
    },
    {
      img: "/img/signsbysector/6-Office-Corporate/FullColourVinylCutVinyl.jpg",
      title: "Full Colour Vinyl & Cut Vinyl",
    },
    {
      img: "/img/signsbysector/6-Office-Corporate/FullColourWallpaper.jpg",
      title: "Full Colour Wallpaper",
    },
    {
      img: "/img/signsbysector/6-Office-Corporate/FullColourWallpaper2.jpg",
      title: "Full Colour Wallpaper",
    },
    {
      img: "/img/signsbysector/6-Office-Corporate/FullColourWallpaper3.jpg",
      title: "Full Colour Wallpaper",
    },
    {
      img: "/img/signsbysector/6-Office-Corporate/FullColourWallpaperPanels.jpg",
      title: "Full Colour Wallpaper Panels",
    },
    {
      img: "/img/signsbysector/6-Office-Corporate/ReverseCutFrostedVinyl.jpg",
      title: "Reverse Cut Frosted Vinyl",
    },
    {
      img: "/img/signsbysector/6-Office-Corporate/WEAT_COATED_AND_FULL_COLOUR_PRINTED_CUT_ACRYLIC.jpg",
      title: "Wet Coated and Full Colour Printed Cut Acrylic",
    },
    {
      img: "/img/signsbysector/6-Office-Corporate/WET_COATED_10MM_ACRYLIC_LETTERS_ON_STAND-OFF_LOCATORS.jpg",
      title: "Wet Coated 10mm Acrylic Letters on Stand-Off Locators",
    },
    {
      img: "/img/signsbysector/6-Office-Corporate/FULL_COLOUR_PRINTED_HIGH-TACK_VINYL_GRAPHICS.jpg",
      title: "Full Colour Printed High-Tack Vinyl Graphics",
    },
    {
      img: "/img/signsbysector/6-Office-Corporate/16MM_SILICON_EDGE_FULL_COLOUR_PRINTED_GRAPHICS.jpg",
      title: "16mm Silicon Edge Full Colour Printed Graphics",
    },
    {
      img: "/img/signsbysector/6-Office-Corporate/TEAL_CUT_VINYL_WINDOW_GRAPHICS.jpg",
      title: "Teal Cut Vinyl Window Graphics",
    },
    {
      img: "/img/signsbysector/6-Office-Corporate/FULL_COLOUR_PRINTED_WALLPAPER_VINYL.jpg",
      title: "Full Colour Printed Wallpaper Vinyl",
    },
    {
      img: "/img/signsbysector/6-Office-Corporate/FULL_COLOUR_PRINTED_VINYL_MANIFESTATIONS.jpg",
      title: "Full Colour Printed Vinyl Manifestations",
    },
    {
      img: "/img/signsbysector/6-Office-Corporate/CUT_PRIVACY_FROSTED_VINYL.jpg",
      title: "Cut Privacy Frosted Vinyl",
    },
    {
      img: "/img/signsbysector/6-Office-Corporate/WET_COATED_10MM_ACRYLIC_LETTERS_WITH_FULL_COLOUR_PRINTED_VINYL_TO_FACE.jpg",
      title:
        "Wet Coated 10mm Acrylic Letters with Full Colour Printed Vinyl to Face",
    },
  ],
};

const locationsGallery = [
  shopsRetailSigns,
  barRestaurant,
  //   estateProperty,
  gym,
  //   education,
  //   building,
  //   exhibition,
  //   office,
  threeDBuildUpLetters,
  wayfindingPlaques,
  privacyFrosting,
  vehicleGraphics,
  projectSigns,
  neonSigns,
  hoarding,
  cut,
  fullColour,
  flatCutLetters,
  digitalWallpaper,
  troughLight,
  bespokeFabrication,
  digitalLithoPrint,
  tradeSignage,
  buildConstruction,
  estateAgencyProperty,
  exhibitionEvent,
  officeCorporate,
  schoolEducation,
  shopsRetail,
];

export default locationsGallery;
