import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import device from "../common/device";
import HTMLReactParser from "html-react-parser";

const Wrapper = styled.section`
  padding: 80px 0;
  background-color: #e6f7f6;

  @media ${device.lg} {
    padding: 64px 0;
  }
`;
const SliderWrapper = styled.div`
  width: 50%;

  button {
    transform: none;
    padding: 0 !important;
  }

  button,
  button::before,
  li {
    width: 8px !important;
    height: 8px !important;
  }

  button::before {
    color: #b5e7e4 !important;
    opacity: 1 !important;
  }

  .slick-active button::before {
    color: var(--ys-teal) !important;
  }

  li {
    margin: 0 8px !important;
  }

  .slick-dots {
    bottom: -35px;
  }

  @media ${device.lg} {
    width: 100%;
  }
`;
const Flex = styled.div`
  display: flex;
  gap: 60px 30px;
  justify-content: space-between;
  align-items: center;

  @media ${device.lg} {
    flex-direction: column;
    align-items: normal;
  }
`;
const Content = styled.div`
  width: 42.8%;

  @media ${device.lg} {
    width: 100%;
  }

  a {
    color: var(--ys-teal) !important;
  }
`;
const Title = styled.h2`
  font-family: var(--font-secondary);
  color: var(--ys-teal);
  margin-bottom: 32px;
  font-size: 48px;
  line-height: 1;
`;
const Description = styled.p`
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 12px;
  letter-spacing: -0.01em;

  &:first-of-type {
    font-weight: 800;
  }
  a {
    font-weight: 700;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
`;
const LinkWrapper = styled.ul`
  margin: 24px 0;
  display: flex;
  gap: 12px 56px;
  padding-left: 0em;
  color: var(--ys-teal);
  flex-wrap: wrap;

  li {
    list-style: none;
  }

  a {
    font-weight: 800;
    font-size: 16px !important;
    color: var(--ys-teal) !important;
    white-space: nowrap;
    line-height: 22px;
  }
`;
const Slide = styled.div`
  aspect-ratio: 540/382;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Showcase = ({ showcase }) => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  return (
    <Wrapper>
      <Container>
        <Flex>
          <SliderWrapper>
            <Slider {...settings}>
              {showcase?.images?.map((image, idx) => (
                <Slide key={idx}>
                  <img src={image} alt={`thumbanil-${idx}`} />
                </Slide>
              ))}
            </Slider>
          </SliderWrapper>

          <Content>
            <Title>{showcase?.title}</Title>
            <Description>{showcase?.subtitle}</Description>
            <Description>
              {HTMLReactParser(showcase?.description || "")}
            </Description>
            {/* <LinkWrapper>
              {showcase?.jobs?.map((job) => (
                <Link key={job?.label} to={job?.href}>
                  {job?.label}
                </Link>
              ))}
            </LinkWrapper> */}
            <Description>
              {HTMLReactParser(showcase?.bottomTitle || "")}
            </Description>
          </Content>
        </Flex>
      </Container>
    </Wrapper>
  );
};

export default Showcase;
