import React, { Component } from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import Button from "../common/Button";
import device from "../common/device";

const Wrapper = styled.div`
  background-image: url(/img/locations/locations-banner-bg.png);
  background-size: cover;
  padding: 64px 0 89px;

  p {
    font-weight: 400;
  }
`;

const Image = styled.img`
  width: 100%;
  padding-top: 16px;
`;

const Heading = styled.h1`
  font-family: "Alternate";
  font-size: 64px;
  color: var(--ys-teal);
  margin-bottom: 16px;
  line-height: 1;
`;

const Description = styled.div`
  margin-bottom: 32px;

  p {
    font-size: 15px;
    margin-bottom: 4px;
    color: var(--ys-white);
  }
`;

const Flex = styled.div`
  display: grid;
  gap: 20px;
  column-gap: 8.333%;
  grid-template-columns: 1.3fr 1fr;

  @media ${device.lg} {
    grid-template-columns: 1fr 1fr;
    column-gap: 5.333%;
  }

  @media ${device.md} {
    grid-template-columns: 1fr;
  }
`;

const AsWeCoverLogo = styled.div`
  display: flex;
  justify-content: end;
  img {
    width: auto;
    height: 300px;
  }
  @media ${device.sm} {
    justify-content: center;
    img {
      width: auto;
      height: 200px;
    }
  }
`;

const LocationBanner = () => {
  return (
    <Wrapper>
      <Container>
        <Flex>
          <div>
            <Heading>Areas we cover</Heading>
            <Description>
              <p>
                We’re based in Battersea and work with a wide range of clients
                in and around London, but we also supply clients around the rest
                of the UK. We have our own in-house production team and
                installation team to ensure we cover your area with our own team
                of signage and graphics experts.
              </p>
            </Description>

            <Button href="/contact-us">Get In Touch</Button>
          </div>

          <AsWeCoverLogo>
            <img src="/img/banners/locations-banner-logos.png" />
          </AsWeCoverLogo>
        </Flex>
      </Container>
    </Wrapper>
  );
};

export default LocationBanner;
