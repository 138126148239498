import React from "react";
import { useState } from "react";
import { Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import locationsGallery from "../../data/locationsGallery";
import { useEffect } from "react";
import ServicesGalleryImg from "../services/ServicesGalleryImg";
import device from "../common/device";

const FADE_DURATION = 200;

const Wrapper = styled.section`
  .location-block {
    padding: 64px 0 80px;
    display: flex;
    flex-direction: column;
    row-gap: 64px;
  }
  .location-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
    align-items: center;
  }
  button.location {
    padding: 5px 24px;
    color: var(--ys-black);
    font-size: 16px;
    border: none;
    background-color: rgba(7, 7, 7, 0.1);
    border-radius: 50px;
    transition: background-color 0.3s;
    pointer-events: auto;
    line-height: 22px;
    &:hover {
      background-color: var(--ys-yellow-lt);
    }

    &.active {
      background-color: var(--ys-yellow);
    }
  }

  a.location-link {
    display: flex;
    column-gap: 10px;
    padding: 16px 24px;
    font-weight: 500;
    font-family: var(--font-secondary);
    border-radius: 5px;
    border: 1px solid var(--ys-black);
    font-size: 24px;
    line-height: 1;
    width: fit-content;
    margin-inline: auto;
    margin-top: 64px;

    img {
      transition: all 0.3s;
    }

    &:hover {
      img {
        transform: translateX(5px);
      }
    }
  }

  .hidden {
    display: none;
  }

  /* Keyframes for fading in */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Keyframes for fading out */
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  /* CSS class for handling the fade in */
  .fade-in {
    animation: fadeIn ${FADE_DURATION}ms forwards; /* Change '1s' to the duration you want */
  }

  /* CSS class for handling the fade out */
  .fade-out {
    animation: fadeOut ${FADE_DURATION}ms forwards; /* Change '1s' to the duration you want */
  }

  @media ${device.md} {
    .location-buttons {
      flex-wrap: nowrap;
      justify-content: flex-start;
      overflow: auto;
      scrollbar-width: none;
    }

    a.location-link {
      margin-top: 32px;
    }
  }
`;

const LocationGallery = () => {
  const [activeId, setActiveId] = useState(0);
  const [animation, setAnimation] = useState("");

  useEffect(() => {
    // Set the animation to fade in whenever the activeTab changes
    setAnimation("fade-in");
  }, [activeId]);

  const changeTab = (id) => {
    if (id !== activeId) {
      // Start fade out animation
      setAnimation("fade-out");

      // Change tab after fade out completes
      setTimeout(() => {
        setActiveId(id);
      }, FADE_DURATION); // This duration should match the CSS animation duration
    }
  };
  return (
    <Wrapper>
      <Container>
        <div className="location-block">
          <div className="location-buttons">
            {locationsGallery.map((location, index) => {
              const { name } = location || {};
              const isActive = activeId === index;
              return (
                <button
                  onClick={() => changeTab(index)}
                  key={index}
                  type="button"
                  className={`location text-nowrap ${isActive ? "active" : ""}`}
                  aria-label={`Choose ${name}`}
                >
                  {name}
                </button>
              );
            })}
          </div>
          <div
            className={`location-gallery ${animation}`}
            onAnimationEnd={() => {
              setAnimation("");
            }}
          >
            {locationsGallery.map(({ gallery }, locationIndex) => {
              const isActive = locationIndex === activeId;
              return (
                <Row
                  key={locationIndex}
                  className={isActive ? "gallery-images" : "hidden"}
                >
                  {(gallery || []).slice(0, 12).map(({ img, title }, index) => {
                    return (
                      <ServicesGalleryImg key={index} img={img} title={title} />
                    );
                  })}
                </Row>
              );
            })}

            <Link
              className="location-link"
              to={locationsGallery[activeId].href}
            >
              <span> More {locationsGallery[activeId].name} Signs</span>
              <img src="/icons/arrow-right.svg" alt="arrow right" />
            </Link>
          </div>
        </div>
      </Container>
    </Wrapper>
  );
};

export default LocationGallery;
