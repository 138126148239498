import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

import FontFaceObserver from "fontfaceobserver";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "G-9CEB09VPMT",
};

TagManager.initialize(tagManagerArgs);

// To avoid FOUT the page is rendered only when custom fonts are downloaded
const fontAlternate = new FontFaceObserver("Alternate");
const fontAvenir = new FontFaceObserver("Avenir");

/**
 * Render App
 */

// ReactDOM.render(
//   <React.StrictMode>
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>
//   </React.StrictMode>,
//   document.getElementById("root")
// );

Promise.all([fontAlternate.load(), fontAvenir.load()]).then(() => {
  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("root")
  );
});
