import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import device from "../common/device";
import locations from "../../data/locations";

const Wrapper = styled.div`
  padding-top: 80px;
  padding-bottom: 60px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 30px;

  @media ${device.lg} {
    grid-template-columns: 1fr;
  }
`;

const Content = styled.div`
  grid-column: span 5;

  @media ${device.lg} {
    grid-column: span 1;
  }

  h2 {
    font-family: var(--font-secondary);
    font-weight: 500;
    font-size: 48px;
    margin-bottom: 24px;
    line-height: 1;
  }

  p {
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 24px;
    opacity: 0.7;
  }

  .list {
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;

    @media ${device.lg} {
      columns: 2;
      column-gap: 16px;
    }
    @media ${device.md} {
      columns: 1;
    }
    li:last-of-type {
      a {
        margin-bottom: 0;
      }
    }
    a {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
      font-size: 16px;
      padding-bottom: 9px;
      border-bottom: 0.5px solid rgba(8, 8, 8, 0.3);

      span,
      svg {
        transition: color 0.3s;
      }

      &:hover {
        span {
          color: var(--ys-teal);
          font-weight: 800;
        }

        svg {
          color: var(--ys-teal);
        }

        border-color: var(--ys-teal);
      }
    }
  }
`;
const Image = styled.div`
  grid-column: 7 / span 6;
  padding-top: 72px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media ${device.lg} {
    padding-top: 0px;
    aspect-ratio: 16/9;
    grid-column: span 1;
  }
`;

const LocationList = () => {
  return (
    <Wrapper>
      <Container>
        <Grid>
          <Content>
            <h2>List of areas</h2>
            <p>
              We supply and install signage and graphics all over London and
              South East with a heavy focus on our neighbouring London boroughs
              including West London, South-West London, Central London, North
              London and North-West London, as well as the City of London and
              most of East London.
            </p>
            <p>
              Here are a list of the areas we work in most but if you don’t see
              your area listed don’t worry – there’s a good chance we can help!{" "}
            </p>
            <ul className="list">
              {locations?.map((location, index) => {
                return (
                  <li key={index}>
                    <Link to={location.href}>
                      <span>{location.label}</span>
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M20.5299 11.4699C20.6704 11.6105 20.7493 11.8012 20.7493 11.9999C20.7493 12.1987 20.6704 12.3893 20.5299 12.5299L14.5299 18.5299C14.4613 18.6036 14.3785 18.6627 14.2865 18.7037C14.1945 18.7447 14.0952 18.7667 13.9944 18.7685C13.8937 18.7703 13.7937 18.7518 13.7003 18.714C13.6069 18.6763 13.5221 18.6202 13.4509 18.5489C13.3797 18.4777 13.3235 18.3929 13.2858 18.2995C13.2481 18.2061 13.2296 18.1061 13.2313 18.0054C13.2331 17.9047 13.2552 17.8054 13.2961 17.7134C13.3371 17.6214 13.3962 17.5386 13.4699 17.4699L18.1899 12.7499L3.99993 12.7499C3.80102 12.7499 3.61025 12.6709 3.4696 12.5302C3.32894 12.3896 3.24993 12.1988 3.24993 11.9999C3.24993 11.801 3.32894 11.6102 3.4696 11.4696C3.61025 11.3289 3.80102 11.2499 3.99993 11.2499L18.1899 11.2499L13.4699 6.52991C13.3962 6.46125 13.3371 6.37845 13.2961 6.28645C13.2552 6.19445 13.2331 6.09513 13.2313 5.99443C13.2296 5.89373 13.2481 5.7937 13.2858 5.70031C13.3235 5.60692 13.3797 5.52209 13.4509 5.45087C13.5221 5.37965 13.6069 5.32351 13.7003 5.28579C13.7937 5.24807 13.8937 5.22954 13.9944 5.23132C14.0952 5.23309 14.1945 5.25514 14.2865 5.29613C14.3785 5.33712 14.4613 5.39622 14.5299 5.46991L20.5299 11.4699Z"
                          fill="currentColor"
                        />
                      </svg>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </Content>

          <Image>
            <img src="/img/locations/map.png" alt="map" />
          </Image>
        </Grid>
      </Container>
    </Wrapper>
  );
};

export default LocationList;
