import React from 'react';
import {Container} from 'react-bootstrap';
import styled from 'styled-components';
import Button from '../common/Button';
import device from '../common/device';

const tags = [
    'Alluminium',
    'Acrylic',
    'PVC',
    'Vinyl',
    'Vinyl',
    'Alluminium',
    'PVC',
];

const Title = styled.h2`
    font-family: var(--font-secondary);
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1;
`;
const Description = styled.p`
    font-size: 16px;
    line-height: 1.3625;
    margin-bottom: 6px;
    letter-spacing: -0.01em;
    color: var(--ys-white);
    font-weight: 400;

    &:last-of-type {
        margin-bottom: 0;
    }
`;
const TagContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-top: 24px;
`;
const Tag = styled.span`
    display: inline-block;
    border: 1px solid rgba(255, 255, 255, 0.5);
    padding: 6px 16px;
    border-radius: 50px;
    font-size: 14px;
`;
const FormContent = styled.div`
    text-align: center;
    margin-bottom: 32px;
    h2 {
        text-align: center;
        font-family: var(--font-secondary);
        font-size: 40px;
        line-height: 56px;
        padding: 32px 30px 0;
        .red {
            color: var(--ys-red);
        }
    }
`;

const FormContact = styled.div`
    padding: 24px;
    background-color: rgba(7, 7, 7, 0.1);
    display: flex;
    gap: 20px 29px;

    .divider {
        width: 1px;
        background-color: rgba(7, 7, 7, 0.5);
        opacity: 0.5;
    }

    .hotline {
        display: flex;
        gap: 16px;
        align-items: flex-start;

        h3 {
            font-size: 20px !important;
            line-height: 1;
            text-transform: none;
            margin-bottom: 6px;
        }

        a {
            color: var(--ys-red) !important;
            font-size: 32px !important;
            font-weight: 500 !important;
            line-height: 1;
            font-family: var(--font-secondary);

            &:hover {
                text-decoration: underline !important;
            }
        }
    }

    .phone-icon {
        flex-shrink: 0;
        aspect-ratio: 1;
        width: 32px;
        display: grid;
        place-items: center;
        border-radius: 50%;
        background-color: var(--ys-yellow);
    }

    .working-email {
        font-size: 14px;
        margin-top: auto;
        line-height: 19px;

        p {
            margin-bottom: 6px;
            color: var(--ys-black);
        }

        a {
            &:hover {
                text-decoration: underline !important;
            }
        }
    }

    @media ${device.xl} {
        flex-direction: column;

        .divider {
            width: 100%;
            height: 1px;
        }

        .working-email {
            text-align: center;
        }

        .hotline {
            justify-content: center;
        }
    }
`;

const Form = styled.div`
    background: var(--ys-white);
    color: var(--ys-black);
    border-radius: 10px;
    overflow: hidden;
    width: 45%;

    @media ${device.lg} {
        width: 100%;
    }
`;

const Content = styled.div`
    color: var(--ys-white);
    width: 45.8%;

    @media ${device.lg} {
        width: 100%;
    }
`;

const Wrapper = styled.div`
    display: flex;
    gap: 30px;
    background-image: url('/img/product-single/resource-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-color: var(--ys-black);
    padding: 30px;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
    margin-bottom: 80px;

    @media ${device.lg} {
        flex-direction: column;
    }
`;

const InlineFlex = styled.span`
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
`;

const Resource = () => {
    return (
        <Container>
            <Wrapper>
                <Content>
                    <Title>Manufacturing - Material</Title>
                    <Description>
                        For manufacturing, the finest materials are selected as
                        per the requirements, and state-of-the-art equipment,
                        such as laser cutters, routers, and powder coating, is
                        used to produce these signs/ text.
                    </Description>
                    <Description>
                        All of the signage is manufactured by our experienced
                        and skilled staff in-house.
                    </Description>
                    <TagContainer>
                        {tags?.map((tag, index) => {
                            return <Tag>{tag}</Tag>;
                        })}
                    </TagContainer>
                </Content>
                <Form>
                    <FormContent>
                        <h2>
                            Want to view more buyers guide?
                            <br />
                            <span className="red">Download here!</span>
                        </h2>

                        <Button>
                            <InlineFlex>
                                <span>Shop Buyer Guide</span>
                                <svg
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M17 9.00195C19.175 9.01395 20.353 9.11095 21.121 9.87895C22 10.758 22 12.172 22 15V16C22 18.829 22 20.243 21.121 21.122C20.243 22 18.828 22 16 22H8C5.172 22 3.757 22 2.879 21.122C2 20.242 2 18.829 2 16V15C2 12.172 2 10.758 2.879 9.87895C3.647 9.11095 4.825 9.01395 7 9.00195"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                    />
                                    <path
                                        d="M12 2V15M12 15L9 11.5M12 15L15 11.5"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </InlineFlex>
                        </Button>
                    </FormContent>
                    <FormContact>
                        <div className="hotline">
                            <div className="phone-icon">
                                <img src="/icons/phone.svg" alt="phone icon" />
                            </div>
                            <div>
                                <h3>Hotline</h3>
                                <a href="tel:02073501567">020 7350 1567</a>
                            </div>
                        </div>
                        <div className="divider"></div>
                        <div className="working-email">
                            <p>8am - 5pm / Mon - Fri</p>
                            <a href="mailto:info@yellowsigns.co.uk">
                                info@yellowsigns.co.uk
                            </a>
                        </div>
                    </FormContact>
                </Form>
            </Wrapper>
        </Container>
    );
};

export default Resource;
