const locations = [
  {
    label: "Wandsworth",
    href: "/locations/wandsworth",
    companyDetail: {
      locationBanner: "/img/location-single/wandsworth/map.png",
      title: "Sign Company in Wandsworth",
      subtitle:
        "Signage in Putney, Balham, Clapham, Battersea, Tooting, Wandsworth Town",
      content: [
        "The London Borough of Wandsworth is in South West London and includes areas such as Putney, Balham, Clapham, Battersea, Tooting, Wandsworth Town and Southfields. An affluent part of London, popular with young professionals and families especially due to the amount of green spaces – including Wimbledon Park, Wandsworth Common, Clapham Common and Battersea Park, amongst others. ",
        "Putney High Street and the areas in Clapham, Battersea and Wandsworth Town are home to some of South West London’s most popular bars and restaurants. Yellow Signs is a specialist in this industry and works with many bars, restaurants and catering and events companies with a wide range of signage and graphics. You can see our portfolio of bar and restaurant signage here: <a href='/bar-restaurant'>Bar & Restaurant Signs</a> which is just a small selection of the jobs that we’ve fabricated in our Battersea workshop and installed with our in-house team of installers.",
      ],
    },
    showcase: {
      title: "High Streets, Bars and Restaurants in Wandsworth",
      subtitle:
        "Popular high streets in Wandsworth include Battersea Rise, St John’s Hill, Putney High Street and Balham High Road.",
      description:
        "Yellow Signs works with new and existing retail businesses to provide a wide rage of solutions for shop and retails signs – you can view a selection of our jobs here: <a href='/shops-retail-signs'>Shop & Retail Signs</a>",
      jobs: [
        { href: "/shops-retail-signs", label: "Shop & Retail" },
        { href: "/3d-builtup-letters", label: "3D & Built-up Letters" },
      ],
      bottomTitle:
        "As well as our range of 3D illuminated and non-illuminated letters which have a fantastic and eye-catching look for any shop or retail business: <a href='/3d-builtup-letters'>3D & Built-up Letters</a> <br/> <br/> Wandsworth is also well known for its high demand for flats and houses, especially ones located near Clapham Junction or the ‘commons’ – there is a very high amount of estate agents and Yellow Signs is a specialist within this industry. Yellow Signs is an approved supplier for KFH (Kinleigh, Folkard & Hayward), Savills and Battersea Power Station – so if you can be confident that we know what we’re doing when it comes to new signage and graphics for estate agents <a href='/estate-agent-property'>Estate Agent & Property Signs</a> as well as wayfinding for business and residential buildings: <a href='/plaques-wayfinding'>Plaques & Wayfinding</a>  ",
      images: [
        "/img/location-single/wandsworth/showcase-1.jpg",
        "/img/location-single/wandsworth/showcase-2.jpg",
        "/img/location-single/wandsworth/showcase-3.jpg",
        "/img/location-single/wandsworth/showcase-4.jpg",
      ],
    },
    demand: {
      title: "Yellow Signs, Born in Battersea",
      //   description:
      //     "Wandsworth is also well known for its high demand for flats and houses, especially ones located near Clapham Junction or the ‘commons’",
      banner: "/img/location-single/wandsworth/demand-1.jpg",
      sideImage: "/img/location-single/wandsworth/demand-2.jpg",
      headContent:
        "There is a very high amount of estate agents and Yellow Signs is a specialist within this industry.",
      topBody:
        "Yellow Signs is an approved supplier for KFH (Kinleigh, Folkard & Hayward), Savills and Battersea Power Station – so if you can be confident that we know what we’re doing when it comes to new signage and graphics for estate agents <a href='/estate-agent-property'>Estate Agent & Property Signs</a> as well as wayfinding for business and residential buildings: <a href='/plaques-wayfinding'>Plaques & Wayfinding</a>",
      //   estates: [
      //     { href: "/estate-agent-property", label: "Estate Agent & Property Signs" },
      //     { href: "/plaques-wayfinding", label: "Wayfinding & Plaques" },
      //   ],
      bottomBody:
        "At Yellow Signs we fabricate our signs and graphics in-house at our Battersea workshop so you can ensure that you job is completed on time, every-time. Our production team has nearly 100 years of combined knowledge and experience so we can find solutions to even the most tricky or bespoke requests. We also have our own in-house installation team ensuring your job is delivered and installed on time, every time. You can find out more about our production centre here: <a href={`/signage-solution`}>Signage solution</Link>",
      quote:
        "<strong>Based in Battersea, South West London means we can serve London and the South East with super-fast turnaround time, often as fast as same-day or next-day</strong> – when you have that extremely tight deadline! Battersea is part of the London Borough of Wandsworth so if your business is also based here, you can expect even faster service such as (free) site surveys and installations. ",
      extraContent:
        "Owners David and Daniel Wright are born and raised in Wandsworth so know the area well.<strong>You can read more about Yellow Signs and the story so far here:</strong> <a href='/our-story'>Our story</a>",

      sectionTitle: "In-House Production & Install Team",
      sectionDescription:
        "At Yellow Signs we fabricate our signs and graphics in-house at our Battersea workshop so you can ensure that you job is completed on time, every-time. Our production team has nearly 100 years of combined knowledge and experience so we can find solutions to even the most tricky or bespoke requests. We also have our own in-house installation team ensuring your job is delivered and installed on time, every time. You can find out more about our production centre here: <a href='/signage-solution'>Signage Solution</a>",

      sections: [
        {
          title:
            "We supply and install signage in the following areas of Wandsworth:",
          content:
            "Clapham, Clapham Common, Clapham North, South Lambeth, Vauxhall, Battersea, Nine Elms, Clapham Junction, Balham, Tooting Bec Common, Tooting, Tooting Broadway, Earlsfield, Wandsworth Town, Wandsworth Common",
        },
        {
          title:
            "We supply and install signage in the following postcodes of Wandsworth:",
          content: "SW4, SW8, SW11, SW12, SW15, SW16, SW17, SW18.",
        },
      ],
    },
  },
  {
    label: "Kensington & Chelsea",
    href: "/locations/kensington-chelsea",
    companyDetail: {
      locationBanner: "/img/location-single/kensington-chelsea/map.png",
      title: "Sign Company in Kensington & Chelsea",
      subtitle:
        "Signage in Notting Hill, Knightsbridge, Earls Court, West Brompton",
      content: [
        "The London Borough of Kensington & Chelsea is one of the most prestigious and well known areas in in West and South West London. The area is famous for many well know shops and retail premises including the department store Harrords, as well as many markets in and around Notting Hill. Popular high streets include High Street Kensington and Knightsbridge.",
        "Kensington, particularly in London, is known for its diverse array of shops catering to various tastes and preferences. From fashion boutiques and antique shops to art galleries and book stores, this area has shop and retail sorted. Shop and retail signs are an important part of marketing to any business, but especially in high-end areas like Kensington & Chelsea – you can see examples of some of our shop signs here: <a href='/shops-retail-signs'>Shops & Retail Signs</a>",
      ],
    },
    showcase: {
      title: "High-End Shopping",
      subtitle:
        "Knightsbridge is one of the most well-known shopping areas in this London borough.",
      description:
        "It’s home stores such as Harrods, which offers an unparalleled shopping experience, featuring luxury fashion, beauty products, gourmet food, and exquisite homeware. Additionally, nearby Sloane Street is lined with high-end designer boutiques, including brands such as Chanel, Dior, and Prada, attracting affluent shoppers from around the globe. Such high-end shop and retail spaces really benefit from our 3D & Built-Up Letters – these can be illuminated or non-illuminated: <a href='/shops-retail-signs'>Shop & Retail Signs</a>",
      jobs: [
        // { href: "/shops-retail-signs", label: "Shop & Retail" },
        // { href: "/3d-builtup-letters", label: "3D & Built-up Letters" },
      ],
      bottomTitle:
        "Yellow Signs has a high number of clients in these areas as well as Kensington, Chelsea and Knightsbrdge which come to us for bar and restaurant signage – internal graphics or external menus or shops signs, you can see examples here: <a href='/shops-retail-signs'>Shop & Retail Signs</a>",
      images: [
        "/img/location-single/kensington-chelsea/showcase-1.jpg",
        "/img/location-single/kensington-chelsea/showcase-2.jpg",
        "/img/location-single/kensington-chelsea/showcase-3.jpg",
        "/img/location-single/kensington-chelsea/showcase-4.jpg",
      ],
    },
    demand: {
      title: "World Class Museums",
      //   description:
      //     "There are also a wealth of museums in Kensington & Chelsea, including the V&A (Victoria & Albert), as well as the Science Museum and the National History Museum",
      banner: "/img/location-single/kensington-chelsea/demand-1.jpg",
      sideImage: "/img/location-single/kensington-chelsea/demand-2.jpg",
      headContent:
        "Yellow Signs works closely with a wide range of museums, especially for the their wayfinding and plaques: <a href='/plaques-wayfinding'>Plaques & Wayfinding</a> - something which is vital to show visitors and tourists how to navigate the large spaces. ",
      topBody:
        "Yellow Signs is an approved supplier for KFH (Kinleigh, Folkard & Hayward), Savills and Battersea Power Station – so if you can be confident that we know what we’re doing when it comes to new signage and graphics for estate agents as well as wayfinding for business and residential buildings: ",
      estates: [
        {
          href: "/estate-agent-property",
          label: "Estate Agent & Property Signs",
        },
        { href: "/plaques-wayfinding", label: "Wayfinding & Plaques" },
      ],
      bottomBody:
        "At Yellow Signs we fabricate our signs and graphics in-house at our Battersea workshop so you can ensure that you job is completed on time, every-time. Our production team has nearly 100 years of combined knowledge and experience so we can find solutions to even the most tricky or bespoke requests. We also have our own in-house installation team ensuring your job is delivered and installed on time, every time. You can find out more about our production centre here: <a href='/signage-solution'>Signage solution</Link>",
      quote:
        "<strong>There are also a wealth of museums in Kensington & Chelsea, including the V&A (Victoria & Albert), as well as the Science Museum and the National History Museum.</strong/> Yellow Signs works closely with a wide range of museums, especially for the their wayfinding and plaques: <a href='/plaques-wayfinding'>Plaques & Wayfinding</a> - something which is vital to show visitors and tourists how to navigate the large spaces. ",
      extraContent:
        "Owners David and Daniel Wright are born and raised in Wandsworth so know the area well.<strong>You can read more about Yellow Signs and the story so far here:</strong> <a href='/our-story'>Our story</a>",
      sectionTitle: "",
      sectionDescription: `West Brompton and Earls Court also make up the famous London borough and are home to a number of bars and restaurants and you see plenty of examples here: <a href='/bar-restaurant'>Bar & Restaurant Signs</a> 
        <br/><br/>
        Based in Battersea, Yellow Signs is very close to Kensington & Chelsea, so we know the area well and can provide incredibly fast service – from site surveys to fabrication and installation, meaning even very tight deadlines are possible. You can see more about our process here: <a href='/signage-solution'>Signage Solution</a>
        `,
      sections: [
        {
          title:
            "We supply and install signage in the following areas of Wandsworth:",
          content:
            "Clapham, Clapham Common, Clapham North, South Lambeth, Vauxhall, Battersea, Nine Elms, Clapham Junction, Balham, Tooting Bec Common, Tooting, Tooting Broadway, Earlsfield, Wandsworth Town, Wandsworth Common",
        },
        {
          title:
            "We supply and install signage in the following postcodes of Wandsworth:",
          content: "SW4, SW8, SW11, SW12, SW15, SW16, SW17, SW18.",
        },
      ],
    },
  },
  {
    label: "Hammersmith & Fulham",
    href: "/locations/hammersmith-fulham",
    companyDetail: {
      locationBanner: "/img/location-single/hammersmith-fullham/map.png",
      title: "Sign Company in Hammersmith & Fulham",
      subtitle:
        "Signage in Hammersmith, Fulham, Parsons Green, Sands End, Shepherd’s Bush, White City, Ravenscourt Park, Brook Green",
      content: [
        "The London Borough of Hammersmith and Fulham is situated in West and South West London and includes a wide area with lots of parks, bars, restaurants and shops. The heart of the borough centres around Hammersmith Broadway and Fulham Broadway, connecting Fullham Palace Road and Fulham Road.",
        "Hammersmith’s main road of King’s Street is jam-packed with shops and retail premises which we have been a supplier of for many years in the area. You can see lots of examples of shop and retail signs here: <a href='/shops-retail-signs'>Shop & Retail Signs</a>. The area is also home to numerous offices and managed office block which require interior and exterior signage – something we specialise in. You can find examples of our past work here: <a href='/office-corporate'>Office & Corporate Signs</a>.",
      ],
    },
    showcase: {
      title: "Wayfinding in Hammersmith & Fulham",
      subtitle:
        "Wayfinding is also vital for offices as well as hospitals, hotels and sporting events to ensure all customers know how to navigate your premises.",
      description:
        "Hammersmith Apollo being a prime example of a such a space – an iconic building in the heart of Hammersmith. Charing Cross hospital on Fulham Palace Road is also at the heart of the community in the borough and serves as one of the largest hospitals in the area. We work with a wide range of hospitals and realise the need for a wide range of products and services a hospital can benefit from – including wayfinding and plaques: <a href='/plaques-wayfinding'>Plaques & Wayfinding</a>.",
      jobs: [],
      images: [
        "/img/location-single/hammersmith-fullham/showcase-1.jpg",
        "/img/location-single/hammersmith-fullham/showcase-2.jpg",
        "/img/location-single/hammersmith-fullham/showcase-3.jpg",
        "/img/location-single/hammersmith-fullham/showcase-4.jpg",
      ],
    },
    demand: {
      title: "Booming Property Market",
      banner: "/img/location-single/hammersmith-fullham/demand-1.jpg",
      sideImage: "/img/location-single/hammersmith-fullham/demand-2.jpg",
      quote:
        "Hammersmith and Fulham is home to huge amount of estate agents due to the buoyant property market in the area – we love to work with estate agents and you can see examples of our work here: <a href='/estate-agent-property'>Estate Agent & Property Signs</a>.",
      topBody:
        "Yellow Signs is an approved supplier for KFH (Kinleigh, Folkard & Hayward), Savills and Battersea Power Station – so if you can be confident that we know what we’re doing when it comes to new signage and graphics for estate agents as well as wayfinding for business and residential buildings:",
      estates: [
        {
          href: "/estate-agent-property",
          label: "Estate Agent & Property Signs",
        },
        { href: "/plaques-wayfinding", label: "Wayfinding & Plaques" },
      ],
      sectionDescription:
        "The borough is also well known for excellent primary and secondary schools and is a big draw for young families to move into the area. We work within primary schools, secondary schools, academies, colleges and universities and you can see examples of some of our previous signage work here: <a href='/school-education'>School & Education Signs</a>.",
      extraContent: "",
      sectionTitle: "Signage for Schools, Colleges and Universities",
      sections: [
        {
          title:
            "We supply and install signage in the following areas of Hammersmith & Fulham:",
          content:
            "Hammersmith, Shepherd's Bush, Fulham, West Kensington, Parsons Green, White City, Sands End",
        },
        {
          title:
            "We supply and install signage in the following postcodes of Hammersmith & Fulham:",
          content: "SW6, W6, W12, W14",
        },
      ],
    },
  },
  {
    label: "Westminster",
    href: "/locations/westminster",
    companyDetail: {
      locationBanner: "/img/location-single/westminster/map.png",
      title: "Signage Company in Westminster",
      subtitle:
        "Signage in Mayfair, Bayswater, St Johns Wood, Maida Vale, South Kensington, Pimlico, Victoria",
      content: [
        "Westminster Borough, located in central London, is known for its rich history, iconic landmarks, and status as the political and cultural heart of the city. Westminster is known for a wealth of iconic landmarks, including Houses of Parliament, Big Ben, Buckingham Palace and Westminster Palace.",
        "Westminster is also well known and loved for its array of theatres, especially around Drury Lane and Covent Garden. We work with many theatres which regularly need full colour prints and a wide variety of signage and graphics, often supplied and installed to tight deadlines: <a href='/exhibition-event'>Exhibition & Events Signs</a>.",
        "British Museum and Tate Britain, which showcase a vast array of art and historical artifacts, as well as a plethora of other museums, make up the cultural heart of Westminster and the London Borough.",
      ],
    },
    showcase: {
      title: "Luxury Brands & Luxury Shops",
      subtitle:
        "Westminster also boasts some of the busiest and most iconic shopping streets in London – Oxford Street, Regent Street and Bond Street are home to the world’s most famous brands",
      description:
        "We love to work with high-end fashion brands and other retail brands and make almost everything in house – from regular shop and retail signs (<a href='/shops-retail-signs'>Shop & Retail Signs</a>) to more impressive 3D & Built-Up Letters (<a href='/3d-builtup-letters'>Shop & Retails Signs</a>) as well as <a href='/bespoke-fabrication'>Bespoke Fabrication</a> for those jobs that you might not have seen before.",
      jobs: [],
      images: [
        "/img/location-single/westminster/showcase-1.jpg",
        "/img/location-single/westminster/showcase-2.jpg",
        "/img/location-single/westminster/showcase-3.jpg",
        "/img/location-single/westminster/showcase-4.jpg",
      ],
    },
    demand: {
      title: "World Famous Shopping Streets",
      banner: "/img/location-single/westminster/demand-1.jpg",
      sideImage: "/img/location-single/westminster/demand-2.jpg",
      quote:
        "<strong>Signage and graphics in Westminster are an important and useful marketing tool to ensure strong branding and corporate colours, especially in the high footfall areas of the West End and shopping high streets of Oxford Street, Regent Street, Carnaby Street and surround luxury shopping spots.</strong> Promotional vinyl is perfect for seasonal sales and ad-hoc promotions – full colour graphic examples can be found here: <a href='/full-colour-graphics'>Full Colour Graphics</a>",
      topBody:
        "Yellow Signs is an approved supplier for KFH (Kinleigh, Folkard & Hayward), Savills and Battersea Power Station – so if you can be confident that we know what we’re doing when it comes to new signage and graphics for estate agents as well as wayfinding for business and residential buildings:",
      estates: [
        {
          href: "/estate-agent-property",
          label: "Estate Agent & Property Signs",
        },
        { href: "/plaques-wayfinding", label: "Wayfinding & Plaques" },
      ],
      sectionDescription:
        "Westminster seems to have it all. As well as a shopping paradise and its famous monuments and iconic buildings, Westminster is also home to beautiful canals which connect St John’s Wood and Maida Vale. It’s also still manages to be a green borough with many famous parks including St. James’s Park, Green Park and Hyde Park. It is also home to Soho, with its infamous nightlife as well as interesting and often independent bars, cafes and restaurants.",
      extraContent: "",
      sectionTitle: "The Borough with Everything",
      sections: [
        {
          title:
            "We supply and install signage in the following areas of Westminster:",
          content:
            "Whitehall and Downing Street, Parts of Victoria, Westminster Abbey and Westminster Cathedral, Pimlico and Victoria, as well as Westminster's administrative offices, parts of Victoria, Pimlico, and parts of Belgravia, City of Westminster, the W1 postcode also covers parts of Westminster, including areas like Soho and Covent Garden.",
        },
        {
          title:
            "We supply and install signage in the following postcodes of Westminster:",
          content: "SW1, SW1A, SW1E, SW1H, SW1P, SW1V, W1",
        },
      ],
    },
  },
  {
    label: "The City of London",
    href: "/locations/city-of-london",
    companyDetail: {
      title: "Sign Company in The City of London",
      subtitle:
        "Signage in Farringdon, Bank, Tower Hill, Aldgate, St Pauls, Moorgate, Monument",
      content: [
        "The City of London or just ‘The City’ is in the heart of central London and it’s banking district. The borough is small but incorporates some of the most well known streets and roads in London with a heavy focus on the finance industry. In particular Tower Hill, Bank, Moorgate and Monument are areas in the City which are home to numerous banks, insurance companies and other financial institutions.",
      ],
      locationBanner: "/img/location-single/city-of-london/map.png",
    },
    showcase: {
      title: "Signage in The City",
      subtitle: "",
      description:
        "Our signs by sector section on our website shows signage and graphics jobs from various sectors and industries including office and corporate – this sector is huge in the City and you can see examples to suit this area of London here: <a href='https://yellowsigns.co.uk/office-corporate'>Office & Corporate Signs</a>. Offices most commonly require flat cut letters for office walls, which are usually made from acrylic but can also be made from stainless steel or aluminium – you can see examples of flat cut letters here: <a href='https://yellowsigns.co.uk/flat-letters'>Flat Cut Letters</a> - the most common being company logos which can be painted to any Pantone or RAL to match the company colours.",
      jobs: [],
      bottomTitle:
        "Offices also require wayfinding, whether it be for aesthetic reasons or for health & safety and fire regulations – we can provide the right solution for your office. Our wayfinding plaques are available in a wide variety of materials, finishes and colours to suit your brand and company image. You can see examples of wayfinding and plaques for offices and corporate spaces here: <a href='https://yellowsigns.co.uk/plaques-wayfinding'>Plaques & Wayfinding Signs</a>.",
      images: [
        "/img/location-single/city-of-london/showcase-1.jpg",
        "/img/location-single/city-of-london/showcase-2.jpg",
        "/img/location-single/city-of-london/showcase-3.jpg",
        "/img/location-single/city-of-london/showcase-4.jpg",
      ],
    },
    demand: {
      title: "More than Just Finance",
      banner: "/img/location-single/city-of-london/demand-1.jpg",
      sideImage: "/img/location-single/city-of-london/demand-2.jpg",
      quote:
        "As well as the financial industry, The City of London and Farringdon is particular is known for Smithfield meat market (<a href='https://smithfieldmarket.com/'>Smithfield Market</a>) – markets all over London find signage to be incredibly important. All stands need exposure and need to compete for business so eye catching branding is vital. Our shop and retails are perfect for market vendors and you can see a wide range of signage and graphics here: <a href='https://yellowsigns.co.uk/shops-retail-signs'>Shop & Retail Signs</a>.",
      topBody: "",
      estates: [],
      sectionDescription:
        "The City of London, also known simply as 'the City' or the 'Square Mile,' has its own unique set of postal codes that differ from the rest of Greater London. Here are the primary postal codes within the City of London:",
      extraContent: "",
      sectionTitle: "The City of London Postal Codes",
      sections: [
        {
          title:
            "We supply and install signage in the following areas of The City of London:",
          content:
            "Farringdon, Bank, Tower Hill, Aldgate, St Pauls, Moorgate, Monument",
        },
        {
          title:
            "We supply and install signage in the following postcodes of The City of London:",
          content: "EC1, EC2, EC3, EC4",
        },
        {
          title:
            "These are often further subdivided for more specific locations within the City:",
          content:
            "London EC1: EC1A, EC1M, EC1N, EC1R, EC1V, EC1Y\nLondon EC2: EC2A, EC2M, EC2N, EC2R, EC2V, EC2Y\nLondon EC3: EC3A, EC3M, EC3N, EC3P, EC3R, EC3V\nLondon EC4: EC4A, EC4M, EC4N, EC4R, EC4V, EC4Y",
        },
      ],
    },
  },
  {
    label: "Richmond Upon Thames",
    href: "/locations/richmond-upon-thames",
    companyDetail: {
      title: "Sign Company in Richmond Upon Thames",
      subtitle:
        "Signage in Barnes, East Sheen, Fulwell, Hampton, Petersham, Hampton Wick, Twickenham",
      content: [
        "Richmond Upon Thames incorporates the area of Richmond as well as the surrounding areas, most of which are located close to Richmond Park or the Thames. Richmond park is largest of London's Royal Parks and is well known for its vast open spaces, ancient trees, and herds of free-roaming deer. It is a popular spot for walking, cycling, and enjoying nature. The borough is one London’s greenest and boasts affluent areas and high streets.",
      ],
      locationBanner: "/img/location-single/richmond-upon-thames/map.png",
    },
    showcase: {
      title: "High Streets & Estate Agents",
      subtitle: "",
      description:
        "Richmond boats a strong retail presence, especially in Richmond itself and in Sheen, on the Upper Richmond Road. You can see plenty of examples of shop and retail signs here: <a href='https://yellowsigns.co.uk/shops-retail-signs'>Shop & Retail Signs</a>.",
      jobs: [],
      bottomTitle:
        "The residential property market is an important part of the borough and boasts some of the highest prices in London and the South East. Estate agents are numerous and are part of a competitive industry. Signage and branding is key for estate agents in Richmond and the other areas within the borough. You can find examples of signage and graphics to enhance estate agency branding and exposure here: <a href='https://yellowsigns.co.uk/estate-agent-property'>Estate Agent & Property Signs</a>. Estate agent signage is broad, including external shop signage, window graphics, internal branding, as well as vehicle branding such as flat vinyl graphics and partial or full vehicle wraps. Car and van graphics and wraps are often key parts of branding a modern estate agent – you can see examples of our vehicle graphics here: <a href='https://yellowsigns.co.uk/vehicle-graphics'>Vehicle Graphics</a>.",
      images: [
        "/img/location-single/richmond-upon-thames/showcase-1.jpg",
        "/img/location-single/richmond-upon-thames/showcase-2.jpg",
        "/img/location-single/richmond-upon-thames/showcase-3.jpg",
        "/img/location-single/richmond-upon-thames/showcase-4.jpg",
      ],
    },
    demand: {
      title: "Twickenham Rugby",
      banner: "/img/location-single/richmond-upon-thames/demand-1.jpg",
      sideImage: "/img/location-single/richmond-upon-thames/demand-2.jpg",
      quote:
        "Richmond Upon Thames is also home to one of the country’s most famous sporting ground – Twickenham Rugby Football Club. Rugby is one of, if not the most popular sport in the borough – whether its world rugby at Twickenham or club rugby just over the road at Harlequins, this borough has it all covered.",
      topBody: "",
      estates: [],
      sectionDescription:
        "We work with a wide range of sporting clubs, coaching centres, gyms and fitness centres and you can see some of the different types of signage and graphics which this sector can benefit from here: <a href='https://yellowsigns.co.uk/gyms-fitness'>Gyms & Fitness Signs</a>.<br/><br/>The borough is also known for one of the largest and most prestigious palaces in the country – Hampton Court Palace, which sits beside the river Thames is a Grade I listed building and is open to the public year-round. London boasts many palaces, most of which enjoy high visitor numbers throughout the year. We work with a number of palaces and museums which require wayfinding for their large spaces to show the public exactly how to navigate and what to see. You can see examples of our wayfinding signage here: <a href='https://yellowsigns.co.uk/plaques-wayfinding'>Plaques & Wayfinding</a>.",
      extraContent: "",
      sectionTitle: "Fitness in Richmond",
      sections: [
        {
          title:
            "We supply and install signage in the following areas of Richmond Upon Thames:",
          content:
            "Richmond, Twickenham, Teddington, Hampton, East Sheen, Barnes, Mortlake",
        },
        {
          title:
            "We supply and install signage in the following postcodes of Richmond Upon Thames:",
          content: "SW13, SW14. TW1, TW2, TW9, TW10, TW11, TW12",
        },
      ],
    },
  },
  {
    label: "Merton",
    href: "/locations/merton",
    companyDetail: {
      title: "Sign Company in Merton",
      subtitle:
        "Signage in Wimbledon, Wimbledon Village, Wimbledon Park, Merton Park, Morden, Raynes Park, Colliers Wood",
      content: [
        "The London Borough of Merton is hope to a number of green and mostly residential areas in London with a focus in and around the areas of Wimbledon and Wimbledon Village. Wimbledon also boasts a charming village feel with high-end shops and eateries. Parks and commons contribute significantly to the landscape in this borough and are vital parts as well as a massive draw for young families moving into the borough. Over the years this borough has become more and more desirable and property prices reflect this.",
      ],
      locationBanner: "/img/location-single/merton/map.png",
    },
    showcase: {
      title: "Booming Property Market",
      subtitle: "",
      description:
        "The high demand for residential properties brings with it a high amount of estate agents all competing for the next flat or house sale or let. We work with a high number of estate agents as they require a wide range of signage and graphics – both internal and external. You can see examples of estate agent signage here with a wide variety of materials, shapes and sizes: <a href='https://yellowsigns.co.uk/estate-agent-property'>Estate Agent & Property Signs</a>.",
      jobs: [],
      bottomTitle:
        "Merton is perhaps most famous for Wimbledon, the home of the prestigious Wimbledon Tennis Championships held annually at the All England Lawn Tennis and Croquet Club. We are an approved supplier for the AELTC and are well equipped to work with large sporting establishments which often require jobs produced and delivered in installed in record times. Wayfinding in particular is vital type of signage for sporting building, which you can see examples of here: <a href='https://yellowsigns.co.uk/plaques-wayfinding'>Plaques & Wayfinding</a>.",
      images: [
        "/img/location-single/merton/showcase-1.jpg",
        "/img/location-single/merton/showcase-2.jpg",
        "/img/location-single/merton/showcase-3.jpg",
        "/img/location-single/merton/showcase-4.jpg",
      ],
    },
    demand: {
      title: "Retail and Construction Signage",
      banner: "/img/location-single/merton/demand-1.jpg",
      sideImage: "/img/location-single/merton/demand-2.jpg",
      quote:
        "Wimbledon and the Centre Court Shopping Centre has always had a strong retail presence in Merton. Whilst traditional shops may have moved online and left the high street with some empty premises, other industries have moved in to create a new and interesting high street and shopping centre. You can see more examples of shop and retail signage here: <a href='https://yellowsigns.co.uk/shops-retail-signs'>Shop & Retail Signs</a>.",
      topBody: "",
      estates: [],
      sectionDescription:
        "The competitive nature of the housing market in Merton brings about a high amount of building and construction companies which can add value and space to flats and houses. Building and construction companies also require signage which includes health & safety signage, wayfinding and hoarding in particular which disguises the building project as well as being a great form of advertising. You can see examples of this kind of signage here: <a href='https://yellowsigns.co.uk/build-construction'>Building & Construction Signs</a>.",
      extraContent: "",
      sectionTitle: "Retail and Construction Signage",
      sections: [
        {
          title:
            "We supply and install signage in the following areas of Merton:",
          content:
            "Wimbledon, Mitcham, Morden, Colliers Wood, Raynes Park, South Wimbledon",
        },
        {
          title:
            "We supply and install signage in the following postcodes of Merton:",
          content: "CR4, SM4, SW19, SW20",
        },
      ],
    },
  },
  {
    label: "Kingston Upon Thames",
    href: "/locations/kingston-upon-thames",
    companyDetail: {
      locationBanner: "/img/location-single/kingston-upon-thames/map.png",
      title: "Sign Company in Kingston Upon Thames",
      subtitle:
        "Signage in Kingston, Chessington, New Malden, Surbiton, Tolworth",
      content: [
        "Kingston upon Thames is known for its blend of historical charm, vibrant cultural scene, excellent shopping and dining options, and picturesque riverside setting. The borough is focused around the River Thames and has become a highly desirable place to live, especially for London commuters whose makes use of the busy A3 road into London or the numerous train links that take you into Waterloo and the City.",
      ],
    },
    showcase: {
      title: "Education in Kingston",
      subtitle: "",
      description:
        "One of Kingston’s biggest draws, especially for young families is the quality and abundance of schools, colleges and universities. Many are based in and around Kingston itself and you can see examples of our educational signage here: <a href='https://yellowsigns.co.uk/shops-retail-signs'>Shop & Retail Signs</a>.",
      jobs: [],
      bottomTitle:
        "Kingston is at the heart of the borough and is well known for its market square and riverside cafes, bars and restaurants. The huge amount of eating and drinking establishments means a huge amount of competition so it’s important that these bars, cafes and restaurants have great signage and branding to encourage customers inside. We work with a wide range of food and beverage businesses and you can see some of our signage examples for the industry here: <a href='https://yellowsigns.co.uk/bar-restaurant'>Bar & Restaurant</a>.",
      images: [
        "/img/location-single/kingston-upon-thames/showcase-1.jpg",
        "/img/location-single/kingston-upon-thames/showcase-2.jpg",
        "/img/location-single/kingston-upon-thames/showcase-3.jpg",
        "/img/location-single/kingston-upon-thames/showcase-4.jpg",
      ],
    },
    demand: {
      title: "High Street & Shopping",
      banner: "/img/location-single/kingston-upon-thames/demand-1.jpg",
      sideImage: "/img/location-single/kingston-upon-thames/demand-2.jpg",
      quote:
        "Kingston in particular is also well known for its high street and Bentalls centre shopping. The huge variety of shops means you’re very likely to find exactly what you’re looking for. We work with many shop and retail owners to help them attract more customer with sign and retail signs: <a href='https://yellowsigns.co.uk/shops-retail-signs'>Shop & Retail Signs</a>.",
      topBody: "",
      estates: [],
      sectionDescription:
        "Kingston’s reputation for great schools and educational facilities fuels the every present rise in prices in the housing market, which results in a competitive estate agent market. Whether it’s a let or sale there are plenty of estate agents to help you move into or out of this borough. Estate agents require eye-catching and highly visible signage and graphics to complete in such a dynamic environment and industry and you can see examples of this type of signage here: <a href='https://yellowsigns.co.uk/estate-agent-property'>Estate Agent & Property Signs</a>.",
      extraContent: "",
      sectionTitle: "Schools & Estate Agents",
      sections: [
        {
          title:
            "We supply and install signage in the following areas of Kingston Upon Thames:",
          content:
            "Kingston upon Thames, New Malden, Chessington, Surbiton, Tolworth",
        },
        {
          title:
            "We supply and install signage in the following postcodes of Kingston Upon Thames:",
          content: "KT1, KT2, KT3, KT5, KT6, KT9",
        },
      ],
    },
  },
  {
    label: "Lambeth",
    href: "/locations/lambeth",
    companyDetail: {
      locationBanner: "/img/location-single/lambeth/map.png",
      title: "Sign Company in Lambeth",
      subtitle:
        "Signage in Vauxhall, Kennington, South Lambeth, Stockwell, Brixton, Clapham, Streatham, Norwood",
      content: [
        "The London Borough of Lambeth, located in Central and South London, is known for a wide range of notable features and attractions including The South Bank - this area is a major cultural hub, home to institutions like the Southbank Centre, Royal Festival Hall, the National Theatre, and the BFI (British Film Institute). As well as The London Eye, one of London's most famous tourist attractions, offering panoramic views of the city. Historical sites including Lambeth Palace and The Imperial War Museum.",
      ],
    },
    showcase: {
      title: "Food & Drink Pop-Ups",
      subtitle: "",
      description:
        "Lambeth has always been a vibrant and ever-changing borough. Clapham and Brixton often leading the way with independent food and drink vendors and pop-ups, now with the Nine Elms regeneration project doing something similar. Neon has become a popular type of signage, especially amongst pop-ups and newly opened food and drink vendors – you can examples of neon signage here: <a href='https://yellowsigns.co.uk/neon-signs'>Neon Signs</a>.",
      jobs: [],
      bottomTitle:
        "Lambeth is also home to 3 well known residential vibrant neighbourhoods – Brixton, Clapham and Streatham, which are centred around their respective high streets. Bars, cafes and restaurants dominate these areas as well as day and nighttime markets. Brixton market has never been busier and is a key attraction to people moving into the area. You can see examples of shop and retail signage here: <a href='https://yellowsigns.co.uk/shops-retail-signs'>Shop & Retail Signs</a>. These are perfect for permanent shops or more temporary market or pop-up premises.",
      images: [
        "/img/location-single/lambeth/showcase-1.jpg",
        "/img/location-single/lambeth/showcase-2.jpg",
        "/img/location-single/lambeth/showcase-3.jpg",
        "/img/location-single/lambeth/showcase-4.jpg",
      ],
    },
    demand: {
      title: "Leafy Lambeth",
      banner: "/img/location-single/lambeth/demand-1.jpg",
      sideImage: "/img/location-single/lambeth/demand-2.jpg",
      quote:
        "Over the last decade or so, Lambeth has become one of the most desirable boroughs to live for newly graduated students and young families. The boroughs many well known parks and commons including Clapham Common, Brockwell Park and Kennington Park. The combination of these green spaces and the exciting nightlife make for a booming property market. Estate agents are in numbers and all competing for business. You can see examples of our signage for estate agents in Lambeth and all over London here: <a href='https://yellowsigns.co.uk/estate-agent-property'>Estate Agent & Property Signs</a>.",
      topBody: "",
      estates: [],
      sectionDescription:
        "Lambeth seems to be drawing an increasingly younger crowd, and this brings with it a lot of competition in the health & fitness sector. Whether it be a traditional gym, health club, cross-fit or class-led training, signage is vital to stand out for the crowd in a competitive industry – you can see examples of signage for gyms and fitness centres here: <a href='https://yellowsigns.co.uk/gyms-fitness'>Gyms & Fitness Signs</a>.",
      extraContent: "",
      sectionTitle: "Fitness & Music",
      sections: [
        {
          title:
            "We supply and install signage in the following areas of Lambeth:",
          content:
            "Brixton, Clapham, Streatham, Herne Hill, Kennington, Stockwell, Vauxhall, Waterloo, West Norwood, Tulse Hill, Oval",
        },
        {
          title:
            "We supply and install signage in the following postcodes of Lambeth:",
          content: "SE1, SE11, SE24, SE27, SW2, SW4, SW8, SW9, SW16",
        },
      ],
    },
  },
  {
    label: "Camden",
    href: "/locations/camden",
    companyDetail: {
      locationBanner: "/img/location-single/camden/map.png",
      title: "Sign Company in Camden",
      subtitle: "Signage in Camden Town, Kings Cross, Kentish Town",
      content: [
        "The London Borough of Camden is known for its diverse cultural scene, historical significance, and vibrant neighbourhoods. Stand-out places include The British Museum, Camden Market, Regent’s Park and London Zoo. Tourist and locals flock to these Camden institutions and the borough has become one of London’s most recognisable and popular.",
      ],
    },
    showcase: {
      title: "High Street Shops",
      subtitle: "",
      description:
        "Camden market is one of the focus areas within the borough and it is home to hundreds, if not thousands of mostly independent vendors and shop owners around the high street and canal. Markets are one of the key industries to benefit from eye-catching signage and graphics. Whether it just be window graphics - <a href='https://yellowsigns.co.uk/full-colour-graphics'>Full Colour Graphics</a> or shop signs - <a href='https://yellowsigns.co.uk/shops-retail-signs'>Shop & Retail Signs</a> - exposure for potential custom is vital. We can help with shop and retail signage, no matter the size.",
      jobs: [],
      bottomTitle:
        "Camden, with its famous market and lock is the heart of the borough. It attracts tourists from all over the world who are looking to enjoy the shopping, the food and drink and the music of this eclectic part of London. The focus is around the market and the canal and is often one of the most popular places for Londoners and tourists alike, especially in the summer months.",
      images: [
        "/img/location-single/camden/showcase-1.jpg",
        "/img/location-single/camden/showcase-2.jpg",
        "/img/location-single/camden/showcase-3.jpg",
        "/img/location-single/camden/showcase-4.jpg",
      ],
    },
    demand: {
      title: "Bespoke Signage",
      banner: "/img/location-single/camden/demand-1.jpg",
      sideImage: "/img/location-single/camden/demand-2.jpg",
      quote:
        "Areas such as Hampstead and Belsize Park are known for their affluent residential neighbourhoods, charming streets, and historic houses. Estate agents all compete for the next let or sale in this very desirable area of London. Such competition requires the best and most eye-catching signage and you can find examples of bespoke signage here: <a href='https://yellowsigns.co.uk/bespoke-fabrication'>Bespoke Fabrication</a>.",
      topBody: "",
      estates: [],
      sectionDescription:
        "Estate agent signage is also popular in Camden and you can see examples here: <a href='https://yellowsigns.co.uk/estate-agent-property'>Estate Agent & Property Signs</a>. 3D letters are particularly popular, especially internally illuminated letters: <a href='https://yellowsigns.co.uk/3d-builtup-letters'>3D & Built-Up Letters</a>.",
      extraContent: "",
      sectionTitle: "Bespoke Signage",
      sections: [
        {
          title:
            "We supply and install signage in the following areas of Camden:",
          content:
            "Bloomsbury, Camden Town, Hampstead, Belsize Park, Primrose Hill, Kentish Town, Gospel Oak, Chalk Farm, Somers Town, Swiss Cottage, Fitzrovia, West Hampstead, Holborn and Regent's Park.",
        },
        {
          title:
            "We supply and install signage in the following postcodes of Camden:",
          content: "NW1, N1C, NW3, NW5, NW6, WC1, WC1V, WC1X",
        },
      ],
    },
  },
  {
    label: "London & The South-East",
    href: "/locations/london-south-east",
    companyDetail: {
      locationBanner: "/img/location-single/london-the-south-east/map.png",
      title: "Sign Company in London & The South-East",
      subtitle: "",
      content: [
        "We supply and install signage all over London and the South-East. We can supply and deliver signage all over the UK but most of our work for installations is in London and surrounding areas. We fabricate signs and graphics in our Battersea production facility which is based in Battersea, South-West London. We are strategically located to be able to quickly attend site surveys and installations all over London and the South East.",
      ],
    },
    showcase: {
      title: "Signage for Offices & Shops",
      subtitle: "",
      description:
        "We provide a range of signage and graphics in London but focus particularly on these areas: <br/><br/>Signage for offices and corporate signage – you can see examples here: <a href='https://yellowsigns.co.uk/office-corporate'>Office & Corporate Signs</a>.",
      jobs: [],
      bottomTitle:
        "Offices and corporate premises are also a huge part of London, especially in the City of London. Office signage often requires privacy frosting and wayfinding to ensure health and safety and fire safety precautions are in place. We specialise in wayfinding and are approved suppliers for Savills, KFH and Battersea Power station – you can see examples of wayfinding and plaques in London here: <a href='https://yellowsigns.co.uk/plaques-wayfinding'>Plaques & Wayfinding</a>.<br/><br/>Shop and retail signs are perfect for the numerous London high streets and you can see some examples of shop signs here: <a href='https://yellowsigns.co.uk/shops-retail-signs'>Shop & Retail Signs</a>.",
      images: [
        "/img/location-single/london-the-south-east/showcase-1.jpg",
        "/img/location-single/london-the-south-east/showcase-2.jpg",
        "/img/location-single/london-the-south-east/showcase-3.jpg",
        "/img/location-single/london-the-south-east/showcase-4.jpg",
      ],
    },
    demand: {
      title: "Signage for Bars & Restaurants",
      banner: "/img/location-single/london-the-south-east/demand-1.jpg",
      sideImage: "/img/location-single/london-the-south-east/demand-2.jpg",
      quote:
        "One of London’s attractions to tourists and locals is the abundance of bars, cafes and restaurants – all of which are in competition with each other to grab the attention of passers-by and potential customers. You see a wide range of signage for bars and restaurants in London here: <a href='https://yellowsigns.co.uk/bar-restaurant'>Bar & Restaurant Signs</a>.",
      topBody: "",
      estates: [],
      sectionDescription:
        "London and the South East has a thriving and competitive building and construction industry. Residential and commercial property development is always growing and it’s important for developers and estate agents to make use of every available space for advertising and to increase their brand image. Hoarding is perfect for this – especially in high traffic areas. Once up it’s free advertising for you and any partners within the project. You can see examples of hoarding signage here: <a href='https://yellowsigns.co.uk/hoarding'>Hoarding</a> and examples of building and construction signage here: <a href='https://yellowsigns.co.uk/build-construction'>Building & Construction Signs</a>.",
      extraContent: "",
      sectionTitle: "Hoarding",
      sections: [
        {
          title: "Inner London Boroughs:",
          content:
            "Camden, Greenwich, Hackney, Hammersmith and Fulham, Islington, Kensington and Chelsea, Lambeth, Lewisham, Southwark, Tower Hamlets, Wandsworth, Westminster",
        },
        {
          title: "Outer London Boroughs:",
          content:
            "Barking and Dagenham, Barnet, Bexley, Brent, Bromley, Croydon, Ealing, Enfield, Haringey, Harrow, Havering, Hillingdon, Hounslow, Kingston upon Thames, Merton, Newham, Redbridge, Richmond upon Thames, Sutton, Waltham Forest",
        },
        {
          title: "City of London:",
          content:
            "A unique entity with its own local authority, the City of London Corporation. It is a major financial district and distinct from the 32 London boroughs.",
        },
      ],
    },
  },
  {
    label: "Rest of the UK",
    href: "/locations/rest-of-uk",
    companyDetail: {
      locationBanner: "/img/location-single/rest-of-the-uk/map.png",
      title: "Sign Company in Rest of the UK",
      subtitle: "",
      content: [
        "We fabricate our signage and graphics in-house in Battersea, which is in South-West London. We also install our jobs ourselves with our in-house team of skilled installers. Although most of our work is in and around London, we can supply signage and graphics all over the UK – you can get a good idea of the types of signage on our website and our processes here: <a href='https://yellowsigns.co.uk/signage-solution'>Signage Solution</a>.",
      ],
    },
    showcase: {
      title: "In-House Production & Installation",
      subtitle: "",
      description:
        "Most of our installations are carried out in and around London but we can install all over the UK – so if you have a large job or something that requires more specialist or bespoke work please send us an enquiry through our online form or email us directly – info@yellowsigns.co.uk.",
      jobs: [],
      bottomTitle:
        "You can view a wide range of jobs that we’ve supplied and usually installed across the UK if you visit our case study page: <a href='https://yellowsigns.co.uk/case-studies'>Case Studies</a>. You can also see what’s new in the sign-related news here: <a href='https://yellowsigns.co.uk/news'>News</a>.",
      images: [
        "/img/location-single/rest-of-the-uk/showcase-1.jpg",
        "/img/location-single/rest-of-the-uk/showcase-2.jpg",
        "/img/location-single/rest-of-the-uk/showcase-3.jpg",
        "/img/location-single/rest-of-the-uk/showcase-4.jpg",
      ],
    },
    demand: {
      title: "From SMEs and Charities to Big Brands and Corporate",
      banner: "/img/location-single/rest-of-the-uk/demand-1.jpg",
      sideImage: "/img/location-single/rest-of-the-uk/demand-2.jpg",
      quote:
        "We work with a wide range of businesses and charities, so whatever size your organisation is, just drop us a line and we can quote for your next signage project – info@yellowsigns.co.uk.",
      topBody: "",
      estates: [],
      sectionDescription:
        "Are you looking for something a little bit different? You can’t quite find it online or you want something that just hasn’t been made before? We can usually help! Our huge range of sign making machines and wealth of knowledge in our production and installation team means we can create all sorts of bespoke jobs – from biltong dryers to suspending neon ceilings. Have a look at some of the most bespoke jobs we’ve produced and installed here: <a href='https://yellowsigns.co.uk/bespoke-fabrication'>Bespoke Fabrication</a>.",
      extraContent: "",
      sectionTitle: "Bespoke Fabrication",
      sections: [
        {
          title: "Contact Us:",
          content:
            "If you’re looking for something from outside of London fear not – we can deliver your job with an overnight courier, a dedicated same-day courier or deliver ourselves, to ensure your new signage job arrives in perfect condition. Feel free to contact us by email or phone and discuss your next project: <a href='https://yellowsigns.co.uk/contact-us'>Contact Us</a>.",
        },
      ],
    },
  },
];

export default locations;
