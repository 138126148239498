import React from "react";
import Slider from "react-slick";
import styled from "styled-components";

const brandLogos = [
  { alt: "Discovery Channel", link: "/img/brandlogos/discovery.png" },
  { alt: "Chesney's", link: "/img/brandlogos/chesneys.png" },
  { alt: "Ascot", link: "/img/brandlogos/ascot.png" },
  { alt: "Bringley's", link: "/img/brandlogos/brinkleys.png" },
  { alt: "UK Cancer Research", link: "/img/brandlogos/cancer-research.png" },
  // {alt: 'Coco', link: '/img/brandlogos/coco.png'},
  // {alt: 'Ted Baker', link: '/img/brandlogos/ted-baker.png'},
  // { alt: "Jimmy Choo", link: "/img/brandlogos/jimmy.png" },
  { alt: "Royal Albert Hall", link: "/img/brandlogos/royal-albert-hall.png" },
  { alt: "CTN", link: "/img/brandlogos/ctn.png" },
  { alt: "Wimbledon", link: "/img/brandlogos/wimbledon.png" },
  { alt: "Green Tomato Cars", link: "/img/brandlogos/green-tomato.png" },
  {
    alt: "Imperial London Hotels",
    link: "/img/brandlogos/imperial-london-hotels.png",
  },
  { alt: "Johnson & Johnson", link: "/img/brandlogos/johnson-johnson.png" },
  { alt: "Rhubarb", link: "/img/brandlogos/rhubarb.png" },
  { alt: "Shavata", link: "/img/brandlogos/shavata.png" },
  // {alt: 'Pink', link: '/img/brandlogos/pink.png'},
  // { alt: "Mira Mikati", link: "/img/brandlogos/mira.png" },
  // { alt: "MWA", link: "/img/brandlogos/mwa.png", class: "no-mob" },
  // {alt: 'L&Q', link: '/img/brandlogos/lq.png'},
  { alt: "BBC", link: "/img/brandlogos/bbc.png" },
  {
    alt: "H2 Property Services",
    link: "/img/brandlogos/h2-property-services.png",
  },
  { alt: "Schmidt", link: "/img/brandlogos/schmidt.png" },
  { alt: "Bone Daddies", link: "/img/brandlogos/bone-daddies.png" },
  {
    alt: "Battersea Power Station",
    link: "/img/brandlogos/battersea-power-station.png",
  },
  // { alt: "Kinleigh Folkard & Hayward", link: "/img/brandlogos/kinleigh.png" },
  {
    alt: "Royal Academy of Dance",
    link: "/img/brandlogos/royal-academy-of-dance.png",
  },
  { alt: "Sky Garden", link: "/img/brandlogos/sky-garden.png" },
  {
    alt: "TWM Solicitors",
    link: "/img/brandlogos/twm-solicitors.png",
    class: "no-mob",
  },
  { alt: "Savills", link: "/img/brandlogos/savills.png" },
  { alt: "Sloane", link: "/img/brandlogos/logo-sloane-stanley.png" },
  { alt: "Mclaren", link: "/img/brandlogos/mclaren.png" },
  { alt: "Banham Group", link: "/img/brandlogos/banham-group-seeklogo.svg" },
];

const Wrapper = styled.div`
  position: relative;
  margin-inline: 44px;

  .overlay {
    position: absolute;
    top: 0;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgba(7, 7, 7, 0) 49.9%,
      rgba(7, 7, 7, 1) 75.06%
    );
    width: 10%;
    z-index: 1;
  }

  .overlay-right {
    right: 0;
  }

  .overlay-left {
    left: 0;
    rotate: 180deg;
  }
`;

const Slide = styled.div`
  padding: 0 50px;

  display: flex !important;
  flex-direction: column;
  justify-content: center;
  height: 100px !important;

  // img {
    filter: grayscale(1);
  // }
`;

const LogoSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 5000,
    // slidesToShow: 4,
    slidesToScroll: 5,
    arrows: false,
    autoplay: false,
    draggable: true,
    autoplaySpeed: 0,
    easing: "linear",
    cssEase: "linear",
    variableWidth: true,
  };
  return (
    <Wrapper>
      <div className="overlay overlay-left"></div>
      <Slider {...settings}>
        {brandLogos.map((logo, index) => {
          return (
            <Slide key={index} style={{ width: "fit-content" }}>
              <img width={170} height={95}  loading="lazy" src={logo.link} alt={logo.alt} />
            </Slide>
          );
        })}
      </Slider>
      <div className="overlay overlay-right"></div>
    </Wrapper>
  );
};

export default LogoSlider;
