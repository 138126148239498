import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Button from "../common/Button";
import device from "../common/device";
import parse from "html-react-parser";

const Wrapper = styled.section`
  padding: 104px 0;
  strong {
    font-weight: 800;
  }
`;
const Intro = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;

  @media ${device.lg} {
    grid-template-columns: 1fr;
  }
`;
const Title = styled.h2`
  font-size: 48px;
  line-height: 1;
  margin-bottom: 32px;
  color: var(--ys-teal);
  font-family: var(--font-secondary);
  text-wrap: balance;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: start;
  gap: 16px;

  @media ${device.lg} {
    justify-content: start;
    align-items: start;
  }
`;
const ImageBanner = styled.img`
  margin: 64px 0;
  height: 220px;
  object-fit: cover;
  width: 100%;

  @media ${device.lg} {
    margin: 40px 0;
  }
`;
const Paragraph = styled.p`
  letter-spacing: -0.01em;
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 1.3625;
  color: var(--ys-black);
  font-weight: 400;

  &.base {
    font-size: 16px;
  }
`;
const LinkWrapper = styled.ul`
  margin: 24px 0;
  display: flex;
  gap: 12px 56px;
  padding-left: 1em;
  color: var(--ys-teal);
  flex-wrap: wrap;

  a {
    font-size: 16px;
  }
`;
const Quote = styled.div`
  margin-top: 45px;
  border-radius: 10px;
  padding: 16px;
  background-color: #e6f7f6;
  margin-bottom: 16px;
  border-left: 2px solid var(--ys-teal);
  p {
    margin: 0;
  }
`;
const Grid = styled.div`
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr 1fr;
  a {
    font-weight: 800;
    color: var(--ys-teal) !important;
    text-decoration: underline;
  }

  @media ${device.lg} {
    grid-template-columns: 1fr;
  }
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  @media ${device.lg} {
    height: 220px;
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Demand = ({ demand }) => {
  return (
    <Wrapper>
      <Container>
        <Intro>
          <div>
            <Title>{demand?.title}</Title>
            {/* <Paragraph className="base">
              <strong>{demand?.description}</strong>
            </Paragraph> */}
          </div>

          <ButtonWrapper>
            <Button href={"/contact-us"}>Contact Us</Button>
            <Button href={"/shops-retail-signs"} isPrimary={false}>View more products</Button>
          </ButtonWrapper>
        </Intro>
        <Quote>
          <Paragraph>{parse(demand?.quote || "")}</Paragraph>
        </Quote>
        <ImageBanner src={demand?.banner} />
        <Grid>
          <div>
            <Title>{demand?.sectionTitle}</Title>
            <Paragraph>{parse(demand?.sectionDescription || "")}</Paragraph>
            <Section>
              {demand?.sections?.map((section) => (
                <Section>
                  <strong>{section?.title}</strong>
                  <Paragraph>{parse(section?.content || "")}</Paragraph>
                </Section>
              ))}
            </Section>{" "}
          </div>
          <Image src={demand?.sideImage} />
        </Grid>
      </Container>
    </Wrapper>
  );
};

export default Demand;
