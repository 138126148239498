import React from "react";
import { useState } from "react";
import { Container, Row } from "react-bootstrap";
import styled from "styled-components";
import locationsGallery from "../../data/locationsGallery";
import Button from "../common/Button";
import ServicesGalleryImg from "../services/ServicesGalleryImg";
import GalleryText from "../services/GalleryText";
import HTMLReactParser from "html-react-parser";

const Wrapper = styled.section`
  margin-bottom: 80px;

  strong {
    font-weight: 800;
  }
`;

const Header = styled.div`
  margin-bottom: 80px;
  max-width: 600px;
  margin-inline: auto;
  text-align: center;
`;

const Title = styled.h2`
  color: var(--ys-teal);
  font-family: var(--font-secondary);
  line-height: 1;
  font-size: 48px;
  margin-bottom: 24px;
`;
const Description = styled.p`
  margin: 0;
  line-height: 22px;
  font-size: 16px;
  font-weight: 400;
  color: var(--ys-black);
  &:last-of-type {
    margin-bottom: 24px;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 40px;
  text-align: center;

  button img {
    display: none;
  }

  button span {
    transition: background-color 0.3s;
  }

  button:hover {
    span {
      background-color: var(--ys-yellow);
    }
  }
`;

const ProductListing = (props) => {
  const [visibleItems, setVisibleItems] = useState(12);
  console.log(props);
  const handleLoadMore = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 12);
  };
  return (
    <Wrapper>
      <Container>
        <Header>
          <Title>Our Products</Title>
          <Description>
            This is a small sample of the Shop Signs we have produced.
          </Description>
          <Description>
            <strong>Want to See More?</strong>
          </Description>

          <Button href="/contact-us">Contact us</Button>
        </Header>
        <Row>
          {(props?.gallery || [])
            ?.slice(0, visibleItems)
            .map(({ img, title }, index) => {
              return <ServicesGalleryImg key={index} img={img} title={title} />;
            })}
        </Row>

        {visibleItems < (props?.gallery || []).length && (
          <ButtonWrapper>
            <Button onClick={handleLoadMore} isPrimary={false}>
              Load more
            </Button>
          </ButtonWrapper>
        )}

        {props.footerContent && (
          <div style={{ marginTop: 50 }}>{props.footerContent}</div>
        )}
      </Container>
    </Wrapper>
  );
};

export default ProductListing;
