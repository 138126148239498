import React, { Component } from "react";
import PointerEvents from "../../fragments/common/PointerEvents";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styled from "styled-components";
import PageBannerA from "../../fragments/PageBannerA";
import { Helmet } from "react-helmet";

const About = styled.div`
  margin: 4rem 0;
`;

const YellowSignsIs = styled.h3`
  color: var(--ys-teal);
`;

const YellowLine = styled.div`
  height: 10rem;
  width: 100%;
  background: var(--ys-halfy);
  position: absolute;
`;

const WateraidLogo = styled.img`
  width: 10rem;
  padding: 0 0 1rem 0;
`;

class OurStory extends Component {
  componentDidMount() {
    PointerEvents("aboutUsBtn");
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Our Story - Yellow Signs in Battersea, London</title>
          <meta
            name="description"
            content="David and Daniel Wright head up a team of highly skilled designers, fabricators, installers and account managers from our headquarters in Battersea, South West London"
          />
          <link rel="canonical" href={`${window.location.origin}/our-story`} />
        </Helmet>
        <PageBannerA
          line1="Our"
          line2="Story"
          img="/img/meettheteam/yellow-signs-building.jpg"
          imgTiny="/img/meettheteam/yellow-signs-building-tiny.jpg"
          width="22.5rem"
        />
        <Container>
          <About>
            <Row>
              <Col lg={4}>
                <YellowSignsIs>
                  YELLOW SIGNS IS A FAMILY OWNED AND RUN BUSINESS WITH A REAL
                  DESIRE TO PROVIDE A SOLUTION-LED APPROACH AND ‘CAN-DO’
                  ATTITUDE.
                </YellowSignsIs>
              </Col>
              <Col lg={8}>
                <p>
                  David and Daniel Wright head up a team of highly skilled
                  designers, fabricators, installers and account managers – a
                  team that provides a dynamic and responsive approach to
                  clients and their requirements.
                </p>
                <p>
                  Yellow Signs acquired Easisigns in 2018 which allowed the team
                  to grow further and move to a large purpose designed
                  production centre in Battersea. Together with state-of-the-art
                  equipment and machinery and a strong network of tried and
                  trusted suppliers, Yellow Signs can meet and exceed customer
                  expectations – from graphic design, visualisations and project
                  management to fabrication, installation and maintenance.
                  In-house production is at the heart of production and the
                  ability turnaround jobs quickly and efficiently. Equipment
                  includes CNC router, laser cutter, vinyl cutters, solvent wide
                  format printers, UV wide format printers, 3D printers, and
                  spray booth – utilising materials including wood, steel,
                  aluminium, aluminium composite, acrylic, foam PVC and more.
                </p>
                <p>
                  Yellow Signs is socially aware and aims to contribute to the
                  local community causes where possible. Yellow Signs also
                  donates to WaterAid which is the UK’s dedicated water charity,
                  bringing clean water to those who need it most. You can find
                  more information here:{" "}
                  <a href="https://www.wateraid.org/uk/" target="_blank">
                    https://www.wateraid.org/uk/
                  </a>
                </p>
                <p>
                  Feel free to pop into the Yellow Signs head office and
                  production centre in Battersea to discuss your next project or
                  speak to a member of the team on 020 7350 1567.
                </p>
              </Col>
            </Row>
          </About>
        </Container>
      </div>
    );
  }
}

export default OurStory;
