import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  withRouter,
} from "react-router-dom";
import HeaderSimple from "../../fragments/services/HeaderSimple";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styled from "styled-components";
import { Helmet } from "react-helmet";

const Date = styled.h3`
  color: var(--ys-teal);
`;

const DateSmall = styled.p`
  display: block;
  color: (--ys-black);
`;

const Title = styled.h3`
  color: var(--ys-teal);
  font-size: 3rem !important;
  margin-top: -0.25rem;
`;

const Excerpt = styled.p``;

const Content = styled.p`
  margin-top: 2rem;
`;

const Thumb = styled.div`
  height: 9rem;
  background-size: cover !important;
  background: url(${(props) => props.img});
`;

const DateSeperator = styled.div`
  height: 2px;
  background: var(--ys-grey-lt);
  width: 100%;
  margin-bottom: 1.25rem;
`;

const ArticleSeperator = styled.div`
  height: 2px;
  background: var(--ys-grey-lt);
  width: 100%;
  margin: 2rem 0.75rem 1rem 0.75rem;
`;

const YellowTitle = styled.h3`
  color: var(--ys-yellow);
`;

const Sidebar = styled.div`
  border-left: 2px solid var(--ys-grey-lt);
  height: 100%;
  padding: 0 1rem;
`;

const PostImg = styled.div`
  height: 30rem;
  background: url(${(props) => props.img});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const SideLink = styled.p`
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 0.25rem;
`;

class News extends Component {
  componentDidMount() {
    window.scroll(0, 0);
  }

  Date(date) {
    let year = date.substring(0, 4);
    let month;
    let day = date.substring(8, 10);

    switch (date.substring(5, 7)) {
      case "01":
        month = "January";
        break;
      case "02":
        month = "February";
        break;
      case "03":
        month = "March";
        break;
      case "04":
        month = "April";
        break;
      case "05":
        month = "May";
        break;
      case "06":
        month = "June";
        break;
      case "07":
        month = "July";
        break;
      case "08":
        month = "August";
        break;
      case "09":
        month = "September";
        break;
      case "10":
        month = "October";
        break;
      case "11":
        month = "November";
        break;
      case "12":
        month = "December";
        break;
      default:
        month = date.substring(5, 7);
    }

    return `${year} ${month} ${day}`;
  }

  render() {
    let ListPosts = [];
    let ListSinglePost = [];
    let ListRecentPosts = [];
    
    if (Object.keys(this.props.posts).length !== 0) {
      ListPosts = this.props.posts.map(
        (post, index) =>
          post.categories[0] === 2 && ( // 3 is the category ID for News in WP
            <Row style={{ margin: "2rem 0" }} key={index}>
              <Col lg={3}>
                {/* <Date>{this.Date(post.date)}</Date>
								<DateSeperator /> */}
                <Thumb img={post.jetpack_featured_media_url} />
              </Col>
              <Col lg={9}>
                <Link to={post.slug}>
                  <Title>{post.title.rendered}</Title>
                </Link>
                <Excerpt
                  dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }}
                />
              </Col>

              {/* Hide the seperator from last article */}
              {this.props.posts.length - 1 === index ? null : (
                <ArticleSeperator />
              )}
            </Row>
          )
      );

      ListRecentPosts = this.props.posts.map(
        (post, index) =>
          post.categories[0] === 2 && ( // 2 is the category ID for News in WP
            <div style={{ marginBottom: 40 }} key={index}>
              <Link to={`/${post.slug}`}>
                <SideLink>{post.title.rendered}</SideLink>
                {/* <DateSmall>{this.Date(post.date)}</DateSmall> */}
              </Link>
            </div>
          )
      );

      ListSinglePost = this.props.posts.map(
        (post, index) =>
          post.categories[0] === 2 && ( // 2 is the category ID for News in WP
            <Route path={`/${post.slug}`} key={index}>
              <Container>
                <Row>
                  <Col lg={9}>
                    <Title>{post.title.rendered}</Title>
                    {/* <DateSmall>{this.Date(post.date)}</DateSmall> */}
                    <PostImg img={post.jetpack_featured_media_url} />
                    <Content
                      dangerouslySetInnerHTML={{
                        __html: post.content.rendered,
                      }}
                    />
                  </Col>
                  <Col lg={3}>
                    <Sidebar>
                      <YellowTitle>Recent Posts</YellowTitle>
                      {ListRecentPosts}
                    </Sidebar>
                  </Col>
                </Row>
              </Container>
            </Route>
          )
      );
    }

    // console.log(this.props.posts);

    return (
      <div>
        <Helmet>
          <title>
            News of Signs & Graphics in London, Yellow Signs Battersea
          </title>
          <meta
            name="description"
            content="Have a look at some of our news articles about signage and graphics - we specialise in internal and external signage and graphics for businesses in London and the South East"
          />
          <link
            rel="canonical"
            href={`${window.location.origin}/news`}
          />
        </Helmet>
        <HeaderSimple heading="News" />

        <Router>
          <Switch>
            {ListSinglePost}

            <Route path="/news">
              <Container>
                <Row>
                  <Col lg={9}>{ListPosts}</Col>
                  <Col lg={3}>
                    <Sidebar>
                      <YellowTitle>Recent Posts</YellowTitle>
                      {ListRecentPosts}
                    </Sidebar>
                  </Col>
                </Row>
              </Container>
            </Route>
          </Switch>
        </Router>
      </div>
    );
  }
}

export default withRouter(News);
