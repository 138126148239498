import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";

const Wrapper = styled.section`
  padding: 80px 0 40px;
  text-align: center;
  position: relative;
`;

const Title = styled.h1`
  color: var(--ys-teal);
  font-family: var(--font-secondary);
  line-height: 1;
  font-size: 64px;
  margin-bottom: 24px;
`;
const Description = styled.p`
  margin: 0;
  line-height: 22px;
  font-size: 16px;
  font-weight: 400;
  color: var(--ys-black);
`;

const Content = styled.div`
  max-width: 730px;
  margin-inline: auto;
`;

const Hero = ({ name, content }) => {
  return (
    <Wrapper>
      <Container>
        <Content>
          <Title>{name}</Title>

          <Description>{content}</Description>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default Hero;
