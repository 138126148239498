import React, { Component } from "react";
import CTA from "../fragments/locations/CTA";
import LocationBanner from "../fragments/locations/LocationBanner";
import LocationGallery from "../fragments/locations/LocationGallery";
import LocationList from "../fragments/locations/LocationList";
import { Helmet } from "react-helmet";

export default class Locations extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Yellow Signs - Locations</title>
          <meta
            name="description"
            content={
              "We’re based in Battersea and work with a wide range of clients in and around London, but we also supply clients around the rest of the UK. We have our own in-house production team and installation team to ensure we cover your area with our own team of signage and graphics experts."
            }
          />
          <link rel="canonical" href={`${window.location.origin}/locations`} />
        </Helmet>
        <LocationBanner />
        <LocationList />
        <CTA />
        <LocationGallery />
      </>
    );
  }
}
