import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const LinkWrapper = styled.span`
  color: var(--ys-black);
  font-size: 24px;
  padding: 1rem;
  display: inline-block;
  line-height: 1;
  border-radius: 5px;
  background: var(--ys-yellow);
  font-family: var(--font-secondary);
  transition: 0.3s;

  &:hover {
    color: var(--ys-white);
    background: var(--ys-teal);
  }
`;

const LinkWrapperSecondary = styled.span`
  display: flex;
  column-gap: 10px;
  padding: 16px 24px;
  font-weight: 500;
  font-family: var(--font-secondary);
  border-radius: 5px;
  border: 1px solid var(--ys-black);
  font-size: 24px;
  line-height: 1;
  width: fit-content;
  background-color: white;

  img {
    transition: all 0.3s;
  }

  &:hover {
    img {
      transform: translateX(5px);
    }
  }
`;

const ButtonWrapper = styled.button`
  border: none;
  padding: 0;
`;

const Button = ({ children, href, isPrimary = true, ...rest }) => {
  if (isPrimary) {
    if (href) {
      return (
        <Link {...rest} to={href}>
          <LinkWrapper>{children}</LinkWrapper>
        </Link>
      );
    }

    return (
      <ButtonWrapper
        className={`new ${rest?.className ? rest?.className : ""}`}
        {...rest}
      >
        <LinkWrapper>{children}</LinkWrapper>
      </ButtonWrapper>
    );
  }

  if (href) {
    return (
      <Link {...rest} to={href}>
        <LinkWrapperSecondary>
          <span>{children}</span>
          <img
            src="/icons/arrow-right.svg"
            alt="arrow right"
            width={25}
            height={24}
          />
        </LinkWrapperSecondary>
      </Link>
    );
  }

  return (
    <ButtonWrapper
      className={`new ${rest?.className ? rest?.className : ""}`}
      {...rest}
    >
      <LinkWrapperSecondary>
        <span>{children}</span>
        <img
          src="/icons/arrow-right.svg"
          alt="arrow right"
          width={25}
          height={24}
        />
      </LinkWrapperSecondary>
    </ButtonWrapper>
  );
};

export default Button;
