import React from "react";
import { useState } from "react";
import { useRef } from "react";
import styled, { keyframes } from "styled-components";
import Button from "./Button";
import device from "./device";
import { Link } from "react-router-dom";
import axios from "axios";

const ButtonWrapper = styled.div`
  text-align: center;
`;

const FormContact = styled.div`
  padding: 24px;
  background-color: rgba(7, 7, 7, 0.1);

  display: flex;
  gap: 20px 29px;

  .divider {
    width: 1px;
    background-color: rgba(7, 7, 7, 0.5);
    opacity: 0.5;
  }

  .hotline {
    display: flex;
    gap: 16px;
    align-items: flex-start;

    h3 {
      font-size: 20px !important;
      line-height: 1;
      text-transform: none;
      margin-bottom: 6px;
    }

    a {
      color: var(--ys-red) !important;
      font-size: 32px !important;
      font-weight: 500 !important;
      line-height: 1;
      font-family: var(--font-secondary);

      &:hover {
        text-decoration: underline !important;
      }
    }
  }

  .phone-icon {
    flex-shrink: 0;
    aspect-ratio: 1;
    width: 32px;
    display: grid;
    place-items: center;
    border-radius: 50%;
    background-color: var(--ys-yellow);
  }

  .working-email {
    font-size: 14px;
    margin-top: auto;
    font-weight: 500;
    line-height: 19px;

    p {
      margin-bottom: 6px;
      color: var(--ys-black);
    }

    a {
      &:hover {
        text-decoration: underline !important;
      }
    }
  }

  @media ${device.xl} {
    flex-direction: column;

    .divider {
      width: 100%;
      height: 1px;
    }
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-bottom: 16px;
  width: 100%;
`;

const FormWrapper = styled.div`
  margin-bottom: 72px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 24px;
  font-weight: 400;

  @media ${device.lg} {
    grid-template-columns: 1fr;
  }
`;

const FormContent = styled.div`
  grid-column: 1 / span 6;
  color: var(--ys-white);
  font-size: 14px;
  letter-spacing: -0.01em;
  padding-top: 24px;
  p {
    color: var(--ys-white);
    font-size: 14px;
    margin-bottom: 12px;
    line-height: 19px;
  }

  h2 {
    font-family: var(--font-secondary);
    font-weight: 500;
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1;
  }

  h3 {
    font-size: 24px !important;
    text-transform: none;
    line-height: 33.6px;
  }

  div {
    display: flex;
    flex-direction: column;
    /* row-gap: 12px; */
  }

  a {
    color: var(--ys-white) !important;
    display: inline-block;
    margin-bottom: 12px;
    width: fit-content;

    &:hover {
      text-decoration: underline !important;
    }

    &.email {
      text-decoration: underline !important;
    }
  }

  .contact {
    color: var(--ys-teal) !important;
    font-size: 15px;
    font-weight: 700;
  }

  .yellow {
    color: var(--ys-yellow);
    font-weight: 800;
  }

  @media ${device.lg} {
    grid-column: span 1;
  }
`;

const Form = styled.form`
  grid-column: 8 / span 5;
  background: var(--ys-white);
  color: var(--ys-black);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;

  @media ${device.lg} {
    grid-column: span 1;
  }
`;

const FormFields = styled.div`
  padding: 32px 24px 24px;
  position: relative;
  flex-grow: 1;
  display: grid;
  place-items: center;

  h2 {
    margin-bottom: 24px;
    font-size: 40px;
    line-height: 1;
    font-weight: 500;
    font-family: var(--font-secondary);
  }
`;

const InputWrapper = styled.div`
  position: relative;
  input,
  textarea {
    border-radius: 5px;
    border: 0.5px solid rgba(7, 7, 7, 0.5);
    padding: 13px 16px;
    width: 100%;
    font-size: 16px;
    line-height: 21px;
  }
`;

const Placeholder = styled.div`
  position: absolute;
  padding: 13px 16px;
  inset: 0;
  /* z-index: -1; */
  color: rgba(7, 7, 7, 0.3);
  font-size: 16px;
  line-height: 21px;
  pointer-events: none;
  span {
    color: var(--ys-red);
  }
`;

const Error = styled.div`
  color: var(--ys-red);
  padding: 8px 0 20px;
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid var(--ys-yellow);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: ${spin} 1s linear infinite;
`;

const LoadingSpinner = styled.div`
  position: absolute;
  display: grid;
  place-items: center;
  z-index: 10;
  inset: 0;
  background: rgba(255, 255, 255, 0.5);
`;

const SuccessMessage = styled.div`
  svg {
    margin-bottom: 16px;
    border: 1px solid green;
    border-radius: 50%;
    display: inline-block;
  }

  text-align: center;
`;

const FORM_STATE = {
  loading: "loading",
  success: "success",
  initial: "initial",
};

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
  });

  const [errorMessage, setErrorMessage] = useState("");

  const [formState, setFormState] = useState(FORM_STATE.initial);

  const validateForm = () => {
    const { name, phone, email } = formData;

    const regex = {
      email:
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
      phone: /^(\+[1-9]{1}[0-9]{3,14})?([0-9]{9,14})$/,
    };

    if (!name || !phone || !email) {
      setErrorMessage("All fields are required.");
      return false;
    }

    if (!regex.phone.test(phone)) {
      setErrorMessage("Invalid phone number. Ensure it is 9-14 digits long.");
      return false;
    }

    if (!regex.email.test(email)) {
      setErrorMessage(
        "Invalid email address. Please enter a valid format like user@example.com."
      );
      return false;
    }

    return true;
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    setFormState(FORM_STATE.loading);

    if (!validateForm()) {
      setFormState(FORM_STATE.initial);
      return;
    }

    setErrorMessage("");
    const sendEmailDate = {
      ContactName: formData.name,
      ContactPhone: formData.phone,
      ContactEmail: formData.email,
    };
    try {
      await fetch("https://submit-form.com/iJk3XIClU", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(formData),
      }).then(() => {
        window.dataLayer.push({
          event: "contact_form_submit",
          eventCategory: "form_submit",
          eventAction: "click",
          eventLabel: "Contact Form Submit",
        });
      });
      // axios
      //   .post(
      //     "https://0p6owqyjoi.execute-api.us-east-1.amazonaws.com/prod/send-quote-request",
      //     sendEmailDate
      //   )
      //   .then((res) => {
      //     console.log("Contact data is passed to Nodemailer");
      //   });
      setFormState(FORM_STATE.success);
    } catch (error) {
      console.error("Error submitting the form:", error);
      setFormState(FORM_STATE.initial);
    }
  };

  return (
    <FormWrapper>
      <FormContent>
        <h2>Fast response, fast quotes!</h2>
        <div>
          <p>
            We know that you want a fast response and fast quotes and that’s
            exactly what we do.
          </p>
          <p>
            {/* Please <span className="yellow">provide a telephone number</span>{" "}
            when emailing, so we can ensure you receive your estimate, as
            sometimes emails don't get through. */}
            Just fill in the form, email us or call us and we’ll get back to you
            straightaway. We also have a handy online chat button or you can
            send photos of your next project via Whatsapp – you can find the
            details here:{" "}
            <Link className="contact" to="/contact-us">
              Contact Us
            </Link>
          </p>

          <h3>Call us</h3>
          <a href="tel:02073501567">020 7350 1567</a>

          <h3>Email</h3>
          <a className="email" href="mailto:info@yellowsigns.co.uk">
            info@yellowsigns.co.uk
          </a>

          <h3>Address</h3>
          <p>Unit 5 River Reach, Gartons Way, Battersea, London SW11 3SX</p>
        </div>
      </FormContent>
      <Form onSubmit={onSubmit}>
        <FormFields>
          {formState === FORM_STATE.success ? (
            <SuccessMessage>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={56}
                height={56}
                viewBox="0 0 24 24"
              >
                <path
                  fill="green"
                  d="m10 16.4l-4-4L7.4 11l2.6 2.6L16.6 7L18 8.4z"
                />
              </svg>
              <div>
                Thank you for your message. We will get back to you as soon as
                possible.
              </div>
            </SuccessMessage>
          ) : (
            <>
              <h2>Need an instant, no obligation quote?</h2>

              <InputContainer>
                <Input
                  setFormData={setFormData}
                  formData={formData}
                  placeholder="Your name"
                  name="name"
                />
                <Input
                  setFormData={setFormData}
                  formData={formData}
                  placeholder="Your phone number"
                  name="phone"
                />
                <Input
                  setFormData={setFormData}
                  formData={formData}
                  placeholder="Your email"
                  name="email"
                />
                <Textarea
                  setFormData={setFormData}
                  formData={formData}
                  placeholder="Brief description of your job or project"
                  name="description"
                />
              </InputContainer>

              {errorMessage && <Error>{errorMessage}</Error>}
              <ButtonWrapper>
                <Button type="submit">Submit</Button>
              </ButtonWrapper>
            </>
          )}

          {formState === FORM_STATE.loading && (
            <LoadingSpinner>
              <Spinner />
            </LoadingSpinner>
          )}
        </FormFields>
        <FormContact>
          <div className="hotline">
            <div className="phone-icon">
              <img
                src="/icons/phone.svg"
                height={20}
                width={20}
                alt="phone icon"
              />
            </div>
            <div>
              <h3>Hotline:</h3>
              <a href="tel:02073501567">020 7350 1567</a>
            </div>
          </div>
          <div className="divider"></div>
          <div className="working-email">
            <p>8am - 5pm / Mon - Fri</p>
            <a href="mailto:info@yellowsigns.co.uk">info@yellowsigns.co.uk</a>
          </div>
        </FormContact>
      </Form>
    </FormWrapper>
  );
};

const Input = ({
  placeholder = "",
  type = "text",
  setFormData,
  formData,
  name,
}) => {
  const ref = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div>
      <label
        htmlFor={name}
        style={{
          position: "absolute",
          width: "1px",
          height: "1px",
          padding: 0,
          margin: "-1px",
          overflow: "hidden",
          clip: "rect(0, 0, 0, 0)",
          whiteSpace: "nowrap",
          borderWidth: 0,
        }}
      >
        {placeholder}
      </label>
      <InputWrapper>
        <Placeholder ref={ref}>
          {placeholder} <span>*</span>
        </Placeholder>
        <input
          id={name}
          name={name}
          type={type}
          onChange={(e) => {
            handleChange(e);
            if (e.target.value) {
              ref.current.style.opacity = 0;
            } else {
              ref.current.style.opacity = 1;
            }
          }}
        />
      </InputWrapper>
    </div>
  );
};

const Textarea = ({
  placeholder = "",
  type = "text",
  setFormData,
  formData,
  name,
}) => {
  const ref = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <InputWrapper>
      <label
        htmlFor={name}
        style={{
          position: "absolute",
          width: "1px",
          height: "1px",
          padding: 0,
          margin: "-1px",
          overflow: "hidden",
          clip: "rect(0, 0, 0, 0)",
          whiteSpace: "nowrap",
          borderWidth: 0,
        }}
      ></label>
      <Placeholder ref={ref}>
        {placeholder} <span>*</span>
      </Placeholder>
      <textarea
        id={name}
        name={name}
        type={type}
        onChange={(e) => {
          handleChange(e);
          if (e.target.value) {
            ref.current.style.opacity = 0;
          } else {
            ref.current.style.opacity = 1;
          }
        }}
      />
    </InputWrapper>
  );
};

export default ContactForm;
