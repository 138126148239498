import { useEffect, useState } from "react";

const ImagePreloader = (props) => {
  // console.log(props)
  const [image, setImage] = useState(null);

  useEffect(() => {
    const img = new Image();
    img.src = props.src;
    setImage(img);
  }, []);

  return <img {...props} src={image?.src || props?.src} />;
};

export default ImagePreloader;
