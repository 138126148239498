import React from 'react';
import {Container} from 'react-bootstrap';
import styled from 'styled-components';

const Wrapper = styled.section`
    padding: 64px 0 177px;
    background: black;
    text-align: center;
    position: relative;
    background-image: url('/img/location-single/banner.png');
    background-size: cover;
    background-repeat: no-repeat;
`;

const Content = styled.div``;

const Title = styled.h1`
    color: var(--ys-teal);
    font-family: var(--font-secondary);
    line-height: 1;
    font-size: 64px;
    margin-bottom: 16px;
`;
const Description = styled.p`
    color: var(--ys-white);
    margin: 0;
    line-height: 22px;
    font-size: 16px;
    font-weight: 400;
`;

const Banner = () => {
    return (
        <Wrapper>
            <Container>
                <Content>
                    <Title>Areas we cover</Title>
                    <Description>
                        Looking for something specific, or want to discuss your
                        options?
                    </Description>
                    <Description>
                        We have provide covering London, Central London and the
                        Home Counties
                    </Description>
                </Content>
            </Container>
        </Wrapper>
    );
};

export default Banner;
