import React, { useEffect } from "react";
import Banner from "../fragments/location-single/Banner";
import CompanyDetail from "../fragments/location-single/CompanyDetail";
import Demand from "../fragments/location-single/Demand";
import Showcase from "../fragments/location-single/Showcase";
import WhyYouCare from "../fragments/common/WhyYouCare";
import { Helmet } from "react-helmet";

const LocationSingle = (props) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Yellow Signs - Location - {props.label}</title>
        <meta name="description" content={props?.companyDetail?.subtitle} />
        <link
          rel="canonical"
          href={`${window.location.origin}${props?.href}`}
        />
      </Helmet>
      <Banner />
      <CompanyDetail detail={props.companyDetail} />
      <Showcase showcase={props?.showcase} />
      <Demand demand={props?.demand} />
      <WhyYouCare />
      <div style={{ height: "20px" }}></div>
    </>
  );
};

export default LocationSingle;
