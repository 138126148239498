const size = {
	xs: 500,
	sm: 576,
	md: 768,
	lg: 992,
	xl: 1200,
};

const device = {
	xs: `(max-width: ${size.xs}px)`,
	sm: `(max-width: ${size.sm}px)`,
	md: `(max-width: ${size.md}px)`,
	lg: `(max-width: ${size.lg}px)`,
	xl: `(max-width: ${size.xl}px)`,
	smallest: size.xs,
	small: size.sm,
	mid: size.md,
	large: size.lg,
	xlarge: size.xl,
};

export default device;
