
const PointerEvents = (activeBtn) => {

    // Remove pointer events from megamenu
    let megamenu = document.getElementsByClassName("mgDrop");
    for(let menu of megamenu){
      menu.style.pointerEvents = "none";
    };

    // Scroll to top
    window.scroll(0, 0);

    // Add active top menu style
    // const buttons = ["signSelectionBtn", "signsBySectorBtn", "whatWeDoBtn", "aboutUsBtn", "quoteSiteVisitBtn"];
    // for(let button of buttons){
    //   if(activeBtn === button){
    //     if(!document.getElementById(activeBtn).classList.contains("active-menu")){
    //       document.getElementById(activeBtn).classList.add("active-menu");
    //     }
    //   } else {
    //     document.getElementById(button).classList.remove("active-menu");
    //   }
    //   document.getElementById(button).classList.remove("active-menu-temp");
    // }
}

export default PointerEvents;