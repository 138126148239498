import React, { Component } from "react";
import PointerEvents from "../fragments/common/PointerEvents";
import PageBannerA from "../fragments/PageBannerA";
import QuoteForm from "../fragments/QuoteForm";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import device from "../fragments/common/device";
import styled from "styled-components";
import { Helmet } from "react-helmet";

const YellowLine = styled.div`
  height: 10rem;
  width: 100%;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 60%,
    rgba(255, 216, 0, 1) 60%,
    rgba(255, 216, 0, 1) 100%
  );
  position: absolute;
  @media ${device.md} {
    display: none;
  }
`;

const MeetWrap = styled.h2`
  height: 10rem;
  background: white;
  padding-left: 2rem;
  transform: scale(1) rotate(0deg) translate(0px, 0px) skew(-6deg, 0deg);
  background: var(--ys-yellow);
  margin-left: 35rem;
  margin-top: 2rem;
`;

const MeetLineOne = styled.div`
  font-size: 2.5rem;
  font-weight: 500;
  text-transform: uppercase;
  transform: scale(1) rotate(0deg) translate(0px, 0px) skew(-6deg, 0deg);
  margin-left: 3.1rem;
  font-weight: 300;
  @media ${device.md} {
    margin-left: 0;
  }
`;

const PromiseLineTwo = styled.span`
  font-size: 14rem;
  text-transform: uppercase;
  display: block;
  font-family: Alternate, "sans-serif";
  margin-top: -2rem;
  color: var(--ys-yellow);
  margin-left: -35rem;
  line-height: 0.73;
  @media ${device.md} {
    font-size: 7rem;
    margin-left: -37rem;
  }
`;

const PromiseImg = styled.img`
  width: 100%;
`;

const PromiseTextWrap = styled.div`
  padding-left: 1rem;
`;

const PromiseBoxWrapper = styled.div`
  margin: 2rem auto;
  @media ${device.md} {
    margin: 0 auto;
  }
`;

const Title = styled.h3`
  color: var(--ys-teal);
`;

const Text = styled.p``;

const PromiseWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  transform: scale(1) rotate(0deg) translate(0px, 0px) skew(-6deg, 0deg);
`;

const PromiseBox = (props) => {
  return (
    <Col sm={12} lg={4}>
      <PromiseWrap>
        <PromiseImg src={props.img} alt={props.title} />
        <PromiseTextWrap>
          <Title>{props.title}</Title>
          <Text>{props.text}</Text>
        </PromiseTextWrap>
      </PromiseWrap>
    </Col>
  );
};

class QuoteSiteVisit extends Component {
  componentDidMount() {
    PointerEvents();
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>
            Free Quote & Site Survey - Yellow Signs in Battersea London
          </title>
          <meta
            name="description"
            content="Tell us about your next signage or graphics project and recieved personalised advice as well as a free quote and site survey - we're here to help!"
          />
          <link
            rel="canonical"
            href={`${window.location.origin}/quote-site-visit`}
          />
        </Helmet>
        <PageBannerA
          line1="Request a"
          line2="Quote"
          line3="Or book in a"
          line4="free site visit"
          width="22.4rem"
          img="/img/signselection/projecting-signs-lg.jpg"
          imgTiny="/img/signselection/projecting-signs-tiny.jpg"
        />

        <QuoteForm />

        <Container>
          <Row>
            <Col>
              <MeetLineOne>Our Price</MeetLineOne>
            </Col>
          </Row>
        </Container>
        <YellowLine />
        <Container>
          <Row>
            <Col>
              <MeetWrap>
                <PromiseLineTwo>promise</PromiseLineTwo>
              </MeetWrap>
            </Col>
          </Row>
        </Container>
        <Container>
          <PromiseBoxWrapper>
            <Row>
              <PromiseBox
                img="/img/quote/quality.svg"
                title="QUALITY, QUALITY, QUALITY"
                text="We pride ourselves on quality of service we supply, as well as quality of materials, techniques and our installation team. We also understand the need to be competitively priced so offer a like-for-like price promise guarantee."
              />
              <PromiseBox
                img="/img/quote/like.svg"
                title="LIKE-FOR-LIKE"
                text="Not all signage quotes are born equal so it’s important to make sure that you compare the same materials used. There is a huge range of products and materials available for every sign job so it’s best to compare like-for-like to get a fair comparison and also to make sure you recieve exactly the right product for your next project."
              />
              <PromiseBox
                img="/img/quote/challenge.svg"
                title="CHALLENGE US!"
                text="We’re so confident in our price promise that we encourage you to challenge our price promise with any genuine like-forlike quote."
              />
            </Row>
          </PromiseBoxWrapper>
        </Container>
      </div>
    );
  }
}

export default QuoteSiteVisit;
